/* Flaggen */
.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    font-size: 50px !important;
}

.flag-icon:before {
    content: "\00a0";
}

.flag-icon.flag-icon-squared {
    width: 1em;
}

.flag-icon-at {
    background-image: url(/images/icons/flags/AT.svg);
}

/* F�r verschiedene Gr��e
.flag-icon-at.xs {
  background-image: url(/images/icons/flags/at.svg);
}
*/

.flag-icon-ch {
    background-image: url(/images/icons/flags/CH.svg);
}

.flag-icon-cz {
    background-image: url(/images/icons/flags/CZ.svg);
}

.flag-icon-de {
    background-image: url(/images/icons/flags/DE.svg);
}

.flag-icon-fr {
    background-image: url(/images/icons/flags/FR.svg);
}

.flag-icon-pl {
    background-image: url(/images/icons/flags/PL.svg);
}

.flag-icon-lt {
    background-image: url(/images/icons/flags/LT.svg);
}

.flag-icon-sk {
    background-image: url(/images/icons/flags/SK.svg);
}

.flag-icon-lv {
    background-image: url(/images/icons/flags/LV.svg);
}
