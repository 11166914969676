em {
    font-style: italic;
}

.input-group input[type=text], .input-group select {
    width: 100%;
    height: 100%;
}

#btn-top {
    z-index: 1501;
    transition: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

a:focus {
    color: #bb2d21;
}

/*span[style*="text-decoration: underline"]:active,*/
a:active {
    color: #bb2d21;
}

/*span[style*="text-decoration: underline"]:hover,*/
a:hover {
    color: #bb2d21;
}

@media (max-width: 767px) {
    .form-group label {
        margin-bottom: 0px;
    }
}

@media (max-width: 1025px) {
    a:focus {
        color: initial;
    }

    span[style*="text-decoration: underline"]:hover,
    a:hover {
        color: initial;
    }

    span[style*="text-decoration: underline"]:active,
    a:active {
        color: #bb2d21;
    }
}

a.link-default:hover {
    background-color: initial;
}

/* BODY */
body {
    color: #333;
    font-size: 15px;
    background-color: #fff;
}

.winkler-close {
    color: #000;
}

@media (max-width: 767px) {
    .modal .modal-content .winkler-close {
        font-size: 25px;
        width: 45px;
        height: 45px;
        z-index: 9999999999;
    }

    h1.iwc-winkler-modal-title {
        margin-top: 15px;
    }
}

section.main-content {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    padding-right: 0px;
}

.catalog-section.contact-section img,
.iwc-ui-pref-live-preview .iwc-contact-img {
    width: initial;
    height: 140px;
}

@media (max-width: 991px) {
    .catalog-section.contact-section .contact img,
    .login-section .contact img, .no-result .contact img,
    .iwc-ui-pref-live-preview .iwc-contact-img {
        height: auto;
    }
}

img.iwc-text-default.image-right {
    float: right;
}

img.iwc-text-default.image-left {
    float: left;
}

img.iwc-text-default {
    margin: 10px;
    max-width: 100%;
}

@media (max-width: 425px) {
    img.iwc-text-default {
        margin-left: auto;
        margin-right: auto;
        display: block;
        float: none !important;
    }
}

/*.winkler-content-wimg{
	margin-bottom: 50px;
}*/

.underline {
    text-decoration: underline;
    cursor: pointer;
}

.iwc-winkler-radiobutton-text {
    padding-left: 10px;
}

.accordion-item.panel {
    margin-bottom: 0px;
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* Dialog */

.modal-open, .modal {
    padding-right: 0px !important;
}

body.modal-open {
    position: relative;
}

body.modal-open.iwc-toolbar-body-fix {
    padding-top: 40px;
    margin-top: 0px !important;
}

.winkler-fullscreen-modal > .modal-backdrop {
    /*	height: 100% !important;*/
}

@media (max-width: 767px) {
    .modal-dialog {
        margin: 0px;
    }
}

.modal-lg.extra.modal-dialog {
    width: 100%;
    max-width: 1400px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .modal-dialog {
        width: 100%;
        padding: 0 20px;
    }
}

@media (max-width: 767px) {
    .iwc-toolbar-body-fix .modal.winkler-fullscreen-modal {
        margin-top: 40px;
    }

    .modal.winkler-fullscreen-modal {
        margin-top: 0px;
        padding-top: 0px;
    }
}

.winkler-fullscreen-modal {
    margin-top: 0px !important;
    padding-top: 180px;
    height: 100% !important;
}

.winkler-fullscreen-modal .iwc-ui-uploader {
    padding: 20px;
}

.winkler-fullscreen-modal .modal-footer {
    padding-top: 8px;
}

.modal.in .modal-dialog {
    margin-top: 0px;
}

.winkler-fullscreen-modal > .modal-dialog > .modal-content {
    padding-top: 0px;
}

.iwc-ui-show-element {
    display: block;
}

.iwc-ui-hide-element {
    display: none;
}

.winkler-head .iwc-ui_main_adn-header .iwc-edit-bar {
    margin-top: 34px;
}

.winkler-fullscreen-modal .slider-buttons {
    position: absolute;
    z-index: 999;
    height: 100%;
}

.winkler-fullscreen-modal .slider-buttons p {
    position: relative;
    top: 150px;
    font-size: 60px;
    color: white;
}

.winkler-fullscreen-modal .slider-buttons.left {
    left: -70px;
}

.winkler-fullscreen-modal .slider-buttons.right {
    right: -70px;
}

.winkler-fullscreen-modal .slider-buttons .winkler-arrow-left,
.winkler-fullscreen-modal .slider-buttons .winkler-arrow-right,
.winkler-fullscreen-modal .mini-slider-button .winkler-arrow-left,
.winkler-fullscreen-modal .mini-slider-button .winkler-arrow-right {
    font-family: winkler-font;
    cursor: pointer;
}

.winkler-fullscreen-modal .mini-slider-button .winkler-arrow-left,
.winkler-fullscreen-modal .mini-slider-button .winkler-arrow-right {
    color: #bb2d21;
    font-size: 20px;
}

.winkler-fullscreen-modal .slider-buttons .winkler-arrow-left:before,
.winkler-fullscreen-modal .mini-slider-button .winkler-arrow-left:before {
    content: "c";
}

.winkler-fullscreen-modal .slider-buttons .winkler-arrow-right:before,
.winkler-fullscreen-modal .mini-slider-button .winkler-arrow-right:before {
    content: "d";
}

.iwc-mobile-slider-head .mini-slider-button {
    display: none;
}

@media (max-width: 991px) {
    .iwc-mobile-slider-head {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }

    .iwc-mobile-slider-head .mini-slider-button, .iwc-mobile-slider-head .iwc-winkler-modal-title {
        /*   display: inline-block;*/
        display: table-cell;
    }

    .iwc-mobile-slider-head .mini-slider-button {
        padding: 10px;
    }
}

/* Notifications/Meldungen (noty) */
li.iwc-notification-theme,
.iwc-default-message-success,
.iwc-default-message-error {
    background-color: #eaeaea !important;
    border: 1px solid #333333 !important;
}

/*.iwc-notification-theme.noty_container_type_alert,
.iwc-notification-theme.noty_container_type_error,*/
.iwc-notification-theme.noty_container_type_warning,
.iwc-notification-theme.noty_container_type_information,
.iwc-notification-theme.noty_container_type_success,
.iwc-default-message-success {
    color: #000;
}

#noty_bottom_layout_container {
    left: 0 !important;
}

#noty_bottom_layout_container li {
    background-color: #0e4194 !important;
    border: none !important;
    color: white;
    width: 100vw;
    min-height: 65px;
    font-family: "Helvetica-Neue-LT-Bold",Verdana,Arial,Helvetica;
}

.iwc-notification-theme .noty_close {
    right: 20px;
    visibility: visible;
    display: none;
}

#noty_bottom_layout_container li span.icon.icon-notify:before {
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
}

#noty_bottom_layout_container li.noty_container_type_error span.icon.icon-notify:before {
    content: "j";
}

#noty_bottom_layout_container li.noty_container_type_success span.icon.icon-notify:before {
    content: "i";
}

#noty_bottom_layout_container li span.icon.icon-notify {
    left: 10px;
}

#noty_bottom_layout_container li.noty_container_type_error {
    background-color: #bb2d21 !important;
}

/* Notifications/Meldungen (noty) END */

/* Menue*/

header .subheader-dropdown .dropdown-inner .active_menu > a {
    background: #fff none repeat scroll 0 0;
    color: #164194;
}

header .subheader-dropdown .dropdown-inner .submenu {
    display: block;
}

@media (max-width: 991px) {
    header #header-menu-container .rectangle nav li a.icon-link {
        padding: 0;
    }
}

/* Startseite */
/* wird �ber js beim �ffnen eines dialogs gesetzt
.main>header,
header{
	z-index: auto;
}
*/

.iwc-toolbar {
    z-index: 4000;
}

.iwc-toolbar-body-fix #service-nav.loggedin {
    top: 175px;
}

/*
header #winkler-logo-container{
	z-index: 1000;
	float: left;
}

header #header-menu-container{
	z-index: 100;
}


header #winkler-logo-container, header .winkler-logo-container {
    z-index: 999;
}
*/

@media (min-width: 991px) and (max-width: 1239px) {
    header .winkler-logo-container {
        width: 170px;
    }
}

.target-group-links.container-fluid .target-group a .icon {
    margin-left: 5px;
}

header .subheader-dropdown {
    z-index: 1;
}

header .subheader-dropdown .dropdown-outer {
    /*display: none;*/
    top: -700px;
}

/* LOGIN*/

@media (max-width: 1199px) {
    .keep-logged-in label.iwc-label-checkbox {
        position: absolute;
    }

    .keep-logged-in span.cookie-note {
        padding-left: 50px;
        position: absolute;
    }

    .login-button {
        margin-top: 30px;
    }
}

.radio label, .checkbox label {
    padding-left: 0px;
}

.winkler-content .before-login {
    position: relative;
}

.target-group-links.container-fluid {
    bottom: 15px;
}

.iwc-403-text {
    padding-top: 250px;
}

.winkler-head {
    height: 135px;
    position: relative;
}

.winkler-content {
    padding-left: 15px;
    padding-right: 15px;
}

.winkler-content-wimg.before-login {}

.winkler-content-head {
    margin-left: -15px;
    margin-right: -15px;
}

.iwc-ui-cms-text {
    position: relative;
}

.languages-container .iwc-language-selection .iwc-cookie-note-tooltip {
    background: #164194;
}

.languages-container .iwc-language-selection .iwc-cookie-note-tooltip a {
    color: #fff;
}

.languages-container .iwc-language-selection .iwc-cookie-note-tooltip .iwc-cookie-note-tooltip-after {
    border-color: transparent transparent #164194;
}

.service.languages-container .row div,
.languages-container.onlineshop .row div {
    padding-bottom: 8px;
}

#nav, #nav > li {
    background-color: #fff;
}

#nav li a {
    color: #000000;
    font-weight: bold;
}

.js-jump-to {
    cursor: pointer;
}

.offer-section .offer-single-product .link-to-product .product-short-description-list:after {
    content: "d";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 2;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    padding-top: 2px;
    color: #bb2d21;
}

/* Tooltip */
@media (max-width: 767px) {
    span.iwc-cookie-note-tooltip > div {
        width: 100% !important;
    }
}

/* Content */
.light-light, .light-light ~ p {
    color: #ffffff;
    text-shadow: 0 -1px 5px #323232, 0 3px 5px #323232, 0 -1px 5px #323232;
}

.start-text {
    font-size: 15px;
    line-height: 25px;
    padding: 0 20px;
    text-align: center;
}

h1 {
    font-size: 34px;
}

h1, h2, h3, h4, h5, h6 {
    /*font-family: "Helvetica-Neue-LT-Cond-Bld",Verdana,Arial,Helvetica;*/
    letter-spacing: 0.5px;
}

/*Register*/
.register-benefits {
    margin-top: 0px;
}

@media (max-width: 767px) {
    .register-form-section a.btn {
        display: block;
    }
}

.safety-informations:hover { /* innovate KN underline color red*/
    color: #bb2d21;
}

/* Benefits */
.onlineshop-benefits ul.advantage-list-register.row {
    width: 100%;
}

.onlineshop-benefits ul.advantage-list-register.row .col-md-4 {
    width: 100%;
    padding-right: 0px;
}

.onlineshop-benefits .container {
    width: 100%;
    padding-right: 0px;
}

.onlineshop-benefits ul.advantage-list-register li:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #eaeaea;
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
}

.onlineshop-benefits ul.advantage-list-register li .icon {
    position: absolute;
    left: -3px;
    top: -11px;
    color: #164194;
    font-size: 30px;
    width: 35px;
    height: 30px;
}

/* Widget */
.iwc-ui-mini-user-menu-header {
    background-color: #bb2d21;
}

/* Buttons */
.iwc-ui-mini-user-menu-logout-btn, .btn {
    background-color: #0e4194;
}

/* Language Select */
.iwc-shop-language-main {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
}

/* Administration Editier Modus */
.layelm-editor-layoutelement.iwc-menu-toolbar {
    position: initial;
}

.layelm-editor-layoutelement {
    position: relative;

    /*    display: inline-block; */
    display: block;
    width: 100%;
}

.cleditorMain, .cleditorMain textarea, .cleditorMain iframe {
    display: inline-block;
}

/* Accordion-Sitemap Men� */
.iwc-ui-acc-side-menu {
    margin-right: 20px;
}

/* Men� */
#main-nav {
    /* width: initial; */
    width: auto; /* for IE */
    left: -1000px;
}

/* Verf�gbarkeit */

.checkout-section .availability-location .availability {
    /*	margin-left: 90px; <<==LT*/
}

.checkout-section .availability-location .availability .availability-color-box {
    white-space: nowrap;
}

.checkout-section .availability-location .availability .availability-color-box::before {
    content: "";
    width: 15px;
    height: 15px;

    /*display: block; <<==LT*/
    /*position: absolute; <<==LT*/
    /*top: 50%; <<==LT*/
    /*left: -10px; <<==LT*/
    /*margin-top: -7px; <<==LT*/
    margin-right: 10px;
    display: inline-block;
    margin-bottom: -3px;
}

.availability-location .availability-color-box.green {
    color: #008a00;
}

.availability-location .availability-color-box.green::before {
    background: #008a00;
}

.availability-location .availability-color-box.yellow {
    color: #eb0;
}

.availability-location .availability-color-box.yellow::before {
    background: #eb0;
}

.availability-location .availability-color-box.red {
    color: #bb2d21;
}

.availability-location .availability-color-box.red::before {
    background: #bb2d21;
}

.checkout-section .item .availability-list .availability-location {
    padding-bottom: 10px;
    display: block;
}

/* Suche */
.loggedin.header-image .page-title .search-field-wrapper .search-field {
    padding-left: 80px;
}

@media (max-width: 767px) {
    .loggedin.header-image .page-title .search-field-wrapper .search-field {
        padding-left: 35px;
    }
}

/* MATLIST */
@media (min-width: 768px) {
    .filter-container {
        margin-bottom: 0px !important;
    }

    section.search-result {
        margin-top: 65px;
    }
}

.iwc-ui-matlist-toolbar .btn-group .iwc-ui-siwtch-grid-list-btn {
    border: none;
    background: #fff;
    color: #164194;
    padding: 8px 11px; /*!important;*/
}

.search-result-products.products-grid, .offer-products.products-grid, .replacement.products-grid {
    margin-top: 0px;
}

.iwc-ui-matlist-toolbar .btn-group .active.iwc-ui-siwtch-grid-list-btn {
    background: #164194;
    color: #fff;
}

.iwc-ui-matlist-toolbar {
    text-align: left;
}

.iwc-ui-matlist-toolbar .sort-dropdown {
    display: inline-block;
}

.products-grid .item .icons-container .add-to-icons.compare-icon .icon {
    font-size: 27px;
    margin-top: -3px;
    padding-left: 0;
    padding-top: 0;
    width: 41px;
}

/* Side-Menu */

.ui-iwc-acc-body-cont.acc-body-cont {
    /*background-color: initial;*/
    background-color: transparent; /* for IE */
}

#main-nav .glyphicon {
    display: none;
}

#main-nav > .ui-iwc-acc-side-menu {
    margin-left: -60px;
}

#main-nav > .ui-iwc-acc-side-menu > .ui-iwc-acc-body-cont {
    margin-top: 220px;
}

#main-nav > .ui-iwc-acc-side-menu > .ui-iwc-acc-body-cont > .ui-iwc-acc-body-header {
    display: none;
}

.acc-body-cont .acc-body-header {
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding: 0px;
}

.acc-body-cont a.iwc-ui-active {
    color: #164194 !important;
    background-color: #fff;
}

.acc-body-cont div.iwc-ui-active {
    background-color: transparent;
}

.acc-body-cont .acc-body-header:hover {
    background-color: transparent;
}

#main-nav {
    height: inherit;
}

#main-nav:hover {
    left: -370px;
}

#main-nav:hover .nav-icon:after {
    content: "s";
}

#main-nav .iwc-hide-side-menu:after {
    content: "y";
}

#main-nav:hover .iwc-hide-side-menu:after {
    content: "y";
}

#main-nav.active:hover,
#main-nav.active {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
}

#main-nav:hover {
    background: #164194;
}

/* Preis */
span.price,
span.recommended-price {
    padding-right: 5px;
}

@media (max-width: 767px) {
    .price-after-tax span.visible-xs-block,
    .price-before-tax span.visible-xs-block {
        display: inline !important;
    }
}

/* Produktliste */

.products-list .product-attribute-list {
    line-height: 24px;
    color: #000;
}

.search-result {
    /*    margin-top: 60px; Wegen Produktdetails */
}

.search-result .search-result-products.products-list .item:first-child {
    border-top: 1px solid #747474;
}

@media (max-width: 767px) {
    .search-result .search-result-products.products-grid .item:first-child {
        border-top: 1px solid #c0c0c0;
    }
}

.search-result .search-result-products.products-list .jscroll-inner > div > .item:first-child {
    border-top: none;
}

@media (min-width: 768px) {
    .search-result-products.products-grid .item, .offer-products.products-grid .item, .replacement.products-grid .item {
        margin-top: 40px;
    }
}

.iwc-grid-layout .row.iwc-list-product-info {
    display: none;
}

.iwc-grid-layout .product-qty.iwc-product-qty.add-to-box {
    display: none;
}

.iwc-grid-layout .iwc-product-price {
    width: 250px;
    padding-left: 0px;
}

.order-material-type-info {
    margin-top: -10px;
    color: #747474;
    margin-bottom: 10px;
}

span.different {
    display: block;
    color: #333333;
}

span.different b {
    font-weight: bold;
    padding-right: 4px;
}

@media (max-width: 1023px) {
    .search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
        top: 293px;
        display: none !important;
    }
}

@media (max-width: 767px) {
    .search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
        top: 317px;
    }
}

.product-comparison-table .iwc-grid-layout,
.product-comparison-table .iwc-product-info-container {
    padding-left: 0px;
}

.product-comparison-table .iwc-grid-layout .row,
.search-result-products.products-grid .productcontainer .row {
    margin-left: 0px;
    margin-right: 0px;
}

.col-sm-9.iwc-product-info-container {
    padding-left: 0px;
    padding-right: 0px;
}

.search-result-products .iwc-grid-layout {
    padding-left: 0px;
}

.iwc-grid-layout span.promotion-image {
    left: -10px;
}

/*Filter*/

.filter-container .container:empty {
    display: none;
}

.filter-container .active-filters .active-filter li .icon {
    font-size: 14px;
    margin-top: 0px;
    padding-left: 4px;
}

p.filter-button {
    white-space: normal !important;
    padding-right: 20px !important;
}

p.filter-button.collapsed {
    white-space: nowrap !important;
}

.filter-container .filter-row .dropdown-filter {
    padding-top: 55px;
}

.select-wrapper-for-arrow.filter-dropdown-wrapper {
    height: 39px;
}

/*

.filter-container .filter-row .filter-dropdown-wrapper {
    background: #fff;
    padding: 7px 15px;
    position: relative;
    display: inline-table;
    width: 23.25%;
    margin-left: 10px;
    margin-right: 10px;
}
*/

span.filter-value {
    padding-left: 3px;
}

span.filter-value span.filter-result-number {
    display: none;
}

#categories .row {
    /*   max-height: 400px;
       overflow-y: auto;
       overflow-x: hidden;*/
}

/*
.dropdown-filter .row {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}*/

.view-all-results {
    padding-left: 25px;
}

@media (max-width: 767px) {
    .view-all-results {
        padding-left: 0px;
        text-align: center;
    }

    .view-all-results a {
        display: block;
    }
}

/*Mini Button*/
.icons-container .add-to-icons .iwc-mini-button {
    border: none;

    /* background-color: initial; */
    background-color: transparent; /* for IE */
}

/* entfernt durch tg, da un�tig!!!
.icons-container .add-to-icons {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}
*/

.filter-container #categories {
    height: auto;
}

.filter-container #categories .checkbox label {
    display: inline-table;
}

/* .filter-container .category-dropdown-wrapper .checkbox .iwc-label-checkbox .iwc-ui-checkbox-text-span {
    display: table-cell;
    vertical-align: middle;
} */

.category-dropdown-wrapper .iwc-ui-scroll-container,
.filter-dropdown-wrapper .iwc-ui-scroll-container {
    max-height: 300px;
}

/* Switch BUtton */

.search-result .view-mode .icon {
    color: #164194;
    background: #fff;
}

.search-result .view-mode .icon.active, .search-result .view-mode .icon:hover {
    color: #fff;
    background: #164194;
}

.category #categories .checkbox .iwc-label-checkbox .styled-checkbox {
    float: left;
}

/* Scroll Container */
.iwc-ui-scroll-container .mCSB_dragger .mCSB_dragger_bar {
    background-color: #8f8f8f !important;
}

.iwc-ui-scroll-container .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #bb2d21 !important;
}

/* Checkout */

.confirmation-section .summary-wrapper .summary .price-summary .subtotal-brutto,
.confirmation-section .summary-wrapper .summary .price-summary .shipping-cost,
.confirmation-section .summary-wrapper .summary .price-summary .rounding-diff {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
}

.checkout-section .summary .price-summary .subtotal-brutto.subtotal-price,
.checkout-section .summary .price-summary .shipping-cost .subtotal-price,
.checkout-section .summary .price-summary .rounding-diff .subtotal-price {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 16px;
}

.iwc-checkout-delete-all {
    text-decoration: underline;
    display: none;
}

.iwc-checkout-delete-all-bottom {
    margin-bottom: 20px;
}

.iwc-checkout-delete-all-popup-div-text {
    margin-bottom: 30px;
    text-align: center;
}

@media (min-width: 992px) {
    .iwc-checkout-delete-all {
        text-align: right;
    }

    .iwc-checkout-delete-all-popup-div-submit {
        text-align: right;
        padding-right: 5px;
    }

    .iwc-checkout-delete-all-popup-div-cancle {
        text-align: left;
        padding-left: 5px;
    }

    .iwc-checkout-delete-all-popup-button {
        width: 65%;
        padding-left: 0px;
        padding-right: 0px;
        margin: 0px;
    }
}

@media (max-width: 991px) {
    .iwc-checkout-delete-all {
        text-align: center;
    }

    .iwc-checkout-delete-all-popup-div-submit {
        text-align: center;
    }

    .iwc-checkout-delete-all-popup-div-cancle {
        margin-top: 10px;
        text-align: center;
    }
}

.checkout-header-width {
    width: 100%;
}

@media (max-width: 991px) {
    .checkout-section .item .price-after-tax {
        /* float: left; */
    }
}

@media (min-width: 992px) {
    .checkout-section .summary .price-summary .subtotal-price, .checkout-section .summary .price-summary .total-price {
        float: right;
    }

    .confirmation-section .summary-wrapper .summary .price-summary .subtotal-netto {
        margin-bottom: 5px;
    }

    .confirmation-section .summary-wrapper .summary .price-summary .subtotal-brutto,
    .confirmation-section .summary-wrapper .summary .price-summary .shipping-cost {
        margin-bottom: 60px;
    }

    .confirmation-section .summary-wrapper .summary .price-summary .rounding-diff {
        margin-top: -60px;
        margin-bottom: 60px;
    }
}

/*
.checkout-section .summary{
	margin-top: 15px;
}
*/
.checkout-section .summary .refresh-button {
    cursor: pointer;
    padding-bottom: 10px;
}

@media (max-width: 1270px) and (min-width: 992px) {
    #PROCESS_TABLE_CHECKOUT .checkout-section {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .customer-name, .shipping-address-section .shipping-address .company-name {
        margin-left: 0px;
    }
}

.shipping-address-section .shipping-address .change-address-contact {
    /* position: initial;*/
    position: static; /* for IE */
    /*display: initial;*/
    display: inline; /* for IE */
}

.shipping-address-section .shipping-address {
    width: 100%;
}

.shipping-address-section .checkout-section-content .shipping-address .house-number {
    padding-left: 5px;
}

.shipping-address-section .shipping-address .change-address-contact:after {
    display: none;
}

.shipping-section .shipping-options li.inactive .shipping-failure-info {
    /*position: initial; */
    position: static; /* for IE */
    display: block;
}

.shipping-section .shipping-options li.inactive .shipping-failure-info:after {
    display: none;
}

.action-button .iwc-ui-co-prev-btn {
    margin-right: 10px;
}

.checkout-section.processed .checkout-section-content {
    display: block;
}

.confirmation-section .conditions-container .iwc-ui-checkbox-text-span {
    display: block;
    margin-left: 54px;
    font-weight: 100;
}

.js_process_table .ui-layout-process-number-long {
    display: none;
}

.js_process_table .ui-layout-process-number-short {
    display: inline;
}

.js_process_table .active .ui-layout-process-number-long {
    display: inline;
}

.js_process_table .active .ui-layout-process-number-short {
    display: none;
}

.shipping-failure-info h4 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: bold;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shipping-failure-info .item-outer {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 15px 0;
    margin-top: 5px;
}

.shipping-failure-info .item-outer .item-name {
    font-weight: bold;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    display: block;
}

.shipping-section .add-comment, .payment-section .voucher-link {
    cursor: pointer;
}

.checkout-section .item .availability-list .availability-location .order-avail-color-box .icon {
    margin-left: 5px;
}

.checkout-section .item .availability-list .availability-location .order-avail-color-box {
    text-decoration: underline;
    display: inline-block;
    width: 55px;
}

@media (max-width: 767px) {
    .confirmation .row .contact {
        padding-top: 80px;
    }
}

@media (max-width: 991px) {
    .payment-section .voucher {
        margin-bottom: 50px;
    }
}

@media (max-width: 991px) {
    .confirmation-section .shipping-and-billing .col-lg-3.col-md-3 {
        margin-top: 0px;
    }
}

.confirmation-section .shipping-and-billing .subtotal-brutto .winkler-info {
    color: #747474;
}

.iwc-order-note-input {
    float: right;
    display: inline-block;
    height: 40px;
    background: #eaeaea;
    border: none;
    text-align: right;
    padding-right: 10px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    margin-top: 5px;
    width: 128.153px;
}

@media (max-width: 340px) {
    .iwc-order-note-input {
        width: 100px;
    }
}

.iwc-order-note-download {
    float: right;
}

.iwc-order-note-icon {
    margin-left: 5px;
    color: #747474;
}

.iwc-order-note-text-style {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.iwc-order-text-color {
    color: #000;
}

@media (max-width: 575px) {
    .iwc-order-number-row {
        margin-bottom: 30px;
    }
}

.confirmation-section .summary-wrapper .summary .voucher-summary {
    margin-top: 0px;
}

/* Product Comparison JR innovate */

@media (max-width: 767px) {
    #product-comparison-form .icons-container {
        width: 41px;
    }

    .product-comparison-table .product-content .icons-container {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
    }
}

#product-comparison-form .iwc-ui-use-as-link {
    z-index: 1;
    background-color: white;
}

.overlay-inner.overlay-inner-top {
    padding: 0px !important;
    margin: 0px !important;
}

.overlay-inner.overlay-inner-top h1 {
    margin-bottom: 5px !important;
}

#mCSB_2_scrollbar_horizontal {
    /*	top: 0px; */
}

@media (min-width: 1060px) {
    div.product-comparison-overlay {
        width: 1060px;
    }
}

/* Produktvergleich */

.remove-products strong {
    padding-right: 8px;
}

.red-label {
    color: red !important;
}

.product-comparison-overlay .remove-products > * {
    cursor: pointer;
}

.product-comparison-table td {
    position: relative;
}

.product-comparison-table th {
    height: auto;
    padding-left: 20px;
    background-color: white;
    position: relative;
    z-index: 10;
    max-width: 190px !important;
    width: 190px !important;
    overflow: hidden;
}

.product-comparison-form {
    max-width: 100%;
    overflow-x: visible;
    height: 100%;
}

.product-comparison-overlay .modal-body {
    overflow: hidden;
}

#product-comparison-slider.ui-widget-content {
    background-color: transparent;
    height: 6px;
    width: auto;
    margin-right: 9%;
    border-radius: 16px;
    padding: 0px;
}

#product-comparison-slider .ui-slider-handle {
    background-color: #8f8f8f;
    height: 6px;
    margin: 5px 0px;
    width: 10%;
    border-radius: 16px;
    border: 0px;
    top: -5px;
    cursor: pointer;
}

.ui-widget-content {
    background: none !important;
    border: none !important;
}

#product-comparison-slider .ui-slider-handle:hover {
    background-color: #bb2d21;
}

.product-comparison-slider-background {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 5px 20px !important;
    border-radius: 16px;
    border: 1px solid white;
    height: 9px !important;
}

.custom-scrollbar-container.fixed-slider {
    top: 0px !important;
    width: 100% !important;
    position: fixed !important;
    border-top: 10px solid white;
    background-color: white;
}

.product-comparison-overlay .remove-products {
    text-decoration: none;
    text-align: left;
}

.product-comparison-overlay .remove-products strong:hover {
    text-decoration: none;
    color: #000000;
}

.product-comparison-overlay .remove-products span {
    margin-left: 0px;
    color: #164194;
}

.product-comparison-table {
    margin-bottom: 30px;
}

.product-comparison-table .iwc-ui-checkbox-text-span {
    font-weight: bold;
}

.product-comparison-table .product-content:hover > .cell_content .icons-container {
    display: block;
}

form.compare-icon .icon.winkler-trash {
    display: block;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 18%;
}

.product-comparison-table div.product-image div .promotion-image img {
    height: auto !important;
}

.product-comparison-table div.product-image div img {
    width: auto !important;
    height: 160px !important;
}

.product-comparison-table .property-value .cell-content {
    border-top: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.product-comparison-table .property-value .cell-content:first-child {
    border-top: 1px solid #eaeaea;
    padding-top: 16px;
}

.product-comparison-table .property-value .cell-content:last-child {
    padding-bottom: 16px;
}

#product-comparison-form .iwc-product-price .row div {
    margin-top: -5px !important;
}

h1.overlay-title.product-comparison-title {
    margin-top: 25px;
}

/* Produktvergleich fixed Header */

#product-comparison-form {
    position: relative;
    top: 10px;
}

.remove-products {
    margin: 10px 5px 18px 0px;
    text-align: right;
}

.custom-scrollbar-container {
    position: relative;
    top: 40px;
    z-index: 12;
    margin-left: 190px;
}

#product-comparison-logo-container {
    background-color: transparent;
    background-repeat: no-repeat;
    display: block;
    font-size: 0;
    height: 77px;
    width: 120px;
    position: absolute;
    margin: 25px;
    margin-top: 0px;
}

.product-comparison-no-items {
    display: block;
    width: 100%;
    text-align: center;
}

/* Mobile */
@media (max-width: 1024px) {
    #product-comparison-logo-container {
        width: 67px;
    }

    .product-comparison-title,
    .product-comparison-no-items {
        position: relative;
        top: 50px;
    }

    .product-comparison-amount {
        position: relative;
        top: 50px;
    }

    .remove-products {
        position: relative;
        top: 25px;
        text-align: center;
    }

    .custom-scrollbar-container {
        display: none !important;
    }

    #product-comparison-form {
        position: relative;
        top: 50px;
    }

    #product-comparison-form .item.iwc-grid-layout {
        padding-left: 0px;
        padding-right: 0px;
    }

    #product-comparison-form .iwc-product-info-container .row {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.product-comparison-table.fixed-header thead {
    top: 0px;
    border-top: 20px solid white;
    position: fixed;
    background-color: white;
    z-index: 11;
    width: 100%;
}

.product-comparison-table.fixed-header thead .product-image {
    display: none;
}

.product-comparison-table.fixed-header thead .iwc-product-price {
    display: none;
}

.product-comparison-table.fixed-header thead .icons-container {
    display: none !important;
}

.product-comparison-table.fixed-header th {
    left: 0px;
    right: 0px;
    max-width: 190px !important;
    width: 190px !important;
    height: 100%;
    overflow: hidden;
}

.product-comparison-table.fixed-header td {
    position: relative;
    right: 0px;
}

.product-comparison-table.fixed-header tbody {
    position: absolute;
    top: 631px;
}

span.promotion-image {
    padding: 5px 10px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

@media (max-width: 991px) {
    #mobile-subheader-dropdown .main-mobile-nav > li ul li a {
        padding: 5px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li ul li {
        margin-bottom: 20px;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li ul li a {
        padding: 5px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li ul {
        padding-top: 20px;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper {
        z-index: 2 !important;
    }
}

/* Background Overlay */

#iwc-background-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    z-index: 1000;
    display: none;
}

/* Product Details */

.iwc-ui-frontend-matdetail .iwc-ui-tab {
    width: 100%;
    text-align: center;
}

.product-detail-overview .img_zoom_gallery a {
    display: block;
    height: 70px;
    width: 70px;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    line-height: 70px;
    text-align: center;
}

.product-detail-overview .img_zoom_gallery a img {
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    max-height: 70px;
    max-width: 70px;
}

.product-detail-overview .img_zoom_gallery {
    float: left;
}

.product-detail-overview .iwc-image-zoom {
    padding: 40px;
    display: inline-block;
    text-align: center;
    line-height: 370px;
}

.product-detail-overview .iwc-image-zoom img {
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 767px) {
    .product-detail-overview .img_zoom_gallery {
        float: initial;
    }

    .product-detail-overview .img_zoom_gallery a {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 50px;
    }
}

.iwc-ui-frontend-matdetail .breadcrumb {
    text-align: center;
}

.zoomContainer .zoomWindowContainer > div {
    margin-left: 62px;
    margin-top: -20px;
}

.productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper .specifics-data {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
}

/*
.productdetails-container .specifics-table-container .specifics-table > div:nth-child(1) .specifics-data-wrapper{
    border-top: none !important;
}


.productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper{
    border-top: 1px solid #fff !important;
}
*/

.productdetails-container .specifics-table-container .specifics-table .row {
    margin: 0;
}

.product-detail-overview .availability-list .availability-location {
    border-top: 0px;
    border-bottom: 1px solid #747474;
}

.iwc-ui-frontend-matdetail .productdetails-container .iwc-ui-spare-parts-head {
    border-bottom: 1px solid #747474;
}

.iwc-ui-spare-parts-head .iwc-ui-spare-parts-head-text {
    padding: 5px 10px 5px;
    background-color: #eaeaea;
}

@media (max-width: 575px) {
    .iwc-ui-spare-parts-head .iwc-ui-spare-parts-head-text {
        line-height: 18px;
    }
}

.iwc-ui-spare-parts-head-text > .iwc-ui-spare-parts-head-text-wrap {
    display: inline-block;
}

.productdetails-container .specifics-table-container .specifics-table {
    padding: 0px;
}

p.order-material-number.text-center.winf {
    margin-bottom: 0px;
}

.add-to-box .btn:first-child {
    margin-top: 15px;
}

.add-to-box .btn.add-to-cart {
    margin-top: 0px;
}

.add-to-box form {
    padding-top: 0px !important;
}

.breadcrumb > li + li:before {
    content: "\003E";
    color: rgb(51, 51, 51);
}

.productdetails-container .iwc-ui-tab-head-container .iwc-ui-tab-head a {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 10px;
}

.productdetails-container .gray-tabs .iwc-ui-tab-head-container {
    background: #fff;
}

.productdetails-container .gray-tabs .iwc-ui-tab-head-container li:hover,
.productdetails-container .gray-tabs .iwc-ui-tab-head-container li.active {
    background: #fff;
}

.iwc-ui-frontend-matdetail .iwc-ui-tab .iwc-ui-tab-content-container {
    text-align: left;
}

.product-detail-overview .row.units {
    padding-top: 15px;
}

/* Material List */

/*.products-list .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, .products-list .item .icons-container .add-to-icons.compare-icon .icon {
    	font-size: 27px;
    	width: 41px;
	margin-top: 7px;
}*/

/*.search-result-products.products-grid .icons-container .compare-icon.add-to-icons {
	padding-top: 10px;
}*/

form.compare-icon.add-to-icons > button {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;
}

form div.sort-direction {
    margin-left: 10px;
    display: inline-flex;
}

form div.sort-by {
    display: inline-flex;
}

form div.sort-direction .select-wrapper-for-arrow.sorting-wrapper {
    margin-left: 10px;
}

form div.sort-direction strong {
    padding-top: 7px;
}

/* Marketing */

div.main div.more-offers-link.text-center {
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 767px) {
    .more-offers-link .btn {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.marketing-active-checkbox {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
    cursor: default !important;
}

.iwc-ui-marketing-link {
    text-decoration: underline;
}

.iwc-ui-marketing-preview .form-control {
    background-color: #eaeaea;
}

#id_marketing-edit .iwc-ui-tab-content .input-group-addon {
    background-color: #fff;
    border: #ddd;
}

@media (min-width: 1280px) {
    .catalog-section.contact-section .iwc-marketing-catalog.col-md-4 {
        width: 286px;
    }
}

@media (max-width: 1280px) {
    .catalog-section.contact-section .iwc-marketing-catalog.col-md-4 {
        width: 260px;
    }
}

.iwc-ui-marketing-catalog-link-col {
    text-align: center;
}

.iwc-ui-marketing-catalog-link-icon {
    color: #164194;
}

.iwc-catalog-link:focus {
    text-decoration: none;
}

@media (min-width: 768px) and (max-width: 991px) {
    .offer-section .tab-products .offer-single-product, .offer-section .offer-products .offer-single-product {
        padding-bottom: 0px;
    }
}

@media (min-width: 992px) {
    .offer-section .tab-products .offer-single-product, .offer-section .offer-products .offer-single-product {
        padding-bottom: 30px;
    }
}

#overview_promo .overview_promo .iwc-ui-tab-content {
    min-height: 100px;
}

#overview_promo .overview_promo .iwc-ui-tab-content .iwc-loading-circle-img {
    margin-top: 30px;
}

.scaleprice-info-text {
    color: #bb2d21;
}

.iwc-ui-siwtch-grid-list .iwc-list-layout .iwc-product-price .row .scaleprice-info-text {
    margin-top: -20px !important;
    margin-left: 10px;
}

/*Customizing*/

.iwc-default-checkbox {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
}

.iwc-default-radio {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
}

#GROUPING-PREFERENCES .input-group a {
    text-decoration: underline;
}

.iwc-ui-mk-overview .nav li a {
    padding: 10px;
}

.search-result .sort-direction select {
    width: 100%;
}

/* Contact */

#iwc-contact-form .contact-input.contact-postalcode {
    margin-bottom: 10px;
}

.contact-wrapper {
    width: 300px;
}

.contact-wrapper h1 {
    text-align: left;
}

.contact-wrapper, .contact-wrapper a {
    color: white;
}

.contact-wrapper h4 {
    font-size: 20px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    margin-bottom: 10px;
}

.contact-wrapper a:hover,
.contact-wrapper a:hover .icon,
.contact-wrapper a,
.contact-wrapper a .icon {
    color: white;
}

.contact-wrapper a .icon {
    margin-left: 5px;
}

@media (max-width: 991px) {
    .contact-wrapper {
        width: 100%;
    }

    .contact-wrapper h1 {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
    }

    .contact-wrapper .winkler-close {
        color: #fff;
        position: absolute;
        right: 20px;
        top: 15px;
    }

    .contact-wrapper h4 {
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        margin-bottom: 15px;
    }

    .contact-wrapper a .icon {
        float: right;
    }
}

.contact-input-container .iwc-register-invalid-message.iwc-ui-reg-error-absolut {
    position: relative;
    top: 0;
}

.contact .icon.dealer-mail {
    margin-left: 5px;
}

/* Shoppinglist */
/*
.shopping-list-dashboard .container-outer.gray-background.shopping-list-topbar {
	width: 100vw;
	left: calc(-50vw + 50%);
	position: relative;
}*/
/* entfernt durch tg
.shopping-list-dashboard form .icons-container .add-to-icons {
	padding-top: 0px !important;
}
*/

/* entfernt durch lt: Einkaufsliste Produkt�bersicht werden bei Hover eingeblendet
#iwc-shoppinglist-edit-list .shopping-list-detail-table .row:hover .icons-container-column {
	display: block;
}
*/

.add-to-shopping-list-overlay .new-information {
    display: block;
}

#iwc-shoppinglist-edit-list .icons-container .add-to-icons {
    padding-top: 0px !important;
}

.shopping-list-detail-buttons .row > div input {
    margin-left: 10px;
}

@media (max-width: 991px) and (min-width: 768px) {
    .shopping-list-topbar .new-list-wrapper {
        padding-left: 0px;
        text-align: right;
    }
}

.add-to-shopping-list-overlay .shopping-list-information {
    display: block;
}

.shopping-list-dashboard .shopping-list-topbar .new-list-wrapper .btn.btn-default.btn-blue {
    padding: 7px 18px;
}

.shopping-list-detail-table img {
    padding-right: 10px;
}

#shoppinglist-details-article-form .select-all-items,
#shoppinglist-details-article-form .deselect-all-items {
    float: left;
}

.create-new-sl-form .iwc-register-invalid-message {
    top: 0px;
    width: 93%;
    text-align: right;
}

.shopping-list-dashboard .row.search-information {
    /*width: 1170px; <=LT*/
    /*margin-left: auto; <=LT*/
    /*margin-right: auto; <=LT*/
}

.shopping-list-dashboard .row.search-information .search-show-all {
    text-align: right;
}

@media (max-width: 768px) {
    .edit-shopping-list-data .save-data-action {
        padding-bottom: 15px;
    }
}

.shopping-list-table .row > .iwc-list-detail {
    padding-left: 5px;
}

@media (max-width: 576px) { /* 20171113 @innovate KN: Ausrichtung Einkaufsliste Bestellen Button */
    .shopping-list-table .row > .icons-container.horizontal {
        padding-top: 14px;
        float: right;
    }

    .iwc-list-detail .comment {
        min-height: 18px;
    }
}

/* Request Product */

#request_product_formular .row {
    margin-bottom: 10px;
}

#request_product_formular .row.form-group.iwc-form-checkbox {
    margin-bottom: 0px;
}

#request_product_formular .form-group .contact-input-container label span.styled-checkbox.icon {
    margin: 0px;
}

#request_product_formular .row textarea {
    height: 142px;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
}

#request_product_formular .btn {
    float: right;
}

.shadow-sortiment .iwc-prod-on-request {
    padding-top: 12px;
    padding-bottom: 1px;
}

.search-result-products .shadow-sortiment {
    margin-bottom: 9px;
}

.search-result-products .shadow-sortiment strong {
    color: #000;
}

.shadow-sortiment p.iwc-prod-on-req-text {
    margin-top: 1px;
}

/* Meine Bestellungen */

section.my-orders-section {
    max-width: 860px;
}

.modal-body section.my-orders-section {
    padding-left: 0px;
    padding-right: 0px;
}

@media (max-width: 767px) {
    .my-orders-table .order-residue-header {
        display: none;
    }
}

/* Meine Bestellungen END */

/* Header */
/*
@media (max-width: 767px) {

	#search-form,
	#search-form .pull-left.search-field-wrapper,
	#search-form .pull-left.search-button-wrapper {
		width: 100%;
	}



	#iwcsearch {
		background-position: 10px 10px;
	}

	#search-form .pull-left.search-field-wrapper {
		margin-bottom: 15px;
	}
}

@media (min-width: 767px) {
	#search-form .pull-left.search-field-wrapper {
		width: 65%;
	}

	#search-form .pull-left.search-button-wrapper {
		width: 35%;
	}
}
*/
#search-form .search-button {
    height: 40px;
    width: 100%;
    line-height: 1;
    padding: 7px 0;
}


/* Toolbar */

.slice {
    /*overflow: hidden;*/
    clear: both;
    display: block;
    position: fixed;
    top: 0px;
    z-index: 4000;
    width: 100%;
    height: 40px;
    line-height: auto;
    left: 0px;
}

.header-nav > a {
    float: left;
    width: auto;
    min-width: 55px;
    height: 40px;
    transition: background-color 0.25s ease;
    text-align: left;
    position: relative;
}

div#iwc-toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.toolbar_item {
    background-position: 0px 0px;
    background-size: 100% 100%;
}

#inner {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.header-nav {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    left: 0px;
    z-index: 20;
}

.header-nav > a:hover {
    transition: background-color 0.5s ease;

    /*background:gray;*/
}

.header-nav > a:after {
    background-repeat: no-repeat;
    text-indent: -119988px;
    overflow: hidden;
    text-align: left;
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    left: 50%;
    margin-top: -8px;
    top: 50%;
}

.section-m {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 0px;
    display: block;
    background: lightgray;
    z-index: 9999;
    position: absolute;
}

.iwc-popover.contact {
    margin-left: 75px;
    margin-top: -6px !important;
}

.iwc-popover.mobile-contact {
    left: 0px !important;
    right: 0px !important;
    border-left: none;
    border-right: none;
    margin-top: 20px !important;
    position: absolute;
    z-index: 2000 !important;
}

.fixed-popover {
    position: fixed;
}

.iwc-contact-popover {
    position: fixed;
}

@media (max-width: 767px) {
    .iwc-contact-popover {
        position: absolute;
    }
}

/* Toolbar END */

/* Login */
/*
@media (min-width: 992px){
.gray-tabs .nav-tabs > li .circle {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}
}
*/
.gray-tabs .nav-tabs > li .tab-label {
    /*margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 15px;*/
}

.gray-tabs .iwc-ui-tab-head:hover {
    color: #bb2d21;
}

.gray-tabs .iwc-ui-tab-head-active:hover {
    color: #000000;
}

.gray-tabs .nav-tabs > li span {
    border: none;
    margin-right: 0;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 15px;
    line-height: 1.42857143;
    margin-top: 10px;
}

.productdetails-container .gray-tabs .nav-tabs > li span {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 18px;
}

/* Login END */

/* Marketing Dropdowns */

.select-wrapper-for-arrow.form-control {
    padding: 0px;
    height: 100%;
    border: none;
}

.select-wrapper-for-arrow.form-control:after {
    z-index: 2;
}

/* Marketing Dropdowns END */

/* Basket */

.checkout-section .item {
    border-top: none;
}

#change-address-consultant {
    background: #eaeaea;
    color: #000;
    padding: 25px 15px 40px;

    /*	position: absolute;
    	right: 15px;
    	top: 60px;*/
    width: 300px;
    z-index: 1000;
    text-align: left;
}

#change-address-consultant p {
    margin-bottom: 30px;
}

#change-address-consultant h1 {
    line-height: 40px;
    margin-bottom: 30px;
    text-align: left;
}

#change-address-consultant h4 {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 20px;
    margin-bottom: 10px;
}

#change-address-consultant a .icon {
    color: #164194;
    margin-left: 5px;
}

#shipping-fail-tooltip {
    max-width: 700px;
    background: #eaeaea;
    color: #000;
    padding: 0px 45px 15px 15px;
    z-index: 1;
}

#shipping-fail-tooltip .winkler-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

#shipping-fail-tooltip .item-outer {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 15px 0;
    margin-top: 5px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

#shipping-fail-tooltip .shipping-failure-info a .icon {
    color: #bb2d21;
}

.shipping-address-section .add-new-address .icon.winkler-expand {
    color: #bb2d21;
}

.jq-process-step-30 .ui-layout-process-content .action-button.text-center,
.jq-process-step-40 .ui-layout-process-content .action-button.text-center {
    position: relative;
}

.jq-process-step-30 .ui-layout-process-content .action-button.text-center,
.jq-process-step-40 .ui-layout-process-content .action-button.text-center {
    top: -10px;
}

@media (min-width: 768px) {
    .jq-process-step-30 .ui-layout-process-content .action-button.text-center,
    .jq-process-step-40 .ui-layout-process-content .action-button.text-center {
        top: -30px;
    }
}

@media (min-width: 1024px) {
    .jq-process-step-30 .ui-layout-process-content .action-button.text-center,
    .jq-process-step-40 .ui-layout-process-content .action-button.text-center {
        top: -70px;
    }
}

@media (min-width: 768px) {
    .checkout-section .summary {
        padding-top: 20px;
    }
}

/* Basket END */

/* Bestellbestaetigung */

.iwc-ui-class-order-confirm-head img {
    height: 44px !important;
}

.iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content {
    float: right;
}

@media (min-width: 1035px) {
    .iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content div {
        margin-left: -20px;
    }
}

@media (min-width: 1120px) {
    .iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content div {
        margin-left: -40px;
    }
}

@media (min-width: 1271px) {
    .iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content div {
        margin-left: -100px;
    }
}

@media (max-width: 991px) {
    .iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content div {
        margin-left: -100px;
    }
}

@media (max-width: 767px) {
    .iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content div {
        margin-left: -60px;
    }
}

@media (max-width: 665px) {
    .iwc-ui-class-order-confirm-head .jq_iwc_load_ajax_content div {
        margin-left: -30px;
    }
}

.winkler-fullscreen-modal > .modal-dialog > .modal-content {
    padding: 30px 20px 45px 20px;
}

section.my-orders-section {
    margin-bottom: 0px;
}

.iwc-ui-siwtch-grid-list .iwc-grid-layout .iwc-product-price .row div {
    margin-top: -5px !important;
}

.iwc-ui-siwtch-grid-list .iwc-list-layout .iwc-product-price .row div {
    margin-top: -5px !important;
}

@media (max-width: 992px) {
    .confirmation-section .summary-wrapper .summary .price-summary .subtotal {
        margin-bottom: 20px;
    }

    .confirmation-section .summary-wrapper .summary .price-summary .total {
        margin-top: 30px;
    }
}

@media (min-width: 992px) {
    .confirmation-section .summary-wrapper {
        position: relative;
        float: right;
    }
}

.iwc-ui-class-order-confirm-content {
    margin-bottom: 15px;
}

/* Internet Explorer Fixes */

#service-nav li a .service-menu-container .current-language.icon {
    vertical-align: baseline;
}

select::-ms-expand {
    display: none !important;
}

#iwc-ui-mat-list-btn,
#iwc-ui-mat-grid-btn {
    padding: 0px !important;
}

#iwc-ui-mat-list-btn.active .icon,
#iwc-ui-mat-grid-btn.active .icon {
    background-color: #164194;
    color: #ffffff;
}

#iwc-ui-mat-list-btn .icon,
#iwc-ui-mat-grid-btn .icon {
    background-color: #ffffff;
    color: #164194;
    vertical-align: middle;
    padding-top: 10px;
    padding-left: 0px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .search-result .sort-by select, .order-section .sort-by select {
        width: 100% !important;
    }

    .search-result .sort-by .select-wrapper-for-arrow, .order-section .sort-by .select-wrapper-for-arrow {
        width: auto !important;
    }
}

#PROCESS_TABLE_CHECKOUT .checkout-section-content .voucher-code-wrapper button {
    background-color: transparent;
}

.catalog-section.contact-section .catalog-link {
    margin-top: 0px;
}

/* Internet Explorer Fixes END */

/* Register Validation */

.iwc-register-invalid-message {
    color: #bb2d21;
    white-space: nowrap;
    display: none;
}

.iwc-register-invalid-input:invalid {
    border: 1px solid #bb2d21 !important;
    -webkit-box-shadow: 0 0 5px 0 #bb2d21 !important;
    -moz-box-shadow: 0 0 5px 0 #bb2d21 !important;
    box-shadow: 0 0 1px 0 #bb2d21 !important;
}

.iwc-ui-reg-error-absolut {
    position: absolute;
}

@media (max-width: 767px) {
    .iwc-ui-reg-error-absolut {
        /*top: 40px; */
    }
}

/* 20180305 @innovate KN: Meldung unter Input
@media (min-width: 768px) {
	.iwc-ui-reg-error-absolut {
 		top: -25px;
	}
}
*/

@media (max-width: 991px) {
    .container.password-forgotten .iwc-ui-reg-error-absolut {
        top: 40px;
    }
}

@media (min-width: 992px) {
    .container.password-forgotten .iwc-ui-reg-error-absolut {
        top: -25px;
    }
}

.shopping-list-overlay .iwc-register-invalid-message.iwc-ui-reg-error-absolut {
    top: 73px;
}

.new-shopping-list-form .iwc-register-invalid-message {
    top: 0px;
    width: 96%;
    text-align: right;
}

/* Register Validation END */

/*Promotion*/
.iwc-ui-tab .overview_promo {
    margin-bottom: 0px;
}

@media (min-width: 1100px) {
    .gray-tabs .overview_promo.nav-tabs > li .tab-label {
        margin-right: 15px;
    }
}

#overview_promo .form-group.visible-xs-block {
    margin-left: 20px;
    margin-right: 20px;
}

/* Search */
.search-suggest ul {
    /* max-height: 250px;
     overflow-x: hidden;
     overflow-y: auto;*/
    width: 100% !important;
    border: none;
    background: none;
}

/* Required Products */

@media (min-width: 767px) {
    .required-products-overlay .required-products {
        margin: 0 auto -65px auto;
        padding: 0;
    }
}

@media (max-width: 767px) {
    .required-products-overlay .required-products {
        margin-bottom: -40px;
        margin-top: 0px;
        padding-top: 0px;
    }
}

.required-products-overlay {
    display: block;
    position: relative;
}

@media (min-width: 767px) {
    top: -30px;
}

.required-products-overlay .required-products img {
    max-width: 160px !important;
    height: auto !important;
}

.required-products-overlay .required-products .products-list {
    margin-bottom: 0px;
}

.required-products-overlay {
    background: none;
}

.required-products-overlay .required-products .action-buttons .buttons {
    float: left;
}

/* Schnellerfassung / Quickorder*/

@media (min-width: 992px) {
    .invalid-text {
        top: -2px;
    }

    .quick-order .row .icon {
        top: -2px;
    }
}

/* Footer */
.iwc-show-footer-subgroup {
    display: block !important;
}

/* Basket Info Text */
@media (max-width: 991px) {
    .legal-explanation {
        display: block;
    }
}

/* Productdetails */

@media (min-width: 992px) and (max-width: 1024px) {
    .container.product-detail-overview .iwc-image-zoom.product-image img {
        max-width: 315px !important;
        max-height: 315px !important;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .container.product-detail-overview .iwc-image-zoom.product-image img {
        max-width: 330px !important;
        max-height: 330px !important;
    }
}

@media (min-width: 1200px) {
    .container.product-detail-overview .iwc-image-zoom.product-image img {
        max-width: 370px !important;
        max-height: 370px !important;
    }
}

/* Body width fix */

.row.catalog-section.contact-section {
    /*
    margin-left: auto;
    margin-right: auto;
    */
    max-width: 1150px;
}

.row.catalog-section.contact-section > div.catalog {
    /*
    	    padding-left: 0px;
    	    padding-right: 0px;
    */
}

.offer-section {
    padding-left: 20px;
    padding-right: 20px;
}

/* Sidebar Basket */

#service-nav li.basket:hover {
    left: -95px;
}

#service-nav .service-menu-element.basket .items-on-list-basket {
    color: #bb2d21;
}

@media (max-width: 576px) {
    .mobile-subheader-dropdown .service-nav .compare {
        display: none;
    }
}

#mobile-subheader-dropdown .service-nav .basket, #mobile-subheader-dropdown .service-nav .compare {
    position: relative;
}

#mobile-subheader-dropdown .service-nav .basket .service-item-quantity-wrapper, #mobile-subheader-dropdown .service-nav .compare .service-item-quantity-wrapper {
    position: absolute;
    right: 20px;
}

#mobile-subheader-dropdown .service-nav .basket .service-item-quantity-wrapper .item-quantity-wrapper, #mobile-subheader-dropdown .service-nav .compare .service-item-quantity-wrapper .item-quantity-wrapper {
    background: #164194;
    border-radius: 50%;
    display: block;
    height: 18px;
    padding: 0;
    position: absolute;
    right: 9px;
    text-align: center;
    top: 12px;
    width: 18px;
    z-index: 1;
}

#mobile-subheader-dropdown .service-nav .basket .service-item-quantity-wrapper .item-quantity-wrapper .item-quantity, #mobile-subheader-dropdown .service-nav .compare .service-item-quantity-wrapper .item-quantity-wrapper .item-quantity {
    color: #fff;
    font-size: 10px;
    position: relative;
    top: -7px;
}

/* Select disabled */

.select-wrapper-for-arrow.disabled:after {
    display: none;
}

.select-wrapper-for-arrow.disabled select {
    color: #747474;
    pointer-events: none;
}

.select-wrapper-for-arrow.disabled {
    cursor: not-allowed;
}

/* Kontaktformular */

#iwc-contact-form textarea.form-control {
    background-color: #eaeaea;
    color: #000000;
    font-size: 15px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

#iwc-contact-form .btn-submit {
    float: right;
    margin-bottom: 20px;
}

#iwc-contact-form {
    margin-bottom: 30px;
}

.contact-label-container {
    height: 40px;
    display: flex;
    align-items: center;
}

/* Kontaktformular END */

/* Button Fix */

@media (max-width: 767px) {
    a.btn, .btn {
        padding: 7px 60px;
    }
}

/* Button Fix END */

/* Service Nav Basket */

#service-nav li.basket a {
    background-color: #bb2d21;
}

/* Service Nav Basket END */

/* My Account */

.my-account-section dd {
    margin-bottom: 6px;
}

@media (min-width: 768px) {
    .my-account-section dt {
        width: 40%;
    }
}

/* Installation products */

.required-products-overlay .required-products .product-attribute-list {
    line-height: 24px;
}

#iwc-required-products-form .iwc-deselect-all,
#iwc-required-products-form .iwc-select-all {
    float: right;
}

#iwc-required-products-form .products-list .row.item:first-child {
    padding-top: 0px;
}

.iwc-installation-dialog-title {
    margin-bottom: 10px;
}

/* RMI Datenbank */

@media (max-width: 991px) {
    .service-section, .service-bottom div {
        text-align: left;
    }
}

/* Aktionsangebote */

.products-list .jscroll-added .item:first-child {
    border-top: 1px solid #747474;
}

@media (max-width: 767px) {
    .productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper .col-sm-6 {
        padding-bottom: 0px !important;
    }
}

/* Aktionsangebote END */

/* Mobile Slider */

@media (max-width: 991px) {
    .mobile-header-slider .current-target-group-triangle.nfz-triangle .triangle-right-side {
        width: 61%;
    }
}

/* Mobile Slider END */

/* Productdetails */

.productdetails-container .price-box .nav-tabs > li.active > a:after,
.productdetails-container .price-box .nav-tabs > li.active > a:focus:after,
.productdetails-container .price-box .nav-tabs > li.active > a:hover:after {
    display: none;
}

.products-list .item-price-box .nav-tabs li:first-child {
    z-index: 1;
}

.item-price-box .regular-price a {
    color: #bb2d21;
}

/* Productdetails END */

/* News Slider */

.winkler-fullscreen-modal .mobile-slider-container {
    display: inline-flex;
    left: 255px;
    position: fixed;
    top: 15px;
}

.news-section .scrollable {
    height: auto;
}

@media (max-width: 1024px) {
    .news-section article {
        width: 360px !important;
    }
}

@media (max-width: 800px) {
    .news-section article {
        width: 310px !important;
    }
}

/* News Slider END */

/*FAQ - START*/
.accordion-item h2[aria-expanded="true"] .winkler-expand:before {
    content: "\6b";
}

/*FAQ - END */

/* Account Management */

.iwc-acc-mgmt-settings {
    position: relative;
    top: 30px;
}

/* Account Management END */

.iwc-product-price .row .col-lg-12 {
    padding-left: 0px;
}

/* Product Image */

@media (max-width: 1024px) {
    .zoomContainer {
        display: none;
    }
}

#bookmark-section p {
    margin-bottom: 30px;
}

#bookmark-section p a {
    text-decoration: underline;
}

/* Basket Popover Delete all */

@media (max-width: 991px) {
    .iwc-checkout-delete-all-popup-button {
        width: 100%;
        margin: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

/* Dragbar fix */

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar, .mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, 0.55);
}

/* Dragbar fix END */

/* Product Price */

.products-list .item-price-box .regular-price a {
    color: #bb2d21;
    text-decoration: none;
}

/* Product Price END */

/* Password Forgotten */

@media (max-width: 767px) {
    .password-forgotten-form.iwc-validation-form .btn-right a.btn.iwc-validation-fake-submit {
        display: block;
    }
}

/* Password Forgotten END */

/* Coockie Notification */

.cookie-notification {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 25px 35px;
    z-index: 9999999;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1199px) {
    .cookie-notification {
        padding: 25px 0;
    }
}

.cookie-notification .message {
    padding-top: 10px;
    text-align: center;
}

.cookie-notification .message .more-info {
    display: inline-block;
    line-height: 25px;
}

.cookie-notification .confirm-notification {
    padding: 5px 65px;
    margin-left: 25px;
}

@media (max-width: 1199px) and (min-width: 992px) {
    .cookie-notification .confirm-notification {
        padding-left: 45px;
        padding-right: 45px;
    }
}

@media (max-width: 923px) {
    .cookie-notification .confirm-notification {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 15px;
        width: 150px;
    }
}

@media (max-width: 991px) {
    .cookie-notification {
        border-bottom: 1px solid #eee;
        position: fixed;
        bottom: auto;
        top: 0;
        padding: 20px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .cookie-notification .message {
        padding-top: 0;
        margin-bottom: 10px;
        font-size: 0.85em;
        line-height: 1.8em;
    }

    .cookie-notification .winkler-arrow-right {
        font-size: 16px;
    }

    .cookie-notification .btn-block {
        width: auto !important;
        display: inline-block;
        padding-left: 65px;
        padding-right: 65px;
    }
}

.cookie-notification-space-footer {
    height: 100px;
    width: 100%;
    display: block;
    background-color: transparent;
}

@media (max-width: 991px) {
    .cookie-notification-space-footer {
        display: none;
    }
}

.cookie-notification-show.cookie-notification-container-desktop {
    display: block;
}

@media (max-width: 991px) {
    .cookie-notification-show.cookie-notification-container-desktop {
        display: none;
    }
}

.cookie-notification .winkler-arrow-right {
    color: #bb2d21;
}

.cookie-notification .confirm-notification {
    background-color: #bb2d21;
}

/* Coockie Notification END*/

/* Anlage und Bearbeiten von Einkaufslisten auf Smartphone unterdr�cken */

@media (max-width: 576px) {
    .shopping-list-dashboard .shopping-list-forms {
        display: none;
    }

    .shopping-list-overlay .add-product-container {
        display: none;
    }

    .shopping-list-overlay .shopping-list-detail-edit-product-amount {
        display: none;
    }

    .shopping-list-overlay .icons-container .delete-icon {
        display: none;
    }

    .shopping-list-overlay .edit-shopping-list-data .edit-data-container {
        display: none;
    }

    .shopping-list-overlay .product-comment-container {
        display: none;
    }
}

/* Anlage und Bearbeiten von Einkaufslisten auf Smartphone unterdr�cken ENDE*/

/* FIX Text in Header Bild Tablet*/

@media (max-width: 1025px) and (min-width: 768px) {
    .header-image.header-type-2 .page-title {
        position: absolute !important;
        color: #fff !important;
        top: 95px !important;
    }
}

@media (min-width: 768px) {
    .page-title.homepage h1 {
        color: #FFF !important;
    }
}

/* FIX Text in Header Bild Tablet ENDE*/

/* zur�ck zur Trefferliste Button */

.back-to-results span.winkler-arrow-right {
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    color: #164194;
    padding-left: 3px;
}

/* zur�ck zur Trefferliste Button Ende*/

/* Sichtbarkeit Checkboxen, Textfelder, Radio-Buttons */
.styled-radio {
    border: 2px solid #ababab;
}

input.form-control, select.form-control, .add-to-box input.add-to-cart-qty, input.iwc-order-note-input {
    border-bottom: 2px solid #ababab;
}

input.form-control:focus, select.form-control:focus {
    border-bottom: 2px solid #ababab;
}

.iwc-register-invalid-input:invalid {
    border: inherit !important;
    -webkit-box-shadow: inherit !important;
    -moz-box-shadow: inherit !important;
    box-shadow: inherit !important;
    border-bottom: 2px solid #bb2d21 !important;
}

.styled-checkbox {
    border: 2px solid #ababab;
}

.styled-checkbox.icon.winkler-checkbox-checked {
    padding: 4px 0 0 4px;
}

.custom-radio-button input:checked + span::before {
    margin: 10px 0 0 10px;
}

/* Sichtbarkeit Checkboxen, Textfelder, Radio-Buttons ENDE */
