html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

html {
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: scrollbar;
}

body {
    line-height: 25px;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    color: #bb2d21;
    text-decoration: none;
}

a:focus {
    outline: none;
}

a.underline {
    text-decoration: underline;
}

h1 {
    font-size: 34px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    margin-bottom: 30px;
    text-align: center;
    line-height: 34px;
}

h2 {
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    margin-bottom: 30px;
    text-align: left;
    line-height: 30px;
}

h3 {
    font-size: 15px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    margin-bottom: 30px;
    text-align: center;
    line-height: 30px;
}

input, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

input.form-control, select.form-control {
    background-color: #eaeaea;
    border: 1px solid #fff;
    border-radius: 0;
    padding: 5px 10px;
    outline: 0 none;
    box-shadow: none;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
    font-size: 15px;
    height: 40px;
}

input.form-control:focus, select.form-control:focus {
    border: 1px solid #eaeaea;
    box-shadow: none;
}

input:focus, select:focus, textarea:focus, button:focus {
    outline: none;
    box-shadow: none;
}

select option {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.select-blue {
    background-color: #164194;
    color: #fff;
}

.select-wrapper-for-arrow select {
    padding-right: 50px;
}

textarea, textarea.form-control {
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 15px;
    box-shadow: none;
    border: none;
}

.main > header {
    z-index: 1005;
}

.sticky-nav {
    position: fixed;
}

.sticky-nav #header-menu-container {
    top: 0;
}

.winkler-artikelmerkmale.seitnavi {
    font-size: 27px !important;
    padding-left: 5px !important;
}

.select-wrapper-for-arrow {
    position: relative;
}

.select-wrapper-for-arrow:after {
    content: "a";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 2;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    font-size: 24px;
    width: 25px;
    position: absolute;
    pointer-events: none;
    top: 8px;
    right: 20px;
}

.select-wrapper-for-arrow.selected::after {
    content: "e";
}

.select-wrapper-for-arrow select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-wrapper-for-arrow.select-blue:after {
    color: #fff;
}

.icon {
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
}

.winklerfont {
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 2;
    text-transform: none !important;
}

.shippingfont {
    font-family: "shipping-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 2;
    text-transform: none !important;
}

.btn, a.btn {
    background-color: #164194;
    border: medium none;
    color: #fff;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 18px;
    padding: 7px 60px;
    border-radius: 0;
}

.btn:active, a.btn:active, .btn:focus, a.btn:focus, .btn:hover, a.btn:hover {
    color: #fff;
    background: #133880;
    outline: 0;
}

.btn.btn-red, a.btn.btn-red {
    background-color: #bb2d21;
}

.btn.btn-red:focus, a.btn.btn-red:focus, .btn.btn-red:hover, a.btn.btn-red:hover {
    color: #fff;
    background: #a40e11;
}

.btn.btn-gray, a.btn.btn-gray {
    background-color: #eaeaea;
    color: #000;
}

.btn.btn-gray:focus, a.btn.btn-gray:focus, .btn.btn-gray:hover, a.btn.btn-gray:hover {
    color: #000;
    background: #c0c0c0;
}

.btn.btn-blue, a.btn.btn-blue {
    background-color: #164194;
}

.btn.btn-blue:focus, a.btn.btn-blue:focus, .btn.btn-blue:hover, a.btn.btn-blue:hover {
    color: #fff;
    background: #133880;
}

.btn.btn-add, a.btn.btn-add {
    border: none;
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 20px;
    padding: 0;
}

.btn.btn-add::before, a.btn.btn-add::before {
    display: block;
    padding-top: 9px;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity = 0);
    opacity: 0;
    outline: none;
    background: #fff;
    cursor: inherit;
    display: block;
}

#btn-top {
    bottom: 50px;
    height: 35px;
    opacity: 0;
    position: fixed;
    right: 0;
    transition: all .5s ease 0s;
    width: 35px;
    z-index: 1;
    padding: 5px 0;
}

#btn-top.white-scroll-btn {
    background-color: #fff;
}

#btn-top.white-scroll-btn .icon {
    color: #164194;
}

#btn-top.blue-scroll-btn {
    background-color: #164194;
}

#btn-top.blue-scroll-btn .icon {
    color: #fff;
}

@media (min-width: 768px) {
    .mobile-default-option {
        display: none;
    }
}

.main {
    position: relative;
}

section {
    margin-bottom: 60px;
}

@media (min-width: 1200px) {
    section, .container {
        width: 1170px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    section, .container {
        width: 100%;
    }
}

section, .container, .container-fluid {
    padding: 0 20px;
}

@media (min-width: 992px) {
    section, .container, .container-fluid {
        padding: 0 10px;
    }
}

@media (min-width: 992px) and (max-width: 1270px) {
    section, .container, .container-fluid {
        width: 90%;
    }
}

.row {
    margin-left: -20px;
    margin-right: -20px;
}

@media (min-width: 992px) {
    .row {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.container-outer {
    width: 100%;
    padding: 0;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 1024px) {
    .col-ml-1, .col-ml-2, .col-ml-3, .col-ml-4, .col-ml-5, .col-ml-6, .col-ml-7, .col-ml-8, .col-ml-9, .col-ml-10, .col-ml-11, .col-ml-12 {
        float: left;
    }

    .col-ml-12 {
        width: 100%;
    }

    .col-ml-11 {
        width: 91.66666667%;
    }

    .col-ml-10 {
        width: 83.33333333%;
    }

    .col-ml-9 {
        width: 75%;
    }

    .col-ml-8 {
        width: 66.66666667%;
    }

    .col-ml-7 {
        width: 58.33333333%;
    }

    .col-ml-6 {
        width: 50%;
    }

    .col-ml-5 {
        width: 41.66666667%;
    }

    .col-ml-4 {
        width: 33.33333333%;
    }

    .col-ml-3 {
        width: 25%;
    }

    .col-ml-2 {
        width: 16.66666667%;
    }

    .col-ml-1 {
        width: 8.33333333%;
    }

    .col-ml-pull-12 {
        right: 100%;
    }

    .col-ml-pull-11 {
        right: 91.66666667%;
    }

    .col-ml-pull-10 {
        right: 83.33333333%;
    }

    .col-ml-pull-9 {
        right: 75%;
    }

    .col-ml-pull-8 {
        right: 66.66666667%;
    }

    .col-ml-pull-7 {
        right: 58.33333333%;
    }

    .col-ml-pull-6 {
        right: 50%;
    }

    .col-ml-pull-5 {
        right: 41.66666667%;
    }

    .col-ml-pull-4 {
        right: 33.33333333%;
    }

    .col-ml-pull-3 {
        right: 25%;
    }

    .col-ml-pull-2 {
        right: 16.66666667%;
    }

    .col-ml-pull-1 {
        right: 8.33333333%;
    }

    .col-ml-pull-0 {
        right: auto;
    }

    .col-ml-push-12 {
        left: 100%;
    }

    .col-ml-push-11 {
        left: 91.66666667%;
    }

    .col-ml-push-10 {
        left: 83.33333333%;
    }

    .col-ml-push-9 {
        left: 75%;
    }

    .col-ml-push-8 {
        left: 66.66666667%;
    }

    .col-ml-push-7 {
        left: 58.33333333%;
    }

    .col-ml-push-6 {
        left: 50%;
    }

    .col-ml-push-5 {
        left: 41.66666667%;
    }

    .col-ml-push-4 {
        left: 33.33333333%;
    }

    .col-ml-push-3 {
        left: 25%;
    }

    .col-ml-push-2 {
        left: 16.66666667%;
    }

    .col-ml-push-1 {
        left: 8.33333333%;
    }

    .col-ml-push-0 {
        left: auto;
    }

    .col-ml-offset-12 {
        margin-left: 100%;
    }

    .col-ml-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-ml-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-ml-offset-9 {
        margin-left: 75%;
    }

    .col-ml-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-ml-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-ml-offset-6 {
        margin-left: 50%;
    }

    .col-ml-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-ml-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-ml-offset-3 {
        margin-left: 25%;
    }

    .col-ml-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-ml-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-ml-offset-0 {
        margin-left: 0;
    }
}

.header-image {
    position: relative;
    min-height: 140px;
    margin-bottom: 30px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0;
}

.header-image img {
    width: 100%;
    height: auto;
}

.header-image .header-image-inner {
    position: relative;
}

.header-image.header-type-2 .header-image-inner, .header-image.header-type-3 .header-image-inner, .header-image.header-type-4 .header-image-inner {
    overflow: hidden;
}

.header-image.header-type-2 img, .header-image.header-type-3 img, .header-image.header-type-4 img {
    position: relative;
}

@media (min-width: 1024px) {
    .header-image.header-type-2 img, .header-image.header-type-3 img, .header-image.header-type-4 img {
        bottom: 25px;
    }
}

@media (min-width: 1240px) {
    .header-image.header-type-2 img, .header-image.header-type-3 img, .header-image.header-type-4 img {
        bottom: 50px;
    }
}

@media (min-width: 1400px) {
    .header-image.header-type-2 img, .header-image.header-type-3 img, .header-image.header-type-4 img {
        bottom: 75px;
    }
}

@media (min-width: 1600px) {
    .header-image.header-type-2 img, .header-image.header-type-3 img, .header-image.header-type-4 img {
        bottom: 100px;
    }
}

@media (min-width: 768px) {
    .header-image.header-type-2 .header-image-inner {
        height: 350px;
    }
}

@media (min-width: 992px) {
    .header-image.header-type-2 .header-image-inner {
        height: 440px;
    }
}

@media (min-width: 1600px) {
    .header-image.header-type-2 .header-image-inner {
        height: 500px;
    }
}

.header-image.header-type-2 .page-title {
    top: 40px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .header-image.header-type-2 .page-title {
        color: #000;
        position: static;
    }
}

.header-image.header-type-2 .page-title p {
    max-width: 700px;
}

@media (min-width: 1024px) {
    .header-image.header-type-2 img {
        bottom: 30px;
    }
}

@media (min-width: 1240px) {
    .header-image.header-type-2 img {
        bottom: 75px;
    }
}

@media (min-width: 1400px) {
    .header-image.header-type-2 img {
        bottom: 130px;
    }
}

@media (min-width: 1600px) {
    .header-image.header-type-2 img {
        bottom: 170px;
    }
}

@media (min-width: 1800px) {
    .header-image.header-type-2 img {
        bottom: 220px;
    }
}

@media (min-width: 768px) {
    .header-image.header-type-3.search-header .header-image-inner {
        height: 330px;
    }
}

@media (min-width: 992px) {
    .header-image.header-type-3.search-header .header-image-inner {
        height: 370px;
    }
}

@media (min-width: 768px) {
    .header-image.header-type-3 .header-image-inner {
        height: 350px;
    }
}

@media (min-width: 992px) {
    .header-image.header-type-3 .header-image-inner {
        height: 420px;
    }
}

@media (min-width: 1600px) {
    .header-image.header-type-3 .header-image-inner {
        height: 440px;
    }
}

@media (min-width: 1800px) {
    .header-image.header-type-3 img {
        bottom: 140px;
    }
}

@media (min-width: 768px) {
    .header-image.header-type-4 .header-image-inner {
        height: 350px;
    }
}

@media (min-width: 992px) {
    .header-image.header-type-4 .header-image-inner {
        height: 450px;
    }
}

@media (min-width: 1280px) {
    .header-image.header-type-4 .header-image-inner {
        height: 530px;
    }
}

@media (min-width: 1600px) {
    .header-image.header-type-4 .header-image-inner {
        height: 600px;
    }
}

.header-image .search-wrapper {
    width: 100%;
    max-width: 696px;
    margin: 0 auto;
    padding: 0;
    position: absolute;
    bottom: 20px;
    left: 0;
}

@media (min-width: 696px) and (max-width: 767px) {
    .header-image .search-wrapper {
        left: 50%;
        margin-left: -348px;
    }
}

@media (max-width: 767px) {
    .header-image .search-wrapper {
        padding: 0 20px;
    }
}

@media (min-width: 768px) {
    .header-image .search-wrapper {
        position: relative;
        padding: 0;
    }
}

.header-image.search-header .search-wrapper {
    bottom: 160px;
}

@media (min-width: 768px) {
    .header-image.search-header .search-wrapper {
        bottom: 0;
    }
}

.header-image.search-header .header-search-result {
    background-color: #eaeaea;
    text-shadow: none;
    clear: both;
    padding: 20px 0;
    border-bottom: 1px solid #c0c0c0;
}

@media (min-width: 768px) {
    .header-image.search-header .header-search-result {
        border-bottom-color: transparent;
        background-color: transparent;
        text-shadow: 0 0 5px #000;
        padding: 10px 0 0 0;
    }
}

@media (min-width: 992px) {
    .header-image.search-header .header-search-result {
        padding: 30px 0 0 0;
    }
}

.fluid-image {
    width: 100%;
    height: auto;
}

.fluid-table > .row {
    margin: 0;
    padding: 15px 0;
}

.fluid-table > .row > div {
    padding: 0;
}

@media (min-width: 768px) {
    .fluid-table {
        display: table;
        width: 100%;
    }

    .fluid-table > .row {
        display: table-row;
    }

    .fluid-table > .row > div {
        float: none;
        display: table-cell;
        vertical-align: top;
        padding: 15px 0;
    }

    .fluid-table.align-bottom .row > div {
        vertical-align: bottom;
    }

    .fluid-table.solid-divider > .row > div {
        border-bottom: 1px solid #c0c0c0;
    }
}

.row.additional-padding {
    padding-left: 15px;
    padding-right: 15px;
}

strong {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.styled-checkbox-main {
    display: none;
}

.styled-checkbox {
    background-color: #eaeaea;
    border: none;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin-bottom: 7px;
    margin-right: 10px;
    margin-top: 3px;
    vertical-align: middle;
    width: 40px;
}

.styled-checkbox.icon.winkler-checkbox-checked {
    font-size: 30px;
    color: #000;
    padding: 6px 0 0 6px;
}

.styled-radio-main {
    display: none;
}

.styled-radio {
    background-clip: padding-box;
    background-color: #eaeaea;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    display: inline-block;
    height: 40px;
    position: relative;
    top: 0;
    vertical-align: middle;
    width: 40px;
}

.custom-radio-button input:checked + span::before {
    background-clip: padding-box;
    background-color: #000;
    border-radius: 8px;
    content: " ";
    display: block;
    height: 16px;
    margin: 11px 0 0 11px;
    width: 16px;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

section.no-margin-bottom {
    vertical-align: bottom;
}

.no-margin-top {
    margin-top: 0 !important;
}

.circle {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 10px;
    background: #fff;
    border-radius: 20px;
    position: relative;
}

.form-group {
    margin-bottom: 10px;
}

.btn-right {
    margin-top: 30px;
    text-align: right;
}

.form-group label {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.clear-left {
    clear: left;
}

.gray-tabs .nav-tabs {
    text-align: center;
    margin-bottom: 30px;
}

.gray-tabs .form-group {
    margin-bottom: 30px;
}

.gray-tabs .nav-tabs > li {
    display: inline-block;
    position: relative;
    text-align: center;
    margin-bottom: 0;
    float: none;
}

.gray-tabs .nav-tabs > li.active {
    border-radius: 0;
    background: #eaeaea;
    border: none;
}

.gray-tabs .nav-tabs > li.active > a, .gray-tabs .nav-tabs > li.active > a:focus, .gray-tabs .nav-tabs > li.active > a:hover {
    border-radius: 0;
    background-color: #eaeaea;
    border: none;
    color: #000;
    cursor: pointer;
}

.gray-tabs .nav-tabs > li.active .circle:before {
    background: #000;
    border-radius: 10px;
    content: "";
    display: block;
    height: 20px;
    left: 50%;
    margin-left: -10px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 20px;
}

.gray-tabs .nav-tabs > li a {
    border-radius: 0;
    border: none;
    margin-right: 0;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.gray-tabs .nav-tabs > li a:hover {
    background: #eaeaea;
}

@media (min-width: 992px) {
    .gray-tabs .nav-tabs > li a {
        padding: 10px 15px 10px 0;
    }
}

.gray-tabs .nav-tabs > li .circle {
    display: block;
    vertical-align: middle;
    margin: 0 auto;
}

@media (min-width: 992px) {
    .gray-tabs .nav-tabs > li .circle {
        margin-right: 10px;
        display: inline-block;
    }
}

.gray-tabs .nav-tabs > li:hover {
    background: #eaeaea;
}

.gray-tabs .nav-tabs > li .tab-label {
    position: relative;
    vertical-align: middle;
}

.gray-tabs .nav-tabs {
    border-bottom: none;
    background-color: #eaeaea;
}

.overlay {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    position: absolute;
    width: 100%;
    min-height: 100%;
    padding-bottom: 100px;
    z-index: 2700;
    display: none;
}

.overlay .close-overlay {
    position: absolute;
    z-index: 5000;
    top: 28px;
    right: 22px;
    color: #000;
    cursor: pointer;
    cursor: hand;
}

@media (min-width: 768px) {
    .overlay .close-overlay {
        top: 53px;
        right: 7%;
        color: #fff;
    }
}

.overlay .container-fluid {
    padding: 0 20px;
}

.overlay .container-fluid {
    width: 100%;
}

@media (min-width: 768px) {
    .overlay-outer {
        margin: 0 20px;
    }
}

@media (min-width: 992px) {
    .overlay-outer {
        margin: 0 40px;
    }
}

.overlay-inner {
    background: #fff;
    width: 100%;
    padding: 88px 0 55px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .overlay-inner {
        margin-top: 180px;
        padding-top: 30px;
    }
}

@media (max-width: 767px) {
    .overlay-header.sticky {
        position: fixed;
        z-index: 10;
        background-color: #fff;
    }
}

.overlay-title {
    padding: 0 20px;
}

.close-overlay-mobile {
    margin-top: 30px;
    text-align: center;
    cursor: hand;
    cursor: pointer;
}

.close-overlay-mobile:after {
    content: "\6a";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
    width: 28px;
    padding-left: 6px;
    color: #164194;
}

.close-overlay-mobile.collapsed:after {
    content: "\6f";
}

@media (min-width: 768px) {
    .close-overlay-mobile {
        display: none;
    }
}

.toggle-information {
    padding: 0 20px;
}

@media (min-width: 768px) {
    .toggle-information {
        display: none;
    }
}

.toggle-information .open-information-message {
    display: none;
}

.toggle-information:after {
    content: "\6b";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
    padding-left: 0;
    font-size: 30px;
    width: 32px;
    padding-top: 0;
    margin-top: -2px;
    color: #164194;
}

.toggle-information.collapsed:after {
    content: "\6f";
    width: 28px;
    font-size: 20px;
    padding-top: 4px;
    padding-left: 6px;
}

.toggle-information.collapsed .open-information-message {
    display: inline;
}

.toggle-information.collapsed .close-information-message {
    display: none;
}

.overlay-top-information {
    display: none;
    background-color: #eaeaea;
}

.overlay-top-information.displayed {
    display: block;
}

@media (min-width: 768px) {
    .overlay-top-information {
        display: block !important;
    }
}

.overlay-top-information dt {
    margin-bottom: 3px;
}

.overlay-top-information dd {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    margin-bottom: 18px;
    line-height: 1.26;
}

.overlay-top-information dd.delivery-address {
    line-height: 1.26;
    margin-bottom: 22px;
}

.overlay-top-information .new-information {
    display: none;
}

.overlay-top-information.edit-mode .current-information {
    display: none;
}

.overlay-top-information.edit-mode .new-information {
    display: block;
}

.order-overlay .overlay-inner {
    max-width: 910px;
}

.order-overlay .order-information-container {
    background-color: #eaeaea;
    padding: 15px 0;
}

.order-overlay .order-information-container dd {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    margin-bottom: 15px;
}

.order-overlay .order-information-container dd.delivery-address {
    line-height: 1.26;
    margin-bottom: 20px;
}

.order-overlay .show-order-information {
    margin-bottom: 5px;
}

.order-overlay .show-order-information:hover {
    color: #bb2d21;
}

.order-overlay .my-orders-table {
    margin-top: 20px;
}

.order-overlay .delivery-label {
    line-height: 1.26;
}

.order-overlay .reorder-button {
    margin-top: 50px;
}

@media (max-width: 767px) {
    .order-overlay .reorder-button {
        margin-top: 20px;
    }
}

.shopping-list-overlay .overlay-inner, .add-to-shopping-list-overlay .overlay-inner {
    max-width: 910px;
}

.shopping-list-overlay .overlay-title, .add-to-shopping-list-overlay .overlay-title {
    margin-bottom: 30px;
}

.shopping-list-overlay .shopping-list-information-container, .add-to-shopping-list-overlay .shopping-list-information-container {
    background-color: #eaeaea;
    padding: 15px 0;
}

.shopping-list-overlay .shopping-list-information textarea, .add-to-shopping-list-overlay .shopping-list-information textarea {
    background: #fff;
    border-color: #fff;
    color: #000;
    border-radius: 0;
    box-shadow: none;
}

.shopping-list-overlay .product-order-number, .add-to-shopping-list-overlay .product-order-number, .shopping-list-overlay .product-material-number, .add-to-shopping-list-overlay .product-material-number {
    color: #747474;
}

.shopping-list-overlay .product-material-number, .add-to-shopping-list-overlay .product-material-number {
    margin-top: -4px;
}

.shopping-list-overlay .product-name, .add-to-shopping-list-overlay .product-name {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    margin-bottom: 3px;
}

.shopping-list-overlay .product-comment-container, .add-to-shopping-list-overlay .product-comment-container {
    max-width: 340px;
    margin: 15px 0;
}

.shopping-list-overlay .product-comment, .add-to-shopping-list-overlay .product-comment {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shopping-list-overlay .comment-link, .add-to-shopping-list-overlay .comment-link {
    display: block;
}

.shopping-list-overlay .edit-comment, .add-to-shopping-list-overlay .edit-comment {
    margin-top: 5px;
}

.add-to-shopping-list-overlay .overlay-inner {
    max-width: 910px;
}

.add-to-shopping-list-overlay .shopping-list-information-container {
    display: none;
    margin-top: 30px;
}

.add-to-shopping-list-overlay .shopping-list-information-container.edit-mode {
    display: block;
}

.add-to-shopping-list-overlay .shopping-list-information-container.edit-mode .edit-shopping-list-data .save-data-container {
    display: block;
}

.add-to-shopping-list-overlay .shopping-list-information {
    display: none;
}

.add-to-shopping-list-overlay .shopping-list-information.displayed {
    display: block;
}

.add-to-shopping-list-overlay .shopping-list-information .comment-textarea {
    min-height: 120px;
}

.add-to-shopping-list-overlay .shopping-list-question {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .add-to-shopping-list-overlay .shopping-list-question {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .add-to-shopping-list-overlay .overlay-title {
        display: none;
    }
}

.add-to-shopping-list-overlay .toggle-information {
    text-align: left;
}

.add-to-shopping-list-overlay .new-shopping-list {
    display: block;
    padding: 30px 0;
    float: left;
    cursor: hand;
    cursor: pointer;
}

.add-to-shopping-list-overlay .new-shopping-list:after {
    content: "\6f";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
    padding-left: 6px;
    font-size: 20px;
    width: 26px;
    height: 20px;
    padding-top: 0;
    color: #164194;
}

.add-to-shopping-list-overlay .confirm-add-to-shopping-list {
    float: right;
    margin-top: 30px;
}

@media (max-width: 767px) {
    .add-to-shopping-list-overlay .confirm-add-to-shopping-list {
        display: none;
    }
}

.add-to-shopping-list-overlay .shopping-list-table {
    margin: 0;
}

@media (min-width: 768px) {
    .product-comparison-overlay .overlay-inner-container {
        margin-top: 180px;
    }
}

.product-comparison-overlay .overlay-inner {
    max-width: 1060px;
    margin: 0 auto;
    padding: 30px 10px;
}

@media (min-width: 768px) {
    .product-comparison-overlay .overlay-inner {
        padding: 30px 40px;
    }
}

.product-comparison-overlay .overlay-inner-top {
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .product-comparison-overlay .overlay-inner-top {
        padding-top: 88px;
    }
}

.product-comparison-overlay .overlay-inner-bottom {
    padding-top: 0;
}

.product-comparison-overlay .overlay-top {
    border-bottom: 1px solid #eaeaea;
}

.product-comparison-overlay .overlay-title {
    margin-bottom: 5px;
}

.product-comparison-overlay .remove-products {
    margin: 10px 0 18px;
    text-align: center;
}

@media (min-width: 768px) {
    .product-comparison-overlay .remove-products {
        text-align: right;
    }
}

.product-comparison-overlay .remove-products .winkler-close {
    margin-left: 10px;
    color: #164194;
}

.product-comparison-overlay .custom-scrollbar-container {
    padding: 20px 0 10px;
}

@media (min-width: 768px) {
    .product-comparison-overlay .custom-scrollbar-container {
        padding: 40px 0 20px;
    }
}

.product-comparison-overlay .custom-scrollbar-container .custom-scrollbar {
    margin-left: 130px;
    width: auto;
}

@media (min-width: 768px) {
    .product-comparison-overlay .custom-scrollbar-container .custom-scrollbar {
        margin-left: 190px;
    }
}

.product-comparison-overlay.collapsed .overlay-top, .product-comparison-overlay.collapsed .product-image, .product-comparison-overlay.collapsed .price-box, .product-comparison-overlay.collapsed .product-descriptions {
    display: none;
}

.product-comparison-overlay.collapsed .custom-scrollbar-container {
    padding: 10px 0 42px 0;
}

@media (max-width: 767px) {
    .product-comparison-overlay.collapsed .custom-scrollbar-container {
        padding: 42px 0 10px 0;
    }
}

.product-comparison-overlay.collapsed .overlay-inner-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    z-index: 2000;
}

@media (min-width: 1140px) {
    .product-comparison-overlay.collapsed .overlay-inner-container {
        margin-left: -530px;
        left: 50%;
        right: auto;
    }
}

.product-comparison-overlay.collapsed .overlay-inner {
    margin: 0;
}

.product-comparison-overlay.collapsed .product-content {
    padding-bottom: 143px;
}

.product-comparison-overlay.collapsed .product-content .checkbox-container {
    bottom: 70px;
}

.product-comparison-overlay.collapsed .product-content .icons-container {
    top: auto;
    right: auto;
    bottom: 15px;
    left: 0;
}

.product-comparison-overlay.collapsed .product-content .icons-container .add-to-icons {
    margin-right: 1px;
}

@media (max-width: 1025px) {
    .product-comparison-overlay.collapsed .product-content .icons-container {
        display: block;
    }
}

.product-comparison-overlay.collapsed .product-content .icons-container .add-to-icons {
    float: left;
}

.faq-overlay {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: none;
}

.faq-overlay .faq-overlay-inner {
    background: #fff;
    width: 100%;
    max-width: 730px;
    padding: 60px;
    margin: 180px auto 0;
}

.faq-overlay .faq-overlay-inner .winkler-close {
    position: absolute;
    right: 7%;
    top: 60px;
    color: #fff;
    cursor: pointer;
}

.faq-topic {
    display: block;
    padding-right: 20px;
}

.language-overlay {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: none;
}

.language-overlay .language-overlay-inner {
    background: #fff;
    width: 100%;
    max-width: 730px;
    padding: 30px 20px 55px 20px;
    margin: 180px auto 0;
}

.language-overlay .language-overlay-inner .languages-container {
    text-align: center;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 20px;
}

.language-overlay .language-overlay-inner .languages-container img {
    margin-right: 5px;
}

.language-overlay .language-overlay-inner .languages-container .language .available-language {
    display: none;
    background: #164194;
    left: 50%;
    margin-left: -70px;
    padding: 30px 30px 10px;
    position: absolute;
    text-align: left;
    z-index: 1;
    top: 35px;
}

.language-overlay .language-overlay-inner .languages-container .language .available-language li {
    margin-bottom: 10px;
}

.language-overlay .language-overlay-inner .languages-container .language .available-language a {
    color: #fff;
}

.language-overlay .language-overlay-inner .languages-container .language .available-language a:hover {
    background: #fff;
    color: #164194;
}

.language-overlay .language-overlay-inner .languages-container .language .available-language:after {
    border-style: solid;
    border-width: 0 13px 14px 13px;
    border-color: transparent transparent #164194 transparent;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    top: -14px;
    width: 0;
}

.language-overlay .language-overlay-inner .languages-container .language:hover .available-language {
    display: block;
}

.language-overlay .language-overlay-inner .onlineshop {
    margin-top: 35px;
    margin-bottom: 40px;
}

.language-overlay .language-overlay-inner .onlineshop .row {
    width: 85%;
    margin: 0 auto;
}

.language-overlay .language-overlay-inner .service {
    padding-top: 40px;
    border-top: 1px solid #eaeaea;
}

.language-overlay .language-overlay-inner h4 {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 20px;
    margin-bottom: 20px;
}

.language-overlay .winkler-close {
    position: absolute;
    right: 7%;
    top: 60px;
    color: #fff;
    cursor: pointer;
}

.search-overlay {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    display: none;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1001;
}

.required-products-overlay {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    display: none;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1003;
}

.required-products-overlay .winkler-close {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 7%;
    top: 60px;
}

.required-products-overlay .required-products {
    background: #fff;
    width: 100%;
    max-width: 910px;
    padding: 30px 20px;
    margin: 180px auto 0 auto;
    position: relative;
}

.required-products-overlay .required-products h2 {
    margin-bottom: 0;
}

.required-products-overlay .required-products p {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .required-products-overlay .required-products .product-price {
        float: left;
    }
}

.required-products-overlay .required-products .product-price .tax-details {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.required-products-overlay .required-products .product-price .regular-price {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    color: #bb2d21;
}

@media (max-width: 767px) {
    .required-products-overlay .required-products .product-price .regular-price {
        margin-bottom: 30px;
    }
}

.required-products-overlay .required-products .add-to-box {
    margin-top: -4px;
}

.required-products-overlay .required-products .tab-content-product-wrapper, .required-products-overlay .required-products .item {
    padding-top: 30px;
    padding-bottom: 5px;
    border-top: 1px solid #747474;
    margin-left: 0;
    margin-right: 0;
    clear: both;
    position: relative;
}

@media (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper, .required-products-overlay .required-products .item {
        padding-top: 40px;
    }
}

.required-products-overlay .required-products .tab-content-product-wrapper .col-sm-9, .required-products-overlay .required-products .item .col-sm-9 {
    margin-bottom: 25px;
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
    display: block;
    position: absolute;
    right: 0;
    bottom: 30px;
}

@media (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        top: 40px;
        bottom: auto;
    }
}

@media (min-width: 1025px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        display: none;
    }
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons, .required-products-overlay .required-products .item .icons-container .add-to-icons {
    float: left;
    margin-right: 1px;
}

@media (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons, .required-products-overlay .required-products .item .icons-container .add-to-icons {
        float: none;
        margin-right: 0;
        margin-bottom: 1px;
    }
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons a, .required-products-overlay .required-products .item .icons-container .add-to-icons a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons .icon, .required-products-overlay .required-products .item .icons-container .add-to-icons .icon {
    font-size: 21px;
    color: #fff;
    padding-top: 0;
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.add-to-cart-icon {
    background: #bb2d21;
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.compare-icon, .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.shopping-list-icon {
    background: #164194;
}

.required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.compare-icon .icon {
    font-size: 27px;
    margin-top: -3px;
    padding-left: 0;
    padding-top: 0;
    width: 41px;
}

.required-products-overlay .required-products .tab-content-product-wrapper:hover .icons-container, .required-products-overlay .required-products .item:hover .icons-container {
    display: block;
}

.required-products-overlay .required-products .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    margin-right: 4px;
    margin-top: 3px;
}

@media (max-width: 1025px) {
    .required-products-overlay .required-products .item-price-box .nav-tabs {
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .required-products-overlay .required-products .item-price-box .nav-tabs {
        float: left;
    }
}

.required-products-overlay .required-products .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li a:hover {
    background: none;
}

.required-products-overlay .required-products .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

.required-products-overlay .required-products .item-price-box .tab-content {
    margin-top: 0;
    padding-top: 0;
}

@media (min-width: 992px) {
    .required-products-overlay .required-products .item-price-box .tab-content {
        float: left;
        margin-bottom: 20px;
    }
}

.required-products-overlay .required-products .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

.required-products-overlay .required-products .item-price-box .regular-price .price {
    font-size: 24px;
}

.required-products-overlay .required-products .product-image img {
    width: 100%;
    height: auto;
}

.required-products-overlay .required-products .products-list {
    margin-bottom: 65px;
}

.required-products-overlay .required-products .products-list .item {
    padding-bottom: 25px;
}

.required-products-overlay .required-products .products-list .item a:hover h2, .required-products-overlay .required-products .products-list .item a:hover p .tax-details {
    color: #000;
}

.required-products-overlay .required-products .products-list .item:first-child {
    border-top: none;
}

.required-products-overlay .required-products .products-list img {
    width: 100%;
}

.required-products-overlay .required-products .action-buttons {
    margin-bottom: 40px;
}

.required-products-overlay .required-products .action-buttons.bottom {
    margin-bottom: 0;
}

.required-products-overlay .required-products .action-buttons .btn {
    margin-left: 10px;
    padding: 7px 0;
    width: 220px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .required-products-overlay .required-products .action-buttons .col-md-9 {
        width: 668px;
        float: right;
    }
}

.required-products-overlay .required-products .action-buttons .buttons {
    float: right;
    margin-left: -15px;
    padding-left: 10px;
    padding-right: 10px;
}

.required-products-overlay .required-products .action-buttons .buttons .button:first-child {
    margin-left: 0;
}

.required-products-overlay .required-products .add-to .styled-checkbox {
    margin-right: 0;
    margin-top: -4px;
}

.required-products-overlay .required-products .add-to .winkler-checkbox-checked::before {
    left: -4px;
    position: relative;
}

.required-products-overlay .required-products h2 {
    margin-bottom: 3px;
}

.required-products-overlay .required-products .order-material-number {
    line-height: 22px;
}

.custom-scrollbar {
    height: 16px;
    opacity: .75;
    width: 100%;
    outline: 0 none;
    overflow: hidden;
    position: relative;
    -webkit-transition: opacity .2s ease-in-out 0s,background-color .2s ease-in-out 0s;
    -moz-transition: opacity .2s ease-in-out 0s,background-color .2s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s,background-color .2s ease-in-out 0s;
}

.custom-scrollbar .dragger-container {
    bottom: 0;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 20px;
}

.custom-scrollbar .dragger-container .dragger {
    display: block;
    max-width: 1050px;
    min-width: 30px;
    position: absolute;
    width: 60%;
    height: 100%;
    cursor: pointer;
    left: 0;
    z-index: 1;
}

.custom-scrollbar .dragger-container .dragger .dragger-bar {
    background-color: #8f8f8f;
    height: 6px;
    margin: 5px 0;
    width: 100%;
    border-radius: 16px;
    position: relative;
    text-align: center;
    -webkit-transition: opacity .2s ease-in-out 0s,background-color .2s ease-in-out 0s;
    -moz-transition: opacity .2s ease-in-out 0s,background-color .2s ease-in-out 0s;
    transition: opacity .2s ease-in-out 0s,background-color .2s ease-in-out 0s;
}

.custom-scrollbar .dragger-container .dragger .dragger-bar:hover {
    background-color: #bb2d21;
}

.custom-scrollbar .dragger-container .scroll-rail {
    background-color: rgba(0, 0, 0, 0.1);
    height: 6px;
    margin: 5px 0;
    width: 100%;
    border-radius: 16px;
}

.custom-scrollbar .scroll-left, .custom-scrollbar .scroll-right {
    cursor: pointer;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    width: 20px;
    background-image: url("/scripts/mCustomScrollbar/mCSB_buttons.png");
    background-repeat: no-repeat;
    opacity: .4;
}

.custom-scrollbar .scroll-left {
    background-position: -120px -112px;
}

.custom-scrollbar .scroll-right {
    right: 0;
    background-position: -120px -128px;
}

.custom-scrollbar:hover {
    opacity: 1;
}

header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

header #winkler-logo-container, header .winkler-logo-container {
    position: relative;
    z-index: 3000;
    width: 335px;
}

header #winkler-logo-container .logo-rectangle-background, header .winkler-logo-container .logo-rectangle-background {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    height: 135px;
    width: 270px;
    position: relative;
    background-color: #fff;
}

header #winkler-logo-container .logo-rectangle-background .logo, header .winkler-logo-container .logo-rectangle-background .logo {
    left: 134px;
    position: absolute;
    top: 29px;
}

header #winkler-logo-container .logo-rectangle-background .logo .header-logo-image, header .winkler-logo-container .logo-rectangle-background .logo .header-logo-image {
    background: transparent url("/images/logo/winkler-logo.svg") no-repeat scroll 0 0;
    display: block;
    font-size: 0;
    height: 77px;
    width: 120px;
}

header #winkler-logo-container .logo-triangle-background, header .winkler-logo-container .logo-triangle-background {
    border: medium none;
    height: 145px;
    left: 270px;
    overflow: hidden;
    top: 0;
    width: 70px;
    position: absolute;
    background-clip: padding-box;
}

header #winkler-logo-container .logo-triangle-background:after, header .winkler-logo-container .logo-triangle-background:after {
    background: #fff;
    box-shadow: 33px 0 10px 0 rgba(0, 0, 0, 0.1);
    content: "";
    height: 200px;
    left: -163px;
    position: absolute;
    top: -70px;
    transform: rotate(-66deg);
    width: 200px;
}

header #header-menu-container {
    left: 348px;
    position: absolute;
    top: 35px;
    z-index: 2500;
    width: auto;
    right: 0;
    height: 60px;
    box-shadow: 42px 0 10px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
}

header #header-menu-container .rectangle {
    background-color: #fff;
    bottom: 0;
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 1160px;
    z-index: 1;
}

header #header-menu-container .rectangle nav {
    margin-top: 21px;
}

header #header-menu-container .rectangle nav li {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 20px;
}

header #header-menu-container .rectangle nav li a {
    padding: 0 10px;
}

header #header-menu-container .rectangle nav li a:hover {
    background: none;
    color: #164194;
}

header #header-menu-container .rectangle #navbar, header #header-menu-container .rectangle #tyre-partnershop {
    float: left;
}

header #header-menu-container .rectangle #tyre-partnershop {
    padding-left: 0;
    padding-right: 0;
    position: relative;
}

header #header-menu-container .rectangle #tyre-partnershop .partnershop-link {
    position: relative;
    top: -7px;
}

header #header-menu-container .rectangle #tyre-partnershop .reifen {
    height: 29px;
    margin-right: 5px;
    margin-top: -5px;
    padding-left: 1px;
    width: 29px;
    background: url("/images/Reifen_blau.svg") no-repeat;
    display: inline-block;
}

header #header-menu-container .rectangle #tyre-partnershop .tyre-partnershop-link-wrapper {
    display: none;
    position: absolute;
    width: 460px;
    background: #fff;
    top: 60px;
    left: 50%;
    margin-left: -230px;
    padding: 30px 15px;
}

header #header-menu-container .rectangle #tyre-partnershop .tyre-partnershop-link-wrapper:after {
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 25px 20px;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -25px;
    position: absolute;
    top: -20px;
    width: 0;
}

header #header-menu-container .rectangle #tyre-partnershop .tyre-partnershop-link-wrapper .text-right {
    margin-top: 20px;
}

header #header-menu-container .rectangle #tyre-partnershop .tyre-partnershop-link-wrapper .icon {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #164194;
}

header #header-menu-container .rectangle #logout-and-cart {
    float: right;
}

header #header-menu-container .rectangle #logout-and-cart .cart {
    position: relative;
}

header #header-menu-container .rectangle #logout-and-cart .shopping-cart {
    color: #bb2d21;
    font-size: 40px;
    height: 47px;
    margin-top: -7px;
    padding-top: 4px;
    position: relative;
    width: 42px;
    background: url("/images/Shopping-cart_red.svg") no-repeat;
    display: block;
}

@media (min-width: 992px) and (max-width: 1023px) {
    header #header-menu-container .rectangle #logout-and-cart .shopping-cart {
        margin-top: -2px;
        width: 28px;
        height: 30px;
    }
}

header #header-menu-container .rectangle #logout-and-cart .items-in-cart-wrapper {
    background: #bb2d21;
    color: #fff;
    border-radius: 50%;
    height: 30px;
    padding: 4px 0;
    position: absolute;
    width: 30px;
    z-index: 1;
    top: -9px;
    right: 2px;
    display: block;
    text-align: center;
}

@media (min-width: 992px) and (max-width: 1023px) {
    header #header-menu-container .rectangle #logout-and-cart .items-in-cart-wrapper {
        width: 24px;
        height: 24px;
        padding: 2px 0;
        right: 0;
    }
}

header #header-menu-container .rectangle #logout-and-cart #items-in-cart {
    font-size: 18px;
}

header #header-menu-container .triangle {
    border: medium none;
    height: 90px;
    left: -40px;
    overflow: hidden;
    top: -20px;
    width: 40px;
    background-clip: padding-box;
    position: absolute;
}

header #header-menu-container .triangle:after {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    content: "";
    height: 60px;
    left: 27px;
    position: absolute;
    top: 20px;
    transform: skew(-23deg, 0deg);
    width: 150px;
}

header #header-menu-container .rectangle-right {
    background: #fff none repeat scroll 0 0;
    height: 60px;
    position: absolute;
    right: 0;
    width: 60%;
    z-index: 0;
}

header .subheader-dropdown {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1300;
}

header .subheader-dropdown .dropdown-outer {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
    opacity: 0;
    padding-bottom: 45px;
    padding-top: 125px;
    position: absolute;
    transition: all .5s ease 0s;
    width: 100%;
}

header .subheader-dropdown .dropdown-outer.account-dropdown {
    top: -435px;
}

header .subheader-dropdown .dropdown-outer.faq-dropdown {
    top: -435px;
}

header .subheader-dropdown .dropdown-outer.quickorder-dropdown {
    top: -435px;
}

@media (min-width: 992px) {
    header .subheader-dropdown .dropdown-outer.active {
        opacity: 1;
        top: 0;
        z-index: 2000;
    }
}

header .subheader-dropdown .dropdown-inner {
    max-width: 1120px;
    margin-left: 264px;
    position: relative;
}

@media (min-width: 1240px) {
    header .subheader-dropdown .dropdown-inner {
        margin-left: 371px;
    }
}

header .subheader-dropdown .dropdown-inner .submenu {
    display: none;
    padding-left: 30px;
}

header .subheader-dropdown .dropdown-inner .submenu.open {
    display: block;
}

header .subheader-dropdown .dropdown-inner a {
    color: #fff;
    display: inline-block;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 20px;
    margin-bottom: 15px;
    padding: 2px;
}

header .subheader-dropdown .dropdown-inner a:hover {
    background: #fff none repeat scroll 0 0;
    color: #164194;
}

header .subheader-dropdown .dropdown-inner .active a {
    background: #fff none repeat scroll 0 0;
    color: #164194;
}

header .subheader-dropdown .dropdown-inner .winkler-close {
    color: #fff;
    position: absolute;
    right: 152px;
    cursor: pointer;
}

header .subheader-dropdown .tecrmi .winkler-link-extern {
    display: inline-block;
    margin-left: 8px;
    height: 20px;
    font-size: 16px;
}

header .cart-details {
    display: none;
}

.page-title {
    color: #000;
    position: static;
    text-align: center;
    top: 50px;
    width: 100%;
    margin: 40px auto 0;
    z-index: 10;
}

.page-title h1 {
    color: #fff;
    line-height: 34px;
    text-align: right;
}

@media (max-width: 767px) {
    .page-title h1 {
        position: absolute;
        top: 105px;
        right: 20px;
        width: 60%;
    }
}

@media (min-width: 768px) {
    .page-title h1 {
        text-align: center;
        width: auto;
    }
}

.page-title.active {
    position: absolute;
    text-align: center;
    color: #fff;
    width: 100%;
    top: 65px;
    z-index: 1002;
}

@media (min-width: 768px) {
    .page-title {
        position: absolute;
        color: #fff;
        top: 95px;
    }

    .page-title h1 {
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        margin-bottom: 30px;
    }

    .page-title p {
        max-width: 710px;
        margin: 0 auto;
    }
}

.page-title.homepage {
    top: -10px;
}

@media (min-width: 1050px) {
    .page-title.homepage {
        top: -20px;
    }
}

@media (max-width: 767px) {
    .page-title.homepage h1 {
        color: #fff;
        text-align: right;
    }
}

@media (min-width: 768px) {
    .page-title.homepage h1 {
        color: #000;
    }
}

@media (min-width: 1025px) {
    .page-title.homepage h1 {
        color: #fff;
    }
}

@media (min-width: 768px) {
    .page-title.homepage {
        position: static;
        color: #000;
    }
}

@media (min-width: 1025px) {
    .page-title.homepage {
        position: absolute;
        color: #fff;
        text-shadow: 1px 1px 3px #000;
    }
}

#service-nav {
    display: block;
    overflow: visible;
    position: fixed;
    right: 0;
    top: 65px;
    width: 40px;
    z-index: 2000;
}

#service-nav.loggedin {
    top: 135px;
}

#service-nav li {
    cursor: pointer;
    height: 40px;
    left: 0;
    margin-bottom: 1px;
    position: relative;
    transition: width .5s ease 0s,left .5s ease 0s;
}

#service-nav li a {
    background-color: #164194;
    color: #fff;
    font-size: 18px;
    height: 40px;
    overflow: hidden;
    text-decoration: none;
    width: 150px;
    position: absolute;
}

#service-nav li a .service-menu-container {
    display: inline-block;
}

#service-nav li a .service-menu-container .icon {
    color: #fff;
    display: inline;
    font-size: 21px;
    line-height: 47px;
    margin-right: 10px;
    padding-left: 11px;
    vertical-align: middle;
}

#service-nav li a .service-menu-container .icon.current-language {
    top: -3px;
}

#service-nav li a .service-menu-container .icon.current-language img {
    width: 23px;
}

#service-nav li a .service-menu-container .service-menu-text {
    display: block;
    float: right;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 18px;
    line-height: 38px;
}

#service-nav li .swiss-languages {
    position: absolute;
    top: 40px;
    display: none;
}

#service-nav li .swiss-languages li {
    margin-bottom: 0;
}

#service-nav li .swiss-languages li a {
    padding-left: 10px;
    font-size: 15px;
}

#service-nav li.contact {
    width: 117px;
}

#service-nav li.contact:hover {
    left: -77px;
}

#service-nav li.locations {
    width: 117px;
}

#service-nav li.locations.logged-in a {
    width: 220px;
}

#service-nav li.locations:hover {
    left: -94px;
}

#service-nav li.locations:hover.logged-in {
    left: -180px;
}

#service-nav li.email {
    width: 117px;
}

#service-nav li.email:hover {
    left: -65px;
}

#service-nav li.newsletter {
    width: 117px;
}

#service-nav li.newsletter:hover {
    left: -103px;
}

#service-nav li.language:hover {
    left: -123px;
}

#service-nav li.language:hover .swiss-languages {
    display: block;
}

#service-nav li.language {
    width: 140px;
    margin-bottom: 20px;
    z-index: 2;
}

#service-nav li.language img {
    position: relative;
    top: -2px;
}

#service-nav li.language a {
    width: 190px;
}

#service-nav li.shopping-list {
    width: 140px;
}

#service-nav li.shopping-list a {
    width: 170px;
}

#service-nav li.shopping-list:hover {
    left: -130px;
}

#service-nav li.compare {
    width: 160px;
}

#service-nav li.compare a {
    width: 190px;
}

#service-nav li.compare:hover {
    left: -143px;
}

#service-nav li .items-on-list-wrapper {
    background: #fff;
    border-radius: 50%;
    display: block;
    height: 20px;
    position: absolute;
    right: 2px;
    text-align: center;
    top: 15px;
    left: 5px;
    width: 20px;
    z-index: 1;
}

#service-nav li .items-on-list-wrapper .items-on-list {
    font-size: 14px;
    line-height: 1;
    position: relative;
    top: -4px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    color: #164194;
}

#main-nav {
    width: 410px;
    height: 100%;
    position: absolute;
    left: -370px;
    top: 0;
    background: #164194;
    z-index: 500;
    padding-left: 30px;
    padding-right: 40px;
    transition: width .5s ease 0s,left .5s ease 0s;
}

#main-nav:hover {
    background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
}

#main-nav .nav-regular {
    margin-top: 220px;
}

#main-nav .submenu {
    padding-left: 30px;
}

#main-nav a {
    color: #fff;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 21px;
    margin-bottom: 15px;
    display: inline-block;
    padding: 2px;
}

#main-nav a:hover {
    background: #fff;
    color: #164194;
}

#main-nav .active a {
    background: #fff;
    color: #164194;
}

#main-nav .nav-icon {
    color: #fff;
    position: absolute;
    right: 10px;
    top: 350px;
    cursor: pointer;
}

#main-nav .nav-icon:after {
    content: "s";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
}

#main-nav:hover {
    left: 0;
}

#main-nav:hover .nav-icon:after {
    content: "y";
}

.fixed-contact-wrapper {
    display: none;
    background: #164194;
    color: #fff;
    position: fixed;
    right: 70px;
    width: 300px;
    padding: 25px 15px 40px;
    z-index: 1;
    top: 264px;
    -webkit-box-shadow: 0 0 27px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 27px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 27px 4px rgba(0, 0, 0, 0.25);
}

.fixed-contact-wrapper .winkler-close {
    position: absolute;
    top: 15px;
    right: 15px;
}

.fixed-contact-wrapper h1 {
    text-align: left;
    line-height: 40px;
    margin-bottom: 30px;
}

.fixed-contact-wrapper h4 {
    font-size: 20px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    margin-bottom: 10px;
}

.fixed-contact-wrapper a {
    color: #fff;
}

.fixed-contact-wrapper a .icon {
    margin-left: 5px;
}

.fixed-contact-wrapper:after {
    border-width: 22.5px 0 22.5px 20px;
    border-color: transparent transparent transparent #164194;
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    right: -20px;
    position: absolute;
    top: 11px;
    width: 0;
}

.pagination-container {
    margin-top: 45px;
}

.pagination {
    border-radius: 0;
    display: inline-block;
    margin: 20px 0;
    padding-left: 0;
}

.pagination > li {
    display: inline-block;
    margin-right: 5px;
}

.pagination > li > a, .pagination > li > span {
    background-color: #164194;
    border: none;
    color: #fff;
    float: left;
    line-height: 1;
    position: relative;
    text-decoration: none;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    text-align: center;
    padding-top: 10px;
}

.pagination > li > a .icon, .pagination > li > span .icon {
    padding-top: 0;
}

.pagination > li > a:hover, .pagination > li > span:hover {
    background-color: #fff;
    color: #164194;
    border: none;
}

.pagination > li:last-child > a, .pagination > li:first-child > a, .pagination > li:last-child > span, .pagination > li:first-child > span {
    border-radius: 0;
}

.pagination > li.disabled {
    display: none;
}

.pagination > .active > a, .pagination > .active a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #fff;
    border: none;
    color: #164194;
    cursor: default;
    z-index: 3;
}

.info-window {
    position: fixed;
    bottom: 0;
    text-align: center;
    z-index: 1501;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    width: 100%;
}

.info-window .cart-info, .info-window .shoppinglist-info, .info-window .compare-info {
    position: absolute;
    bottom: -70px;
    padding: 20px 0;
    background: #164194;
    color: #fff;
    width: 100%;
    max-height: 80px;
}

@media (max-width: 400px) {
    .info-window p {
        width: 70%;
        margin: 0 auto;
    }
}

footer {
    background-color: #164194;
    color: #fff;
    position: relative;
    z-index: 1500;
    margin-top: 90px;
}

footer .footer-group a, footer .footer-bottomlinks a, footer .footer-group a:hover, footer .footer-bottomlinks a:hover {
    color: #fff;
}

footer .footer-toplinks .container {
    padding: 0 0 25px 0;
}

footer .footer-toplinks .footer-group {
    padding-top: 40px;
    position: relative;
}

footer .footer-toplinks .footer-group h4 {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 20px;
    margin-bottom: 14px;
}

footer .footer-toplinks .footer-group li {
    margin-bottom: 12px;
    line-height: 19px;
}

footer .footer-bottomlinks {
    padding: 0 0 28px 0;
    text-align: center;
}

footer .footer-bottomlinks ul {
    border-top: 1px solid #fff;
    padding-top: 28px;
}

footer .footer-bottomlinks li {
    display: inline-block;
    padding: 0 10px;
}

footer .footer-legal-links {
    background-color: #fff;
    padding: 28px 0;
    text-align: center;
}

footer .footer-legal-links li {
    display: inline-block;
    margin-left: 20px;
}

footer .footer-legal-links li:first-child {
    margin-left: 0;
}

footer .footer-legal-links a {
    color: #000;
}

footer .footer-legal-links a:hover {
    color: #bb2d21;
}

footer .footer-seo {
    background-color: #eaeaea;
    text-align: center;
    padding: 40px 0 90px 0;
}

footer .footer-seo p {
    color: #000;
}

footer .footer-seo strong {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.target-group-links.container-fluid {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 10px 0;
}

.target-group-links.container-fluid .target-group {
    text-align: center;
}

.target-group-links.container-fluid .target-group:first-child {
    width: 28%;
}

.target-group-links.container-fluid .target-group:nth-child(2) {
    width: 24%;
}

.target-group-links.container-fluid .target-group:nth-child(3) {
    width: 24%;
}

.target-group-links.container-fluid .target-group:nth-child(4) {
    width: 24%;
}

.target-group-links.container-fluid .target-group a {
    color: #fff;
    font-size: 15px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    text-shadow: 1px 1px 3px #000;
}

@media (min-width: 992px) {
    .target-group-links.container-fluid .target-group a {
        font-size: 22px;
    }
}

.target-group-links.container-fluid .target-group a .icon {
    padding-top: 5px;
}

.login-section {
    margin-bottom: 40px;
}

.login-section .registration-notice {
    color: #747474;
    font-style: italic;
    text-align: center;
    font-size: 18px;
    margin-top: -5px;
    margin-bottom: 30px;
}

.login-section h2 {
    text-align: left;
    margin-bottom: 15px;
}

.login-section .keep-logged-in {
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
}

.login-section .keep-logged-in .cookie-note-tooltip {
    visibility: hidden;
    width: 700px;
    background-color: #eaeaea;
    color: #000;
    padding: 45px 15px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 70px;
}

.login-section .keep-logged-in .cookie-note-tooltip:after {
    content: "";
    border-color: transparent transparent #eaeaea;
    border-style: solid;
    border-width: 0 25px 20px;
    top: -20px;
    display: block;
    height: 0;
    left: 230px;
    position: absolute;
    width: 0;
}

.login-section .keep-logged-in .cookie-note-tooltip .icon {
    position: absolute;
    right: 15px;
    top: 15px;
}

.login-section .keep-logged-in .cookie-note:hover .cookie-note-tooltip {
    visibility: visible;
}

.login-section .login-button {
    margin-bottom: 10px;
}

.login-section .register-link .btn {
    padding: 7px 0;
    min-width: 220px;
}

.login-section .btn {
    min-width: 220px;
}

.login-section .advantage-list li {
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px;
    line-height: 19px;
}

.login-section .advantage-list li .icon {
    position: absolute;
    left: -3px;
    top: -11px;
    color: #164194;
    font-size: 30px;
    width: 35px;
    height: 30px;
}

.login-section .advantage-list li:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #eaeaea;
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
}

.advantage-list-register li {
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px;
    line-height: 19px;
}

.advantage-list-register li .icon {
    position: absolute;
    left: -3px;
    top: -11px;
    color: #164194;
    font-size: 30px;
    width: 35px;
    height: 30px;
}

.advantage-list-register li:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .offer-section .tab-products .offer-single-product, .offer-section .offer-products .offer-single-product {
        padding-bottom: 60px;
    }
}

@media (min-width: 992px) {
    .offer-section .tab-products .offer-single-product, .offer-section .offer-products .offer-single-product {
        padding-bottom: 30px;
    }
}

.offer-section .tab-products .offer-single-product .price-wrapper, .offer-section .offer-products .offer-single-product .price-wrapper {
    background-color: #bb2d21;
    color: #fff;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    padding: 8px 5px;
    margin-bottom: 10px;
}

.offer-section .tab-products .offer-single-product .price-wrapper .price, .offer-section .offer-products .offer-single-product .price-wrapper .price {
    font-size: 34px;
}

.offer-section .tab-products .offer-single-product h2.product-name, .offer-section .offer-products .offer-single-product h2.product-name {
    margin-bottom: 5px;
    text-align: left;
}

.offer-section .tab-products .offer-single-product p.order-number-wrapper, .offer-section .offer-products .offer-single-product p.order-number-wrapper {
    color: #747474;
    margin-bottom: 15px;
}

.offer-section .tab-products .offer-single-product .link-to-product, .offer-section .offer-products .offer-single-product .link-to-product {
    position: relative;
}

.offer-section .tab-products .offer-single-product .link-to-product .product-short-description-list li, .offer-section .offer-products .offer-single-product .link-to-product .product-short-description-list li {
    line-height: 20px;
    margin-bottom: 7px;
}

.offer-section .tab-products .offer-single-product .link-to-product .product-short-description-list li:last-child, .offer-section .offer-products .offer-single-product .link-to-product .product-short-description-list li:last-child {
    margin-bottom: 0;
}

.offer-section .tab-products .offer-single-product .link-to-product .product-short-description-list li:last-child:after, .offer-section .offer-products .offer-single-product .link-to-product .product-short-description-list li:last-child:after {
    content: "d";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 2;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    padding-top: 0;
    color: #bb2d21;
}

.offer-section .tab-products .offer-single-product .link-to-product:hover h2, .offer-section .offer-products .offer-single-product .link-to-product:hover h2 {
    color: #000;
}

.offer-section .tab-products .offer-single-product .icons-container .add-to-icons, .offer-section .offer-products .offer-single-product .icons-container .add-to-icons {
    margin-bottom: 1px;
}

.offer-section .tab-products .offer-single-product .icons-container .add-to-icons a, .offer-section .offer-products .offer-single-product .icons-container .add-to-icons a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}

.offer-section .tab-products .offer-single-product .icons-container .add-to-icons .icon, .offer-section .offer-products .offer-single-product .icons-container .add-to-icons .icon {
    font-size: 21px;
    color: #fff;
    padding-top: 0;
}

.offer-section .tab-products .offer-single-product .icons-container .add-to-icons.add-to-cart-icon, .offer-section .offer-products .offer-single-product .icons-container .add-to-icons.add-to-cart-icon, .offer-section .tab-products .offer-single-product .icons-container .add-to-icons.shopping-list-icon, .offer-section .offer-products .offer-single-product .icons-container .add-to-icons.shopping-list-icon {
    background: #bb2d21;
}

.offer-section .tab-products .offer-single-product .icons-container .add-to-icons.compare-icon, .offer-section .offer-products .offer-single-product .icons-container .add-to-icons.compare-icon {
    background: #164194;
    font-size: 27px !important;
    margin-top: -3px;
    padding-left: 0 !important;
    padding-top: 0;
    width: 41px;
}

.offer-section .tab-products .offer-single-product:hover .icons-container, .offer-section .offer-products .offer-single-product:hover .icons-container {
    display: block;
}

.offer-section .more-offers-link {
    margin-top: 30px;
    text-align: center;
}

.catalog-section.contact-section img {
    margin-bottom: 20px;
    width: 100%;
}

.catalog-section.contact-section h2 {
    margin-bottom: 10px;
}

.catalog-section.contact-section .catalog-link {
    display: block;
    margin-top: 30px;
}

.catalog-section.contact-section a .icon {
    color: #164194;
    margin-left: 5px;
}

.catalog-section.contact-section a:hover h2 {
    color: #000;
}

.catalog-section.contact-section a:hover .icon {
    color: #bb2d21;
}

.contact img {
    margin-bottom: 20px;
    width: 100%;
}

.contact h2 {
    margin-bottom: 10px;
}

.contact p {
    margin-bottom: 20px;
}

.contact .icon {
    color: #164194;
}

.contact a:hover .icon {
    color: #bb2d21;
}

.news-section .news-inner {
    padding: 30px;
    background: #eaeaea;
}

.news-section .scrollable {
    height: 125px;
    overflow: auto;
    white-space: nowrap;
}

.news-section article {
    width: 33%;
    padding: 0 1%;
    display: inline-block;
    vertical-align: top;
}

.news-section article img {
    float: left;
    margin: 0 10px 10px 0;
}

.news-section article h5 {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    white-space: normal;
    line-height: 22px;
}

.newsletter-register-section {
    padding: 0;
}

@media (min-width: 768px) {
    .newsletter-register-section {
        padding: 0 20px;
    }
}

@media (min-width: 992px) {
    .newsletter-register-section {
        padding: 0 10px;
    }
}

@media (min-width: 1200px) {
    .newsletter-register-section {
        padding: 0 20px;
    }
}

.newsletter-register-section .newsletter-register-inner {
    background: #eaeaea;
    position: relative;
    padding: 0 20px;
}

.newsletter-register-section .row {
    padding-top: 10px;
    padding-bottom: 15px;
}

.newsletter-register-section .newsletter-text h2, .newsletter-register-section .newsletter-button h2 {
    margin-bottom: 10px;
}

.newsletter-register-section .newsletter-picture {
    padding-right: 30px;
    text-align: center;
    position: relative;
}

.newsletter-register-section .newsletter-picture img {
    width: 280px;
    max-width: 280px;
    position: relative;
    bottom: -22px;
}

@media (min-width: 992px) {
    .newsletter-register-section .newsletter-picture img {
        width: 100%;
        position: absolute;
        bottom: -104px;
        left: 50%;
        margin-left: -140px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .newsletter-register-section .newsletter-picture {
        position: absolute;
        right: 0;
        bottom: 15px;
    }
}

.newsletter-register-section .btn.newsletter-register {
    margin-top: 25px;
    width: 100%;
    padding: 7px 0;
}

@media (min-width: 768px) and (max-width: 991px) {
    .newsletter-register-section .btn.newsletter-register {
        max-width: 220px;
    }
}

.quick-order {
    width: 100%;
    padding: 0;
}

.quick-order-table .row > div {
    padding: 0 10px;
}

.loggedin.header-image .page-title .welcome-text {
    margin-bottom: 10px;
}

.loggedin.header-image .page-title input.form-control {
    background-color: #fff;
    border: 1px solid #fff;
}

.loggedin.header-image .page-title input.form-control::-webkit-input-placeholder {
    color: #000;
}

.loggedin.header-image .page-title input.form-control:-moz-placeholder {
    color: #000;
}

.loggedin.header-image .page-title input.form-control::-moz-placeholder {
    color: #000;
}

.loggedin.header-image .page-title input.form-control:-ms-input-placeholder {
    color: #000;
}

.loggedin.header-image .page-title .search-field-wrapper {
    width: 65%;
}

.loggedin.header-image .page-title .search-field-wrapper input.form-control {
    font-size: 18px;
}

.loggedin.header-image .page-title .search-field-wrapper .search-field {
    background: #fff url("/images/search.svg") no-repeat scroll 52px 10px;
    text-align: center;
}

@media (max-width: 767px) {
    .loggedin.header-image .page-title .search-field-wrapper .search-field {
        background-position: 10px 10px;
    }
}

.loggedin.header-image .page-title .search-button-wrapper {
    width: 35%;
}

.loggedin.header-image .page-title .search-button {
    height: 40px;
    line-height: 1;
    vertical-align: unset;
    width: 100%;
    padding: 7px 0;
}

.loggedin .breadcrumbs {
    margin-top: 15px;
}

.order-material-number {
    color: #747474;
}

.product-detail-overview {
    position: relative;
}

.product-detail-overview .product-name {
    margin-bottom: 5px;
}

.product-detail-overview .order-material-number {
    color: #747474;
    margin-bottom: 60px;
}

@media (max-width: 991px) {
    .product-detail-overview .order-material-number {
        margin-bottom: 30px;
    }
}

.product-detail-overview .product-options {
    margin-bottom: 60px;
}

@media (max-width: 991px) {
    .product-detail-overview .product-options {
        margin-bottom: 30px;
    }
}

.product-detail-overview .product-options .units {
    margin-bottom: 10px;
}

.product-detail-overview .product-image-container {
    height: 400px;
    position: relative;
}

.product-detail-overview .product-image-container .product-image {
    padding-right: 15px;
    text-align: right;
}

.product-detail-overview .product-image-container .product-image img {
    max-width: 370px;
    position: absolute;
    top: 50%;
    left: 60%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.product-detail-overview .product-image-container .product-thumbnails li {
    display: block;
    height: 70px;
    width: 70px;
    margin-bottom: 20px;
    position: relative;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.product-detail-overview .product-image-container .product-thumbnails li img {
    max-width: 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.product-detail-overview .product-image-container .product-thumbnails .video-thumb-link {
    display: block;
    background: transparent url("/images/products/winkler_video.png") no-repeat;
    background-size: 100% auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    margin-top: -8.5px;
    margin-left: -12.5px;
    width: 25px;
    height: 17px;
}

@media (min-width: 768px) {
    .product-detail-overview .product-image-container .product-thumbnails .video-thumb-link {
        margin-top: -12.5px;
        margin-left: -18px;
        width: 36px;
        height: 25px;
    }
}

.product-detail-overview .zoom-text {
    color: #747474;
}

@media (max-width: 1024px) {
    .product-detail-overview .zoom-text {
        display: none;
    }
}

.product-detail-overview .zoom-container {
    display: none;
    height: 560px;
    position: absolute;
    right: 15px;
    top: 100px;
    width: 560px;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
}

.product-detail-overview .zoom-container img {
    width: 100%;
}

.product-detail-overview .price-box {
    margin-bottom: 30px;
}

.product-detail-overview .price-box .nav-tabs {
    border: none;
}

.product-detail-overview .price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.product-detail-overview .price-box .nav-tabs li.active {
    text-decoration: none;
}

.product-detail-overview .price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.product-detail-overview .price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
}

.product-detail-overview .price-box .nav-tabs li a:hover {
    background: none;
}

.product-detail-overview .price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.product-detail-overview .price-box .nav-tabs li:first-child a {
    padding-left: 0;
}

.product-detail-overview .price-box .tab-content {
    margin-top: 30px;
}

.product-detail-overview .price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

.product-detail-overview .price-box .regular-price .price {
    font-size: 34px;
}

.product-detail-overview .tax-details {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.product-detail-overview .dl-horizontal.offer-list {
    margin-top: 20px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

@media (max-width: 767px) {
    .product-detail-overview .dl-horizontal.offer-list {
        margin-bottom: 50px;
    }
}

.product-detail-overview .dl-horizontal.offer-list dt {
    width: 120px;
    text-align: left;
}

.product-detail-overview .dl-horizontal.offer-list dd {
    margin-left: 120px;
}

.product-detail-overview .availability-text {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.product-detail-overview .availability-list {
    line-height: 20px;
}

.product-detail-overview .availability-list .availability-location {
    border-top: 1px solid #747474;
    padding-bottom: 10px;
    padding-top: 15px;
}

.product-detail-overview .availability-list .availability-location .location, .product-detail-overview .availability-list .availability-location .availability-color-box {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.product-detail-overview .availability-list .availability-location .icon {
    margin-left: 5px;
}

.product-detail-overview .availability-list .availability-color-box {
    position: relative;
    padding-left: 25px;
    display: inline-block;
}

.product-detail-overview .availability-list .availability-color-box:before {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
}

.product-detail-overview .availability-list .availability-color-box.green {
    color: #008a00;
}

.product-detail-overview .availability-list .availability-color-box.green:before {
    background: #008a00;
}

.product-detail-overview .availability-list .availability-color-box.yellow {
    color: #eb0;
}

.product-detail-overview .availability-list .availability-color-box.yellow:before {
    background: #eb0;
}

.product-detail-overview .availability-list .availability-icons {
    color: #bb2d21;
}

.product-detail-overview .availability-list .availability-icons .icon {
    font-size: 27px;
    width: 28px;
    height: 28px;
}

.product-detail-overview .availability-list .central-warehouse {
    border-top: none;
}

.add-to-box {
    margin-top: 50px;
}

@media (max-width: 991px) {
    .add-to-box {
        margin-top: 30px;
    }
}

.add-to-box .add-to-cart-qty {
    margin-right: 5px;
    width: 60px;
    height: 40px;
    background: #eaeaea;
    border: none;
    text-align: right;
    padding-right: 10px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.add-to-box label {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.add-to-box .btn {
    margin-top: 15px;
    padding: 7px 0;
    width: 100%;
    max-width: 220px;
    float: right;
    clear: both;
}

.add-to-box .btn:first-child {
    margin-top: 0;
}

.add-to-box .btn.add-to-cart {
    background: #bb2d21;
}

.add-to-box input[type=number] {
    -moz-appearance: textfield;
}

.add-to-box input[type=number]::-webkit-inner-spin-button, .add-to-box input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.productdetail-item-configuration {
    position: relative;
}

.productdetail-item-configuration h2 {
    margin-bottom: 0;
}

.productdetail-item-configuration p {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .productdetail-item-configuration .product-price {
        float: left;
    }
}

.productdetail-item-configuration .product-price .tax-details {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.productdetail-item-configuration .product-price .regular-price {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    color: #bb2d21;
}

@media (max-width: 767px) {
    .productdetail-item-configuration .product-price .regular-price {
        margin-bottom: 30px;
    }
}

.productdetail-item-configuration .add-to-box {
    margin-top: -4px;
}

.productdetail-item-configuration .tab-content-product-wrapper, .productdetail-item-configuration .item {
    padding-top: 30px;
    padding-bottom: 5px;
    border-top: 1px solid #747474;
    margin-left: 0;
    margin-right: 0;
    clear: both;
    position: relative;
}

@media (min-width: 768px) {
    .productdetail-item-configuration .tab-content-product-wrapper, .productdetail-item-configuration .item {
        padding-top: 40px;
    }
}

.productdetail-item-configuration .tab-content-product-wrapper .col-sm-9, .productdetail-item-configuration .item .col-sm-9 {
    margin-bottom: 25px;
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container, .productdetail-item-configuration .item .icons-container {
    display: block;
    position: absolute;
    right: 0;
    bottom: 30px;
}

@media (min-width: 768px) {
    .productdetail-item-configuration .tab-content-product-wrapper .icons-container, .productdetail-item-configuration .item .icons-container {
        top: 40px;
        bottom: auto;
    }
}

@media (min-width: 1025px) {
    .productdetail-item-configuration .tab-content-product-wrapper .icons-container, .productdetail-item-configuration .item .icons-container {
        display: none;
    }
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons, .productdetail-item-configuration .item .icons-container .add-to-icons {
    float: left;
    margin-right: 1px;
}

@media (min-width: 768px) {
    .productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons, .productdetail-item-configuration .item .icons-container .add-to-icons {
        float: none;
        margin-right: 0;
        margin-bottom: 1px;
    }
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons a, .productdetail-item-configuration .item .icons-container .add-to-icons a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons .icon, .productdetail-item-configuration .item .icons-container .add-to-icons .icon {
    font-size: 21px;
    color: #fff;
    padding-top: 0;
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, .productdetail-item-configuration .item .icons-container .add-to-icons.add-to-cart-icon {
    background: #bb2d21;
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, .productdetail-item-configuration .item .icons-container .add-to-icons.compare-icon, .productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, .productdetail-item-configuration .item .icons-container .add-to-icons.shopping-list-icon {
    background: #164194;
}

.productdetail-item-configuration .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, .productdetail-item-configuration .item .icons-container .add-to-icons.compare-icon .icon {
    font-size: 27px;
    margin-top: -3px;
    padding-left: 0;
    padding-top: 0;
    width: 41px;
}

.productdetail-item-configuration .tab-content-product-wrapper:hover .icons-container, .productdetail-item-configuration .item:hover .icons-container {
    display: block;
}

.productdetail-item-configuration .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    margin-right: 4px;
    margin-top: 3px;
}

@media (max-width: 1025px) {
    .productdetail-item-configuration .item-price-box .nav-tabs {
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .productdetail-item-configuration .item-price-box .nav-tabs {
        float: left;
    }
}

.productdetail-item-configuration .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.productdetail-item-configuration .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.productdetail-item-configuration .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

.productdetail-item-configuration .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.productdetail-item-configuration .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

.productdetail-item-configuration .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.productdetail-item-configuration .item-price-box .nav-tabs li a:hover {
    background: none;
}

.productdetail-item-configuration .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

.productdetail-item-configuration .item-price-box .tab-content {
    margin-top: 0;
    padding-top: 0;
}

@media (min-width: 992px) {
    .productdetail-item-configuration .item-price-box .tab-content {
        float: left;
        margin-bottom: 20px;
    }
}

.productdetail-item-configuration .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

.productdetail-item-configuration .item-price-box .regular-price .price {
    font-size: 24px;
}

.productdetail-item-configuration .product-image img {
    width: 100%;
    height: auto;
}

.products-grid-configuration .item-price-box {
    margin-bottom: 15px;
}

.products-grid-configuration .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    float: left;
    margin-right: 4px;
    margin-top: 3px;
}

.products-grid-configuration .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.products-grid-configuration .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.products-grid-configuration .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

.products-grid-configuration .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.products-grid-configuration .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

.products-grid-configuration .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.products-grid-configuration .item-price-box .nav-tabs li a:hover {
    background: none;
}

.products-grid-configuration .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

.products-grid-configuration .item-price-box .tab-content {
    padding-top: 20px;
}

.products-grid-configuration .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    margin-top: 15px;
    margin-bottom: 15px;
}

.products-grid-configuration .item-price-box .regular-price .price {
    font-size: 34px;
}

.products-grid-configuration .item-price-box .regular-price .icon {
    font-size: 22px;
    width: 20px;
    height: 24px;
    top: 2px;
}

.products-grid-configuration h2 {
    margin-bottom: 5px;
    clear: both;
}

.products-grid-configuration .order-material-number {
    line-height: 18px;
}

.products-grid-configuration .icons-container {
    position: absolute;
    right: 15px;
    top: 0;
}

@media (min-width: 1025px) {
    .products-grid-configuration .icons-container {
        display: none;
    }
}

.products-grid-configuration .icons-container .add-to-cart-icon {
    background: #bb2d21;
}

.products-grid-configuration .icons-container .shopping-list-icon, .products-grid-configuration .icons-container .compare-icon {
    background: #164194;
}

.products-grid-configuration .icons-container .icon {
    color: #fff;
    font-size: 21px;
    padding-top: 0;
}

.products-grid-configuration .icons-container .add-to-icons {
    margin-right: 1px;
}

.products-grid-configuration .icons-container .add-to-icons a {
    display: block;
    height: 40px;
    padding-top: 10px;
    text-align: center;
    width: 40px;
}

.products-grid-configuration .item {
    position: relative;
}

.products-grid-configuration .item:hover .icons-container {
    display: block;
}

.productdetails-container .tab-content {
    padding-top: 30px;
}

@media (max-width: 991px) {
    .productdetails-container .tab-content {
        padding-top: 20px;
    }
}

.productdetails-container .nav-tabs {
    text-align: center;
    border-bottom: none;
}

.productdetails-container .nav-tabs > li {
    display: inline-block;
    float: none;
    margin-bottom: 0;
    margin-right: -4px;
    border-right: 1px solid #fff;
}

.productdetails-container .nav-tabs > li > a {
    border: none;
    border-radius: 0;
    line-height: 1.42857;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 18px;
    background: #164194;
    color: #fff;
}

.productdetails-container .nav-tabs > li > a:hover {
    background: #fff;
    color: #164194;
}

.productdetails-container .nav-tabs > li > a:after {
    content: "d";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
    top: 1px;
    width: 26px;
    padding-left: 6px;
}

.productdetails-container .nav-tabs > li.active > a, .productdetails-container .nav-tabs > li.active > a:focus, .productdetails-container .nav-tabs > li.active > a:hover {
    border: none;
    color: #164194;
    background: #fff;
}

.productdetails-container .nav-tabs > li.active > a:after, .productdetails-container .nav-tabs > li.active > a:focus:after, .productdetails-container .nav-tabs > li.active > a:hover:after {
    content: "a";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
    top: 5px;
    width: 26px;
    padding-left: 6px;
}

.productdetails-container .specifics-table-container .specifics-table {
    margin: 10px 0 0;
    background: #eaeaea;
    padding: 0 0 25px 0;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper {
    padding: 12px 0;
    border-top: 1px solid #fff;
    width: 46%;
    margin: 0 2%;
}

.productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper:first-child, .productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper:nth-child(2) {
    border-top: none;
}

.productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper .col-sm-6 {
    padding: 0;
}

.productdetails-container #productdetails > p {
    margin-bottom: 30px;
}

.productdetails-container #downloads .download-list {
    margin: 10px 0 0 0;
    background: #eaeaea;
}

.productdetails-container #downloads .download-item {
    padding: 15px 0 15px;
    border-top: 1px solid #fff;
    margin: 0 2%;
    width: 46%;
}

.productdetails-container #downloads .download-item:first-child, .productdetails-container #downloads .download-item:nth-child(2) {
    border-top: none;
}

.productdetails-container #downloads .download-item .col-sm-8 {
    padding-left: 0;
}

.productdetails-container #downloads .download-item .col-sm-4 {
    padding-right: 0;
    text-align: right;
}

.productdetails-container #downloads .download-item a {
    color: #164194;
    display: inline-block;
    padding-top: 10px;
}

.productdetails-container #downloads .download-item p {
    line-height: 20px;
}

.register-benefits {
    background-color: #eaeaea;
    padding-top: 24px;
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 60px;
    margin-top: -7px;
}

.register-form-section .company-container {
    margin-bottom: 50px;
}

.register-form-section .form-group {
    margin-bottom: 30px;
}

.register-form-section .required-info {
    margin-top: 50px;
    margin-bottom: 50px;
}

.register-form-section .offer-agreement-checkbox {
    margin-bottom: 15px;
}

.register-form-section .offer-agreement-checkbox .styled-checkbox {
    display: block;
    float: left;
}

@media (min-width: 768px) {
    .register-form-section .offer-agreement-checkbox .styled-checkbox {
        margin-top: 7px;
    }
}

.register-form-section .register-info {
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .register-form-section .register-info {
        margin-bottom: 50px;
    }
}

.register-form-section .register-btn {
    padding: 7px 0;
    width: 100%;
    text-align: center;
}

@media (min-width: 768px) {
    .register-form-section .register-btn {
        margin-top: 7px;
    }
}

.register-form-section .registration-notice {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .register-form-section .register-row .btn-right, .register-form-section .register-row .safety-informations {
        margin-top: 0;
    }
}

.safety-informations {
    margin-top: 49px;
    text-decoration: underline;
}

.password-forgotten p {
    margin-bottom: 60px;
}

.password-forgotten .form-group {
    margin-bottom: 30px;
}

.password-forgotten .btn-right {
    margin-top: 60px;
}

.header-search-result .search-result-number, .header-search-result .search-result-text, .header-search-result .search-query-text {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 18px;
}

.header-search-result .icon {
    color: #bb2d21;
    margin-left: 5px;
}

.filter-container {
    background: #eaeaea;
    margin-top: -7px;
    padding-top: 40px;
    padding-bottom: 25px;
    margin-bottom: 0;
    width: 100%;
}

@media (min-width: 768px) {
    .filter-container {
        margin-bottom: 65px;
    }
}

.filter-container .select-wrapper-for-arrow:after {
    padding-top: 0;
    right: 13px;
}

.filter-container .container > .row.additional-padding {
    margin-bottom: 20px;
}

.filter-container .category-dropdown-wrapper {
    background: #fff;
    padding: 0 15px;
}

.filter-container .category-dropdown-wrapper p {
    padding: 7px 0;
}

.filter-container .filter-button {
    cursor: pointer;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    position: relative;
    z-index: 2;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.filter-container .filter-button .filter-value {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
}

.filter-container #category-dropdown .checkbox {
    line-height: 38px;
    min-height: 44px;
}

.filter-container #category-dropdown .checkbox label {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    line-height: 22px;
    max-width: 185px;
    vertical-align: middle;
    display: inline-block;
    min-height: 0;
    padding-left: 0;
}

@media (min-width: 1200px) {
    .filter-container #category-dropdown .checkbox label {
        max-width: 250px;
    }
}

.filter-container #category-dropdown .checkbox label .filter-result-number {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
}

.filter-container #category-dropdown .cancel-category-selection {
    margin-top: 10px;
}

.filter-container #category-dropdown .cancel-category-selection a {
    text-decoration: underline;
}

.filter-container .category-row {
    z-index: 6;
    position: relative;
    margin-bottom: 20px;
}

.filter-container .category-row .select-wrapper-for-arrow:after {
    z-index: 2;
}

.filter-container .category-row .filter-button {
    z-index: 2;
    position: relative;
}

.filter-container .filters {
    z-index: 5;
    position: relative;
}

.filter-container .filter-row {
    margin-bottom: 20px;
    position: relative;
}

.filter-container .filter-row.row-1 {
    z-index: 5;
}

.filter-container .filter-row.row-2 {
    z-index: 4;
}

.filter-container .filter-row.row-3 {
    z-index: 3;
}

.filter-container .filter-row .filter-dropdown-wrapper {
    background: #fff;
    padding: 7px 15px;
    position: relative;
}

.filter-container .filter-row .filter-dropdown-wrapper:last-child {
    margin-right: 0;
}

.filter-container .filter-row .filter-dropdown-wrapper.select-wrapper-for-arrow:after {
    z-index: 2;
}

.filter-container .filter-row .dropdown-filter {
    position: absolute;
    left: 0;
    right: 0;
    padding: 40px 15px 0;
    top: 0;
    background: #fff;
    z-index: 1;
    box-shadow: 0 0 10px #747474;
}

.filter-container .filter-row .dropdown-filter .collapse {
    max-height: 300px;
    overflow-y: auto;
}

.filter-container .filter-row .dropdown-filter .styled-checkbox {
    margin-right: 20px;
}

.filter-container .filter-row .dropdown-filter .checkbox {
    clear: both;
    line-height: 38px;
    min-height: 44px;
    margin-bottom: 20px;
}

.filter-container .filter-row .dropdown-filter .checkbox .styled-checkbox {
    float: left;
}

.filter-container .filter-row .dropdown-filter .checkbox label {
    padding-left: 0;
    display: inline;
}

.filter-container .filter-row .dropdown-filter .preview-value img {
    float: left;
    height: 40px;
    margin-right: 10px;
    margin-top: 3px;
    width: 40px;
}

.filter-container .filter-row .dropdown-filter .range-slider-container {
    margin-top: 13px;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .slider-values .form-control {
    width: 40%;
    float: left;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .slider-values .form-control.start-value {
    text-align: right;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .slider-values span {
    display: block;
    float: left;
    text-align: center;
    width: 20%;
    padding-top: 8px;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .range-slider {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .range-slider .thumb {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #164194;
    color: #fff;
    top: -16px;
    padding-top: 8px;
    text-align: center;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .range-slider .thumb.start-thumb {
    left: 15px;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .range-slider .thumb.end-thumb {
    right: 40px;
}

.filter-container .filter-row .dropdown-filter .range-slider-container .slide {
    height: 7px;
    border-radius: 4px;
    width: 100%;
    background: #eaeaea;
}

.filter-container .show-more-filters, .filter-container .show-filters {
    cursor: pointer;
    margin-top: 15px;
}

.filter-container .show-more-filters:after, .filter-container .show-filters:after {
    content: "\6b";
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    line-height: 2;
    width: 26px;
    padding-left: 6px;
    color: #bb2d21;
}

.filter-container .show-more-filters.collapsed:after, .filter-container .show-filters.collapsed:after {
    content: "\6f";
}

.filter-container .show-more-filters:hover, .filter-container .show-filters:hover {
    color: #bb2d21;
}

.filter-container .show-more-filters .display-filters-message {
    display: none;
}

.filter-container .show-more-filters .hide-filters-message {
    display: inline;
}

.filter-container .show-more-filters.collapsed .display-filters-message {
    display: inline;
}

.filter-container .show-more-filters.collapsed .hide-filters-message {
    display: none;
}

.filter-container .show-filters {
    display: none;
}

.filter-container .active-filters {
    margin-top: 30px;
}

.filter-container .active-filters .active-filter li {
    float: left;
    margin-right: 30px;
    margin-bottom: 15px;
}

.filter-container .active-filters .active-filter li .icon {
    cursor: pointer;
    font-size: 17px;
    margin-top: 2px;
    margin-left: 2px;
}

.filter-container .active-filters .active-filter li .icon:hover {
    color: #bb2d21;
}

.search-overlay .winkler-close {
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 7%;
    top: 60px;
}

.search-suggest {
    position: absolute;
    background: #fff;
    padding: 20px 15px;
    top: 60px;
    z-index: 1003;
    display: none;
    left: 0;
    right: 0;
}

@media (max-width: 767px) {
    .search-suggest {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.search-suggest p {
    margin-bottom: 10px;
    color: #000;
}

.search-suggest:after {
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 25px 20px;
    content: "";
    display: block;
    height: 0;
    left: 60px;
    position: absolute;
    top: -20px;
    width: 0;
}

.search-suggest li {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 18px;
    margin-bottom: 10px;
    float: left;
    width: 50%;
}

@media (max-width: 600px) {
    .search-suggest li {
        float: none;
        width: 100%;
    }
}

.search-suggest li .search-hit {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
    color: #000;
}

@media (max-width: 767px) {
    .sort-by {
        margin: 30px 0;
    }
}

.sort-by .select-wrapper-for-arrow select.form-control {
    background: #164194;
    color: #fff;
}

.sort-by .select-wrapper-for-arrow:after {
    color: #fff;
    padding-top: 0;
}

@media (min-width: 992px) {
    .toolbar {
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) {
    .toolbar .view-mode, .toolbar .sort-by {
        float: left;
    }

    .toolbar .view-mode strong, .toolbar .sort-by strong {
        margin-right: 10px;
        line-height: 40px;
    }
}

.toolbar .view-mode {
    display: none;
}

@media (min-width: 768px) {
    .toolbar .view-mode {
        display: block;
        margin-right: 60px;
    }

    .toolbar .view-mode strong {
        float: left;
    }
}

.search-result .view-mode .select-wrapper-for-arrow select.form-control, .order-section .view-mode .select-wrapper-for-arrow select.form-control, .offer-section .view-mode .select-wrapper-for-arrow select.form-control, .search-result .amount .select-wrapper-for-arrow select.form-control, .order-section .amount .select-wrapper-for-arrow select.form-control, .offer-section .amount .select-wrapper-for-arrow select.form-control, .search-result .sort-direction .select-wrapper-for-arrow select.form-control, .order-section .sort-direction .select-wrapper-for-arrow select.form-control, .offer-section .sort-direction .select-wrapper-for-arrow select.form-control {
    background: #164194;
    color: #fff;
}

.search-result .view-mode .select-wrapper-for-arrow:after, .order-section .view-mode .select-wrapper-for-arrow:after, .offer-section .view-mode .select-wrapper-for-arrow:after, .search-result .amount .select-wrapper-for-arrow:after, .order-section .amount .select-wrapper-for-arrow:after, .offer-section .amount .select-wrapper-for-arrow:after, .search-result .sort-direction .select-wrapper-for-arrow:after, .order-section .sort-direction .select-wrapper-for-arrow:after, .offer-section .sort-direction .select-wrapper-for-arrow:after {
    color: #fff;
    padding-top: 0;
}

.search-result .sort-direction select, .order-section .sort-direction select, .offer-section .sort-direction select {
    width: 150px;
}

.search-result .result-per-site-wrapper, .order-section .result-per-site-wrapper, .offer-section .result-per-site-wrapper {
    width: 90px;
}

.search-result .view-mode .icon, .order-section .view-mode .icon, .offer-section .view-mode .icon {
    cursor: pointer;
    color: #fff;
    background: #164194;
    height: 40px;
    padding-left: 11px;
    padding-top: 11px;
    width: 40px;
    font-size: 19px;
}

.search-result .view-mode .icon.active, .order-section .view-mode .icon.active, .offer-section .view-mode .icon.active, .search-result .view-mode .icon:hover, .order-section .view-mode .icon:hover, .offer-section .view-mode .icon:hover {
    color: #164194;
    background: #fff;
}

.search-result.no-result, .order-section.no-result, .offer-section.no-result {
    margin-top: 55px;
}

.search-result.no-result .no-result-info, .order-section.no-result .no-result-info, .offer-section.no-result .no-result-info {
    padding-right: 30px;
}

@media (max-width: 992px) {
    .search-result.no-result .no-result-info, .order-section.no-result .no-result-info, .offer-section.no-result .no-result-info {
        padding-right: 20px;
    }
}

.search-result.no-result .no-result-info p, .order-section.no-result .no-result-info p, .offer-section.no-result .no-result-info p {
    margin-bottom: 30px;
}

.search-result.no-result .contact .icon, .order-section.no-result .contact .icon, .offer-section.no-result .contact .icon {
    margin-left: 5px;
}

.search-result.no-result .no-result-form textarea, .order-section.no-result .no-result-form textarea, .offer-section.no-result .no-result-form textarea {
    background: #eaeaea none repeat scroll 0 0;
    border-color: #eaeaea;
    border-radius: 0;
    box-shadow: none;
    color: #000;
}

.search-result.no-result .no-result-form .btn, .order-section.no-result .no-result-form .btn, .offer-section.no-result .no-result-form .btn {
    margin-top: 30px;
}

.search-result.no-result .no-result-form-confirmation, .order-section.no-result .no-result-form-confirmation, .offer-section.no-result .no-result-form-confirmation {
    display: none;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.search-result.no-result .no-result-form-wrapper, .order-section.no-result .no-result-form-wrapper, .offer-section.no-result .no-result-form-wrapper {
    margin-bottom: 45px;
}

.search-result.no-result .no-result-form-wrapper h2, .order-section.no-result .no-result-form-wrapper h2, .offer-section.no-result .no-result-form-wrapper h2 {
    margin-bottom: 20px;
}

.products-list {
    position: relative;
}

.products-list h2 {
    margin-bottom: 0;
}

.products-list p {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .products-list .product-price {
        float: left;
    }
}

.products-list .product-price .tax-details {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.products-list .product-price .regular-price {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    color: #bb2d21;
}

@media (max-width: 767px) {
    .products-list .product-price .regular-price {
        margin-bottom: 30px;
    }
}

.products-list .add-to-box {
    margin-top: -4px;
}

.products-list .tab-content-product-wrapper, .products-list .item {
    padding-top: 30px;
    padding-bottom: 5px;
    border-top: 1px solid #747474;
    margin-left: 0;
    margin-right: 0;
    clear: both;
    position: relative;
}

@media (min-width: 768px) {
    .products-list .tab-content-product-wrapper, .products-list .item {
        padding-top: 40px;
    }
}

.products-list .tab-content-product-wrapper .col-sm-9, .products-list .item .col-sm-9 {
    margin-bottom: 25px;
}

.products-list .tab-content-product-wrapper .icons-container, .products-list .item .icons-container {
    display: block;
    position: absolute;
    right: 0;
    bottom: 30px;
}

@media (min-width: 768px) {
    .products-list .tab-content-product-wrapper .icons-container, .products-list .item .icons-container {
        top: 40px;
        bottom: auto;
    }
}

@media (min-width: 1025px) {
    .products-list .tab-content-product-wrapper .icons-container, .products-list .item .icons-container {
        display: none;
    }
}

.products-list .tab-content-product-wrapper .icons-container .add-to-icons, .products-list .item .icons-container .add-to-icons {
    float: left;
    margin-right: 1px;
}

@media (min-width: 768px) {
    .products-list .tab-content-product-wrapper .icons-container .add-to-icons, .products-list .item .icons-container .add-to-icons {
        float: none;
        margin-right: 0;
        margin-bottom: 1px;
    }
}

.products-list .tab-content-product-wrapper .icons-container .add-to-icons a, .products-list .item .icons-container .add-to-icons a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}

.products-list .tab-content-product-wrapper .icons-container .add-to-icons .icon, .products-list .item .icons-container .add-to-icons .icon {
    font-size: 21px;
    color: #fff;
    padding-top: 0;
}

.products-list .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, .products-list .item .icons-container .add-to-icons.add-to-cart-icon {
    background: #bb2d21;
}

.products-list .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, .products-list .item .icons-container .add-to-icons.compare-icon, .products-list .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, .products-list .item .icons-container .add-to-icons.shopping-list-icon {
    background: #164194;
}

.products-list .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, .products-list .item .icons-container .add-to-icons.compare-icon .icon {
    font-size: 27px;
    margin-top: -3px;
    padding-left: 0;
    padding-top: 0;
    width: 41px;
}

.products-list .tab-content-product-wrapper:hover .icons-container, .products-list .item:hover .icons-container {
    display: block;
}

.products-list .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    margin-right: 4px;
    margin-top: 3px;
}

@media (max-width: 1025px) {
    .products-list .item-price-box .nav-tabs {
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .products-list .item-price-box .nav-tabs {
        float: left;
    }
}

.products-list .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.products-list .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.products-list .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

.products-list .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.products-list .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

.products-list .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.products-list .item-price-box .nav-tabs li a:hover {
    background: none;
}

.products-list .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

.products-list .item-price-box .tab-content {
    margin-top: 0;
    padding-top: 0;
}

@media (min-width: 992px) {
    .products-list .item-price-box .tab-content {
        float: left;
        margin-bottom: 20px;
    }
}

.products-list .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

.products-list .item-price-box .regular-price .price {
    font-size: 24px;
}

.products-list .product-image img {
    width: 100%;
    height: auto;
}

.products-list .item:first-child {
    border-top: none;
}

.products-list .item, .products-list .row {
    margin-left: 0;
    margin-right: 0;
}

@media (min-width: 768px) {
    .products-list .item, .products-list .row {
        margin-left: -10px;
        margin-right: -10px;
    }
}

@media (max-width: 767px) {
    .products-list .item > div, .products-list .row > div {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    .products-list .item > div, .products-list .row > div {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.search-result-products.products-list a:hover h2, .offer-products.products-list a:hover h2, .replacement.products-list a:hover h2, .search-result-products.products-list a:hover p, .offer-products.products-list a:hover p, .replacement.products-list a:hover p {
    color: #000;
}

.search-result-products.products-list a:hover .order-material-number, .offer-products.products-list a:hover .order-material-number, .replacement.products-list a:hover .order-material-number {
    color: #747474;
}

.search-result-products.products-list .product-image img, .offer-products.products-list .product-image img, .replacement.products-list .product-image img {
    width: 100%;
}

@media (min-width: 768px) and (max-width: 991px) {
    .search-result-products.products-list .add-to-box, .offer-products.products-list .add-to-box, .replacement.products-list .add-to-box {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.search-result-products.products-grid, .offer-products.products-grid, .replacement.products-grid {
    margin-top: 30px;
}

.search-result-products.products-grid .item-price-box, .offer-products.products-grid .item-price-box, .replacement.products-grid .item-price-box {
    margin-bottom: 15px;
}

.search-result-products.products-grid .item-price-box .nav-tabs, .offer-products.products-grid .item-price-box .nav-tabs, .replacement.products-grid .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    float: left;
    margin-right: 4px;
    margin-top: 3px;
}

.search-result-products.products-grid .item-price-box .nav-tabs li, .offer-products.products-grid .item-price-box .nav-tabs li, .replacement.products-grid .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.search-result-products.products-grid .item-price-box .nav-tabs li:first-child, .offer-products.products-grid .item-price-box .nav-tabs li:first-child, .replacement.products-grid .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.search-result-products.products-grid .item-price-box .nav-tabs li.active, .offer-products.products-grid .item-price-box .nav-tabs li.active, .replacement.products-grid .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

.search-result-products.products-grid .item-price-box .nav-tabs li.active a, .offer-products.products-grid .item-price-box .nav-tabs li.active a, .replacement.products-grid .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.search-result-products.products-grid .item-price-box .nav-tabs li.no-padding-left a, .offer-products.products-grid .item-price-box .nav-tabs li.no-padding-left a, .replacement.products-grid .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

.search-result-products.products-grid .item-price-box .nav-tabs li a, .offer-products.products-grid .item-price-box .nav-tabs li a, .replacement.products-grid .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.search-result-products.products-grid .item-price-box .nav-tabs li a:hover, .offer-products.products-grid .item-price-box .nav-tabs li a:hover, .replacement.products-grid .item-price-box .nav-tabs li a:hover {
    background: none;
}

.search-result-products.products-grid .item-price-box .nav-tabs li a:after, .offer-products.products-grid .item-price-box .nav-tabs li a:after, .replacement.products-grid .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

.search-result-products.products-grid .item-price-box .tab-content, .offer-products.products-grid .item-price-box .tab-content, .replacement.products-grid .item-price-box .tab-content {
    padding-top: 20px;
}

.search-result-products.products-grid .item-price-box .regular-price, .offer-products.products-grid .item-price-box .regular-price, .replacement.products-grid .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    margin-top: 15px;
    margin-bottom: 15px;
}

.search-result-products.products-grid .item-price-box .regular-price .price, .offer-products.products-grid .item-price-box .regular-price .price, .replacement.products-grid .item-price-box .regular-price .price {
    font-size: 34px;
}

.search-result-products.products-grid .item-price-box .regular-price .icon, .offer-products.products-grid .item-price-box .regular-price .icon, .replacement.products-grid .item-price-box .regular-price .icon {
    font-size: 22px;
    width: 20px;
    height: 24px;
    top: 2px;
}

.search-result-products.products-grid h2, .offer-products.products-grid h2, .replacement.products-grid h2 {
    margin-bottom: 5px;
    clear: both;
}

.search-result-products.products-grid .order-material-number, .offer-products.products-grid .order-material-number, .replacement.products-grid .order-material-number {
    line-height: 18px;
}

.search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
    position: absolute;
    right: 15px;
    top: 0;
}

@media (min-width: 1025px) {
    .search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
        display: none;
    }
}

.search-result-products.products-grid .icons-container .add-to-cart-icon, .offer-products.products-grid .icons-container .add-to-cart-icon, .replacement.products-grid .icons-container .add-to-cart-icon {
    background: #bb2d21;
}

.search-result-products.products-grid .icons-container .shopping-list-icon, .offer-products.products-grid .icons-container .shopping-list-icon, .replacement.products-grid .icons-container .shopping-list-icon, .search-result-products.products-grid .icons-container .compare-icon, .offer-products.products-grid .icons-container .compare-icon, .replacement.products-grid .icons-container .compare-icon {
    background: #164194;
}

.search-result-products.products-grid .icons-container .icon, .offer-products.products-grid .icons-container .icon, .replacement.products-grid .icons-container .icon {
    color: #fff;
    font-size: 21px;
    padding-top: 0;
}

.search-result-products.products-grid .icons-container .add-to-icons, .offer-products.products-grid .icons-container .add-to-icons, .replacement.products-grid .icons-container .add-to-icons {
    margin-right: 1px;
}

.search-result-products.products-grid .icons-container .add-to-icons a, .offer-products.products-grid .icons-container .add-to-icons a, .replacement.products-grid .icons-container .add-to-icons a {
    display: block;
    height: 40px;
    padding-top: 10px;
    text-align: center;
    width: 40px;
}

.search-result-products.products-grid .item, .offer-products.products-grid .item, .replacement.products-grid .item {
    position: relative;
}

.search-result-products.products-grid .item:hover .icons-container, .offer-products.products-grid .item:hover .icons-container, .replacement.products-grid .item:hover .icons-container {
    display: block;
}

@media (max-width: 767px) {
    .search-result-products.products-grid, .offer-products.products-grid, .replacement.products-grid {
        margin: 0;
    }

    .search-result-products.products-grid .item, .offer-products.products-grid .item, .replacement.products-grid .item {
        padding-left: 0;
        padding-right: 0;
        border-top: 1px solid #c0c0c0;
    }

    .search-result-products.products-grid .item:first-child, .offer-products.products-grid .item:first-child, .replacement.products-grid .item:first-child {
        border: none;
    }
}

@media (max-width: 1025px) {
    .search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
        display: block;
    }
}

@media (max-width: 1023px) {
    .search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
        top: auto;
        bottom: 0;
        left: auto;
        right: 20px;
    }

    .search-result-products.products-grid .add-to-icons, .offer-products.products-grid .add-to-icons, .replacement.products-grid .add-to-icons {
        float: left;
    }
}

@media (max-width: 767px) {
    .search-result-products.products-grid .icons-container, .offer-products.products-grid .icons-container, .replacement.products-grid .icons-container {
        right: 0;
        bottom: 30px;
    }
}

@media (max-width: 767px) {
    .search-result-products.products-grid .item, .offer-products.products-grid .item, .replacement.products-grid .item {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .search-result-products.products-grid .item .row, .offer-products.products-grid .item .row, .replacement.products-grid .item .row {
        margin: 0;
    }

    .search-result-products.products-grid .item .add-to-box, .offer-products.products-grid .item .add-to-box, .replacement.products-grid .item .add-to-box {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    .search-result-products.products-grid .item, .offer-products.products-grid .item, .replacement.products-grid .item {
        margin-top: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .search-result-products.products-grid .item:nth-child(2n+1), .offer-products.products-grid .item:nth-child(2n+1), .replacement.products-grid .item:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .search-result-products.products-grid .item:nth-child(3n+1), .offer-products.products-grid .item:nth-child(3n+1), .replacement.products-grid .item:nth-child(3n+1) {
        clear: left;
    }
}

@media (min-width: 1200px) {
    .search-result-products.products-grid .item:nth-child(4n+1), .offer-products.products-grid .item:nth-child(4n+1), .replacement.products-grid .item:nth-child(4n+1) {
        clear: left;
    }
}

.search-result-products.products-grid a:hover h2, .offer-products.products-grid a:hover h2, .replacement.products-grid a:hover h2, .search-result-products.products-grid a:hover p, .offer-products.products-grid a:hover p, .replacement.products-grid a:hover p {
    color: #000;
}

.search-result-products.products-grid a:hover .order-material-number, .offer-products.products-grid a:hover .order-material-number, .replacement.products-grid a:hover .order-material-number {
    color: #747474;
}

.search-result-products .shadow-sortiment, .offer-products .shadow-sortiment, .replacement .shadow-sortiment {
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .search-result-products .shadow-sortiment .shadow-sortiment-options p, .offer-products .shadow-sortiment .shadow-sortiment-options p, .replacement .shadow-sortiment .shadow-sortiment-options p {
        margin-bottom: 0;
    }
}

.search-result-products .shadow-sortiment .product-image, .offer-products .shadow-sortiment .product-image, .replacement .shadow-sortiment .product-image {
    line-height: 1;
}

@media (max-width: 767px) {
    .search-result-products .shadow-sortiment .product-image, .offer-products .shadow-sortiment .product-image, .replacement .shadow-sortiment .product-image {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }
}

@media (min-width: 768px) {
    .search-result-products .shadow-sortiment .product-image, .offer-products .shadow-sortiment .product-image, .replacement .shadow-sortiment .product-image {
        position: absolute;
        top: 50%;
        left: -33.333%;
        width: 33.333%;
        text-align: center;
    }
}

.search-result-products .shadow-sortiment .col-sm-9, .offer-products .shadow-sortiment .col-sm-9, .replacement .shadow-sortiment .col-sm-9 {
    margin-bottom: 0;
}

.search-result-products .shadow-sortiment a, .offer-products .shadow-sortiment a, .replacement .shadow-sortiment a {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

.offer-section .offer-products.products-grid a:hover h2, .offer-section .offer-products.products-grid a:hover p {
    color: #000;
}

.offer-section .offer-products.products-grid a:hover .order-material-number {
    color: #747474;
}

.offer-section .offer-products.products-list {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.offer-section .offer-products.products-preview .offers {
    margin: 0 -10px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.offer-section .offer-products.products-preview .offer-single-product {
    max-width: 320px;
    padding: 0 10px 30px 10px;
    display: inline-block;
    float: none;
    white-space: normal;
    height: auto;
    vertical-align: top;
    width: 100%;
}

@media (min-width: 768px) {
    .offer-section .offer-products.products-preview .offer-single-product {
        max-width: 374px;
    }
}

@media (min-width: 1024px) {
    .offer-section .offer-products.products-preview .offer-single-product {
        max-width: 308px;
    }
}

@media (min-width: 1200px) {
    .offer-section .offer-products.products-preview .offer-single-product {
        max-width: 289px;
    }
}

.offer-section .view-mode strong, .offer-section .amount strong, .offer-section .sort-by strong, .offer-section .sort-direction strong {
    margin-right: 10px;
}

.offer-section .view-mode .select-wrapper-for-arrow select.form-control, .offer-section .amount .select-wrapper-for-arrow select.form-control, .offer-section .sort-by .select-wrapper-for-arrow select.form-control, .offer-section .sort-direction .select-wrapper-for-arrow select.form-control {
    background: #164194;
    color: #fff;
}

.offer-section .view-mode .select-wrapper-for-arrow:after, .offer-section .amount .select-wrapper-for-arrow:after, .offer-section .sort-by .select-wrapper-for-arrow:after, .offer-section .sort-direction .select-wrapper-for-arrow:after {
    color: #fff;
    padding-top: 0;
}

.offer-section .sort-direction select {
    width: 150px;
}

.offer-section .result-per-site-wrapper {
    width: 90px;
}

.offer-section .view-mode .icon {
    cursor: pointer;
    color: #fff;
    background: #164194;
    height: 40px;
    padding-left: 11px;
    padding-top: 11px;
    width: 40px;
    font-size: 19px;
}

.offer-section .view-mode .icon.active, .offer-section .view-mode .icon:hover {
    color: #164194;
    background: #fff;
}

.checkout-header {
    margin-top: 55px;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .checkout-header {
        margin-bottom: 30px;
    }
}

.checkout-header h1 {
    margin-bottom: 5px;
}

.checkout-header h1 .total-price {
    color: #bb2d21;
}

@media (max-width: 767px) {
    .checkout-header h1 .total-price {
        display: block;
    }
}

.checkout-header .product-qty-in-cart {
    color: #000;
}

.checkout-section {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .checkout-section {
        margin-bottom: 20px;
    }

    .checkout-section .products-list .product-image {
        text-align: center;
    }
}

.checkout-section .cart-step-header {
    margin-bottom: 0;
    background-color: #eaeaea;
    color: #747474;
    padding: 25px 0;
    position: relative;
}

@media (max-width: 991px) {
    .checkout-section .cart-step-header {
        padding: 24px 0;
    }
}

.checkout-section .cart-step-header a {
    color: #747474;
}

.checkout-section .cart-step-header a:hover {
    color: #000;
}

.checkout-section .checkout-section-content {
    padding-top: 45px;
    padding-bottom: 60px;
    display: none;
}

@media (max-width: 991px) {
    .checkout-section .checkout-section-content {
        padding-top: 30px;
        padding-bottom: 0;
    }

    .checkout-section .checkout-section-content .product-qty {
        margin-bottom: 30px;
    }
}

@media (max-width: 991px) {
    .checkout-section .checkout-section-items-content {
        margin: 0 auto;
    }
}

@media (max-width: 991px) {
    .checkout-section .checkout-section-items-content {
        max-width: 768px;
    }
}

@media (max-width: 767px) {
    .checkout-section .checkout-section-items-content {
        max-width: 551px;
    }
}

.checkout-section.active .cart-step-header {
    background-color: #eaeaea;
}

.checkout-section.active .cart-step-header a {
    color: #000;
}

.checkout-section.active .checkout-section-content {
    display: block;
}

.checkout-section.processed .checkout-section-content {
    display: none;
}

.checkout-section.processed .cart-step-header a:hover {
    color: #747474;
}

.checkout-section.processed .cart-step-header a:after {
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    line-height: 2;
    text-transform: none !important;
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    content: "\51";
    color: #bb2d21;
    margin-left: 10px;
}

.checkout-section .cart-table-header {
    margin-bottom: 10px;
}

.checkout-section .item {
    border-bottom: 1px solid #747474;
    padding-top: 30px;
}

.checkout-section .item:first-child {
    padding-top: 0;
}

@media (max-width: 991px) {
    .checkout-section .item {
        padding-bottom: 30px;
    }
}

.checkout-section .item .add-to-box {
    margin-top: -3px;
}

@media (max-width: 767px) {
    .checkout-section .item .add-to-box {
        margin-top: 22px;
        margin-bottom: 26px;
    }
}

.checkout-section .item .add-to-box .refresh-button {
    float: right;
    display: inline-block;
    margin-top: 8px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.checkout-section .item .add-to-box .refresh-button .icon {
    color: #bb2d21;
    margin-left: 5px;
}

.checkout-section .item .price-before-tax, .checkout-section .item .single-price {
    color: #bb2d21;
}

.checkout-section .item .price-after-tax {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

@media (max-width: 991px) {
    .checkout-section .item .price-after-tax {
        text-align: left;
    }
}

.checkout-section .item .price-after-tax .delete-item {
    float: right;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
}

@media (min-width: 992px) {
    .checkout-section .item .price-after-tax .delete-item {
        display: none;
    }
}

@media (max-width: 991px) {
    .checkout-section .item .price-before-tax, .checkout-section .item .additional-info, .checkout-section .item .price-after-tax {
        text-align: left;
    }
}

.checkout-section .item .availability-list {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.checkout-section .item .availability-list .title {
    margin-right: 15px;
}

.checkout-section .item .availability-list .green {
    color: #008a00;
}

.checkout-section .item .availability-list .yellow {
    color: #eb0;
}

.checkout-section .item .availability-list .red {
    color: #bb2d21;
}

.checkout-section .item .availability-list .availability-location {
    margin-right: 3px;
    position: relative;
    display: inline-block;
}

.checkout-section .item .availability-list .availability-location a {
    text-decoration: underline;
    display: inline-block;
    width: 55px;
}

.checkout-section .item .availability-list .availability-location a .icon {
    margin-left: 5px;
}

.checkout-section .item .availability-list .availability-location .green {
    color: #008a00;
}

.checkout-section .item .availability-list .availability-location .yellow {
    color: #eb0;
}

.checkout-section .item .availability-list .availability-location .red {
    color: #bb2d21;
}

.checkout-section .item .availability-list .availability-location:hover .availability-location-tooltip {
    visibility: visible;
}

.checkout-section .item .availability-list .availability-location-tooltip {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    background-color: #eaeaea;
    color: #000;
    left: -115px;
    padding: 15px 15px;
    position: absolute;
    top: 55px;
    visibility: hidden;
    width: 250px;
    z-index: 1;
}

.checkout-section .item .availability-list .availability-location-tooltip:after {
    border-color: transparent transparent #eaeaea;
    border-style: solid;
    border-width: 0 25px 20px;
    content: "";
    display: block;
    height: 0;
    left: 100px;
    position: absolute;
    top: -20px;
    width: 0;
}

@media (min-width: 1200px) {
    .checkout-section .item .availability-list .availability-location-tooltip strong {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
        font-size: 18px;
    }
}

.checkout-section .item .availability-list .availability-location-tooltip .icon {
    position: absolute;
    right: 15px;
    top: 15px;
}

.checkout-section .item .availability-and-delete {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
}

.checkout-section .item .order-material-number {
    margin-bottom: 90px;
}

@media (max-width: 991px) {
    .checkout-section .item .order-material-number {
        margin-bottom: 30px;
    }
}

.checkout-section .item .additional-info {
    color: #747474;
    line-height: 18px;
}

.checkout-section .summary {
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .checkout-section .summary {
        margin-bottom: 0;
    }
}

.checkout-section .summary .refresh-button {
    display: inline-block;
    margin-top: 20px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.checkout-section .summary .refresh-button .icon {
    color: #bb2d21;
    margin-left: 5px;
}

.checkout-section .summary .price-summary {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.checkout-section .summary .price-summary .subtotal-price, .checkout-section .summary .price-summary .total-price {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
}

.checkout-section .summary .price-summary .subtotal {
    color: #bb2d21;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .checkout-section .summary .price-summary {
        text-align: left;
    }
}

.checkout-section .action-button {
    margin-top: 30px;
    margin-bottom: 10px;
}

.checkout-section .action-button .btn {
    width: 220px;
}

@media (max-width: 991px) {
    .checkout-section.cart .price-before-tax {
        margin-bottom: 0;
    }
}

@media (max-width: 767px) {
    .shipping-address-section.checkout-section .checkout-section-content {
        padding-top: 20px;
    }
}

.shipping-address-section .shipping-address {
    border-bottom: 1px solid #747474;
    padding-top: 35px;
    padding-bottom: 35px;
}

@media (max-width: 767px) {
    .shipping-address-section .shipping-address {
        padding-top: 36px;
        padding-bottom: 44px;
    }
}

.shipping-address-section .shipping-address label {
    display: block;
    float: left;
    margin-right: 15px;
    position: relative;
    top: 10px;
}

@media (max-width: 767px) {
    .shipping-address-section .shipping-address label {
        top: 4px;
    }
}

.shipping-address-section .shipping-address .shipping-info {
    color: #747474;
    display: none;
}

.shipping-address-section .shipping-address .shipping-info p {
    padding-left: 55px;
    margin-top: 30px;
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .customer-name, .shipping-address-section .shipping-address .company-name {
        margin-left: 55px;
    }
}

@media (max-width: 767px) {
    .shipping-address-section .shipping-address .change-address-street, .shipping-address-section .shipping-address .change-address-country {
        margin-left: 55px;
    }
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .change-address-wrapper {
        margin-left: 55px;
    }
}

.shipping-address-section .shipping-address .change-address-wrapper {
    position: relative;
}

.shipping-address-section .shipping-address .change-address-wrapper:hover .change-address-contact {
    display: block;
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .change-address-wrapper {
        text-align: left;
    }
}

.shipping-address-section .shipping-address .change-address {
    display: inline-block;
    margin-top: 9px;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    position: relative;
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .change-address {
        margin-top: 30px;
    }
}

.shipping-address-section .shipping-address .change-address .icon {
    color: #747474;
    margin-left: 5px;
}

.shipping-address-section .shipping-address .change-address-contact {
    background: #eaeaea;
    color: #000;
    padding: 25px 15px 40px;
    position: absolute;
    right: 15px;
    top: 60px;
    width: 300px;
    z-index: 1000;
    text-align: left;
    display: none;
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .change-address-contact {
        top: 80px;
        left: -35px;
        right: auto;
        width: 280px;
    }
}

.shipping-address-section .shipping-address .change-address-contact .winkler-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

.shipping-address-section .shipping-address .change-address-contact p {
    margin-bottom: 30px;
}

.shipping-address-section .shipping-address .change-address-contact h1 {
    line-height: 40px;
    margin-bottom: 30px;
    text-align: left;
}

.shipping-address-section .shipping-address .change-address-contact h4 {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 20px;
    margin-bottom: 10px;
}

.shipping-address-section .shipping-address .change-address-contact a .icon {
    color: #164194;
    margin-left: 5px;
}

.shipping-address-section .shipping-address .change-address-contact a:hover .icon {
    color: #bb2d21;
}

.shipping-address-section .shipping-address .change-address-contact:after {
    border-color: transparent transparent #eaeaea;
    border-style: solid;
    border-width: 0 25px 20px;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    top: -20px;
    width: 0;
    right: 70px;
}

@media (max-width: 991px) {
    .shipping-address-section .shipping-address .change-address-contact:after {
        left: 110px;
        right: auto;
    }
}

.shipping-address-section .shipping-address.active .shipping-info {
    display: block;
}

.shipping-address-section .shipping-info {
    color: #747474;
}

.shipping-address-section .add-address {
    margin-top: 45px;
}

.shipping-address-section .add-address a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shipping-address-section .add-address a .icon {
    color: #bb2d21;
}

.shipping-address-section .add-new-address .shipping-info {
    margin-top: 30px;
}

@media (max-width: 767px) {
    .shipping-address-section .add-new-address .add-new-address-column {
        margin-bottom: 39px;
    }
}

.shipping-address-section .add-new-address .new-address-form {
    margin-top: 45px;
    padding-bottom: 2px;
}

@media (min-width: 768px) {
    .shipping-address-section .add-new-address .new-address-form {
        border-bottom: 1px solid #747474;
        padding-bottom: 20px;
    }
}

.shipping-address-section .add-new-address .new-address-form .custom-radio-button {
    margin-right: 15px;
    vertical-align: middle;
}

.shipping-address-section .add-new-address .new-address-form .control-label {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shipping-address-section .add-new-address .new-address-form .new-address-label {
    vertical-align: middle;
}

@media (min-width: 768px) {
    .shipping-address-section .add-new-address .new-address-form .new-address-label {
        display: none;
    }
}

.shipping-address-section .add-new-address .form-horizontal > .row {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .shipping-address-section .add-new-address .form-horizontal > .row {
        margin-bottom: 23px;
    }
}

.shipping-address-section .add-new-address .form-horizontal .control-label {
    text-align: left;
}

.shipping-address-section .add-new-address .form-horizontal .new-address-actions {
    margin-top: 30px;
}

.shipping-address-section .add-new-address .form-horizontal .new-address-actions .cancel {
    margin-right: 27px;
}

.shipping-address-section .add-new-address .form-horizontal .new-address-actions .save-new-address {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shipping-address-section .add-new-address .form-horizontal .new-address-actions .save-new-address .icon {
    color: #bb2d21;
}

@media (max-width: 767px) {
    .checkout-section .action-button .btn {
        width: 100%;
    }

    .checkout-section .action-button .btn-gray {
        margin-bottom: 15px;
    }
}

.custom-radio-button {
    cursor: pointer;
    position: relative;
}

.shipping-section .shipping-options label, .shipping-section .shipping-options .offset-label {
    display: inline-block;
    line-height: 1.26;
    vertical-align: middle;
}

@media (max-width: 400px) {
    .shipping-section .shipping-options .offset-label {
        max-width: 160px;
    }
}

.shipping-section .shipping-options li {
    margin-bottom: 20px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    position: relative;
}

.shipping-section .shipping-options li label {
    cursor: pointer;
    position: relative;
}

.shipping-section .shipping-options li .icon.shipping-icon {
    font-size: 36px;
    width: 41px;
    color: #bb2d21;
    height: 41px;
    margin-right: 4px;
    margin-top: 0;
}

.shipping-section .shipping-options li .styled-radio {
    margin-right: 15px;
}

@media (max-width: 767px) {
    .shipping-section .shipping-options li .styled-radio {
        margin-right: 8px;
    }
}

.shipping-section .shipping-options li.inactive {
    color: #eaeaea;
}

.shipping-section .shipping-options li.inactive .shipping-icon {
    color: #eaeaea;
}

.shipping-section .shipping-options li.inactive .shipping-failure-info {
    max-width: 700px;
    background: #eaeaea;
    color: #000;
    padding: 45px 15px;
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 1;
    display: none;
}

@media (min-width: 768px) {
    .shipping-section .shipping-options li.inactive .shipping-failure-info {
        width: 700px;
    }
}

@media (max-width: 767px) {
    .shipping-section .shipping-options li.inactive .shipping-failure-info {
        padding-top: 40px;
        width: auto;
    }
}

.shipping-section .shipping-options li.inactive .shipping-failure-info .winkler-close {
    position: absolute;
    right: 15px;
    top: 15px;
}

@media (max-width: 767px) {
    .shipping-section .shipping-options li.inactive .shipping-failure-info .winkler-close {
        font-size: 15px;
        right: 5px;
        top: 5px;
    }
}

.shipping-section .shipping-options li.inactive .shipping-failure-info h4 {
    font-size: 18px;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .shipping-section .shipping-options li.inactive .shipping-failure-info h4 {
        line-height: 1.26;
        margin-bottom: 34px;
    }
}

.shipping-section .shipping-options li.inactive .shipping-failure-info p {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
}

.shipping-section .shipping-options li.inactive .shipping-failure-info .item-outer {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 15px 0;
    margin-top: 5px;
}

@media (max-width: 767px) {
    .shipping-section .shipping-options li.inactive .shipping-failure-info .item-outer {
        padding-top: 12px;
        padding-bottom: 17px;
    }
}

.shipping-section .shipping-options li.inactive .shipping-failure-info a .icon {
    color: #bb2d21;
}

.shipping-section .shipping-options li.inactive .shipping-failure-info:after {
    border-color: transparent transparent #eaeaea;
    border-style: solid;
    border-width: 0 25px 20px;
    content: "";
    display: block;
    height: 0;
    left: 130px;
    position: absolute;
    top: -20px;
    width: 0;
}

.shipping-section .shipping-options li.inactive .shipping-failure-info.active {
    display: block;
}

.shipping-section .shipping-options li.inactive .custom-radio-button .winkler-info {
    top: 6px;
}

.shipping-section .add-comment {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    display: inline-block;
}

@media (max-width: 767px) {
    .shipping-section .add-comment {
        margin-top: 10px;
    }
}

@media (min-width: 768px) {
    .shipping-section .add-comment {
        margin-top: 145px;
    }
}

.shipping-section .add-comment .icon {
    color: #bb2d21;
}

.shipping-section .comment {
    display: none;
}

.shipping-section .comment p {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .shipping-section .comment p {
        margin-bottom: 24px;
    }
}

.shipping-section .comment textarea {
    background: #eaeaea;
    border-color: #eaeaea;
    color: #000;
    border-radius: 0;
    box-shadow: none;
}

@media (max-width: 767px) {
    .shipping-section .comment textarea {
        height: 159px;
        margin-bottom: 30px;
    }
}

.shipping-section .comment .action-button {
    margin-top: 20px;
}

@media (max-width: 991px) {
    .shipping-section .comment .action-button {
        display: none;
    }
}

.shipping-section .comment .action-button .cancel {
    color: #747474;
}

.shipping-section .comment .action-button .save-comment {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    margin-left: 20px;
}

.shipping-section .comment .action-button .save-comment .icon {
    color: #bb2d21;
}

.shipping-section .comment .action-button .change-comment {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shipping-section .comment .action-button .change-comment .icon {
    color: #bb2d21;
}

.shipping-section .comment .comment-form.saved {
    display: none;
}

.shipping-section .active-comment .add-comment {
    display: none;
}

.shipping-section .active-comment .comment {
    display: block;
}

.shipping-section .saved-comment .add-comment {
    display: none;
}

.shipping-section .saved-comment .comment {
    display: block;
}

.shipping-section .saved-comment .comment .comment-form {
    display: none;
}

.shipping-section .saved-comment .comment .comment-form.saved {
    display: block;
}

.payment-section .payment-options li {
    margin-bottom: 21px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    position: relative;
}

.payment-section .payment-options li label {
    cursor: pointer;
    position: relative;
}

.payment-section .payment-options li .icon.shipping-icon {
    font-size: 41px;
    width: 41px;
    color: #bb2d21;
    height: 41px;
    margin-right: 10px;
    margin-top: -7px;
}

.payment-section .payment-options li .styled-radio {
    margin-right: 15px;
}

.payment-section .payment-options li .offset-label {
    display: inline-block;
    line-height: 1.26;
    vertical-align: middle;
}

@media (max-width: 400px) {
    .payment-section .payment-options li .offset-label {
        max-width: 210px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .payment-section .payment-options li .offset-label {
        max-width: 275px;
    }
}

.payment-section .billing-address .billing-title {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .payment-section .billing-address .billing-title {
        margin-top: 0;
    }
}

@media (max-width: 991px) {
    .payment-section .voucher {
        margin-top: 20px;
    }
}

.payment-section .voucher a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    display: inline-block;
}

@media (min-width: 992px) {
    .payment-section .voucher a {
        margin-top: 75px;
    }
}

.payment-section .voucher a .icon {
    color: #bb2d21;
}

.payment-section .voucher .icon {
    margin-left: 3px;
}

.payment-section .voucher .voucher-code-wrapper {
    display: none;
}

.payment-section .voucher .voucher-code-wrapper strong {
    display: inline-block;
    margin-bottom: 20px;
}

.payment-section .voucher .voucher-code-wrapper .invalid-voucher-code {
    color: #bb2d21;
    margin-bottom: 10px;
}

.payment-section .voucher .voucher-code-wrapper a {
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    margin-top: 20px;
}

.payment-section .voucher .voucher-code-wrapper.invalid input.form-control {
    border: 1px solid #bb2d21;
    -webkit-box-shadow: 0 0 5px 0 #bb2d21;
    -moz-box-shadow: 0 0 5px 0 #bb2d21;
    box-shadow: 0 0 1px 0 #bb2d21;
}

.payment-section .voucher.active .voucher-link {
    display: none;
}

.payment-section .voucher.active .voucher-code-wrapper {
    display: block;
}

.payment-section .voucher.active .voucher-code-wrapper .invalid-voucher-code {
    display: none;
}

.payment-section .voucher.active .voucher-code-wrapper.invalid .invalid-voucher-code {
    display: inline-block;
}

.confirmation-section {
    margin-bottom: 90px;
}

.confirmation-section .item {
    position: relative;
}

.confirmation-section .shipping-and-billing {
    padding-top: 30px;
    position: relative;
}

@media (min-width: 992px) {
    .confirmation-section .shipping-and-billing .payment-method, .confirmation-section .shipping-and-billing .shipping-method {
        min-height: 40px;
    }
}

.confirmation-section .shipping-and-billing strong {
    display: inline-block;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .confirmation-section .shipping-and-billing strong {
        margin-bottom: 30px;
    }
}

.confirmation-section .shipping-and-billing .shipping-address .address {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .confirmation-section .shipping-and-billing .shipping-address .address {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .confirmation-section .shipping-and-billing .billing-address {
        margin-top: 30px;
    }
}

.confirmation-section .shipping-and-billing .billing-address .address {
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .confirmation-section .shipping-and-billing .billing-address .address {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .confirmation-section .shipping-and-billing .billing-address .address {
        margin-top: 20px;
    }
}

.confirmation-section .shipping-and-billing .shipping-icon {
    color: #bb2d21;
    font-size: 41px;
    height: 41px;
    margin-right: 10px;
    margin-top: -7px;
    width: 41px;
}

@media (max-width: 991px) {
    .confirmation-section .shipping-and-billing .col-lg-3.col-md-3 {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .confirmation-section .summary-wrapper {
        position: static;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .confirmation-section .summary-wrapper {
        margin-bottom: 30px;
    }
}

@media (min-width: 992px) {
    .confirmation-section .summary-wrapper {
        bottom: 0;
        position: absolute;
        right: 0;
    }
}

.confirmation-section .summary-wrapper .summary .price-summary .subtotal {
    margin-bottom: 0;
}

.confirmation-section .summary-wrapper .summary .price-summary .total a {
    margin-right: 10px;
}

.confirmation-section .summary-wrapper .summary .voucher-summary {
    margin-top: 20px;
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .confirmation-section .conditions-container {
        margin-top: 60px;
    }
}

.confirmation-section .conditions-container .styled-checkbox {
    position: absolute;
    top: 0;
    left: 0;
}

.confirmation-section .conditions-container .checkbox {
    margin-bottom: 30px;
    min-height: 40px;
}

.confirmation-section .conditions-container .conditions-message {
    margin-left: 55px;
}

.confirmation-section .cart-qty {
    display: inline-block;
    margin-right: 20px;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.confirmation-section.checkout-section .item .add-to-box {
    margin-top: 5px;
}

.confirmation-section .btn {
    padding: 7px 0;
    width: 220px;
}

.conditions-message .winkler-info {
    font-size: 18px;
    margin-left: 4px;
    padding-top: 2px;
    cursor: pointer;
    cursor: hand;
    color: #747474;
}

@media (min-width: 992px) {
    .conditions-message .winkler-info {
        display: none;
    }
}

@media (max-width: 991px) {
    .conditions-message .legal-link {
        text-decoration: underline;
    }
}

.confirmation {
    margin-top: 55px;
}

.confirmation .continue-shopping {
    margin-top: 15px;
}

.confirmation .continue-shopping a .icon {
    color: #bb2d21;
}

.cart .item-price-box {
    margin-bottom: 15px;
}

.cart .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    float: left;
    margin-right: 4px;
    margin-top: 3px;
}

.cart .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.cart .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.cart .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

.cart .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.cart .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

.cart .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.cart .item-price-box .nav-tabs li a:hover {
    background: none;
}

.cart .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

.cart .item-price-box .tab-content {
    padding-top: 20px;
}

.cart .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cart .item-price-box .regular-price .price {
    font-size: 34px;
}

.cart .item-price-box .regular-price .icon {
    font-size: 22px;
    width: 20px;
    height: 24px;
    top: 2px;
}

.cart h2 {
    margin-bottom: 5px;
    clear: both;
}

.cart .order-material-number {
    line-height: 18px;
}

.cart .icons-container {
    position: absolute;
    right: 15px;
    top: 0;
}

@media (min-width: 1025px) {
    .cart .icons-container {
        display: none;
    }
}

.cart .icons-container .add-to-cart-icon {
    background: #bb2d21;
}

.cart .icons-container .shopping-list-icon, .cart .icons-container .compare-icon {
    background: #164194;
}

.cart .icons-container .icon {
    color: #fff;
    font-size: 21px;
    padding-top: 0;
}

.cart .icons-container .add-to-icons {
    margin-right: 1px;
}

.cart .icons-container .add-to-icons a {
    display: block;
    height: 40px;
    padding-top: 10px;
    text-align: center;
    width: 40px;
}

.cart .item {
    position: relative;
}

.cart .item:hover .icons-container {
    display: block;
}

.text-green {
    color: #008a00;
    padding-top: 30px;
    font-weight: bold;
}

#loading {
    margin: 65px auto 0;
    text-align: center;
}

@media (max-width: 1239px) {
    header .winkler-logo-container .logo-rectangle-background {
        width: 170px;
    }

    header .winkler-logo-container .logo-rectangle-background .logo {
        left: 43px;
    }

    header .winkler-logo-container .logo-triangle-background {
        left: 170px;
    }

    header #header-menu-container {
        left: 240px;
    }

    .advantage-list-register.row {
        padding: 0 30px;
    }

    .loggedin.header-image.search-header .page-title {
        text-align: center;
        width: 100%;
        margin-top: 40px;
        z-index: 10;
    }

    .loggedin.header-image.search-header .page-title.active {
        position: absolute;
        text-align: center;
        color: #fff;
        width: 100%;
        left: 50%;
        margin-left: -360px;
        max-width: 720px;
        margin-top: 0;
        top: 130px;
        z-index: 1002;
    }
}

.login-section .register-link .btn {
    margin-top: 25px;
}

@media (max-width: 1023px) {
    .nav-tabs > li .tab-label {
        top: 0;
    }

    .circle {
        margin: 0 auto 5px auto;
        display: block;
    }

    .target-group-links.container-fluid {
        bottom: 5px;
    }

    .target-group-links.container-fluid .target-group a .icon {
        display: block;
        margin: 0 auto;
    }

    .offer-section .tab-products .offer-single-product h2.product-name, .offer-section .offer-products .offer-single-product h2.product-name {
        hyphens: auto;
        word-wrap: break-word;
    }

    .offer-section .tab-products .offer-single-product .price-wrap, .offer-section .offer-products .offer-single-product .price-wrap {
        width: 100%;
        display: inline-block;
    }

    .product-detail-overview .zoom-container {
        height: 400px;
        width: 400px;
        right: 75px;
    }

    .register-benefits {
        margin-top: 60px;
    }

    .register-top-section .register-top-text {
        padding: 0 20px;
    }

    .password-forgotten {
        margin-top: 55px;
    }

    .password-forgotten .text-center {
        padding: 0 5px;
    }

    .loggedin.header-image .page-title .search-field-wrapper .search-field {
        background-color: #fff;
        border: 1px solid #fff;
    }

    .loggedin.header-image .page-title.active {
        top: 120px;
    }

    .loggedin.header-image .page-title.active .search-field-wrapper .search-field {
        background-color: #fff;
        border: 1px solid #fff;
    }

    .loggedin .breadcrumbs {
        padding-top: 20px;
        clear: both;
        background: #fff;
    }

    .filter-container .active-filters {
        padding: 0 5px;
    }

    .required-products-overlay .required-products {
        position: relative;
    }

    .required-products-overlay .required-products .action-buttons .buttons {
        padding-right: 10px;
    }

    .required-products-overlay .required-products h2 {
        margin-bottom: 0;
    }

    .required-products-overlay .required-products p {
        margin-bottom: 10px;
    }

    .required-products-overlay .required-products .product-price .tax-details {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    }

    .required-products-overlay .required-products .product-price .regular-price {
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        font-size: 24px;
        color: #bb2d21;
    }

    .required-products-overlay .required-products .add-to-box {
        margin-top: -4px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper, .required-products-overlay .required-products .item {
        padding-top: 30px;
        padding-bottom: 5px;
        border-top: 1px solid #747474;
        margin-left: 0;
        margin-right: 0;
        clear: both;
        position: relative;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .col-sm-9, .required-products-overlay .required-products .item .col-sm-9 {
        margin-bottom: 25px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        display: block;
        position: absolute;
        right: 0;
        bottom: 30px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons, .required-products-overlay .required-products .item .icons-container .add-to-icons {
        float: left;
        margin-right: 1px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons a, .required-products-overlay .required-products .item .icons-container .add-to-icons a {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 10px;
        margin-bottom: 1px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons .icon, .required-products-overlay .required-products .item .icons-container .add-to-icons .icon {
        font-size: 21px;
        color: #fff;
        padding-top: 0;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.add-to-cart-icon {
        background: #bb2d21;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.compare-icon, .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.shopping-list-icon {
        background: #164194;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.compare-icon .icon {
        font-size: 27px;
        margin-top: -3px;
        padding-left: 0;
        padding-top: 0;
        width: 41px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper:hover .icons-container, .required-products-overlay .required-products .item:hover .icons-container {
        display: block;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs {
        border: none;
        text-align: left;
        margin-right: 4px;
        margin-top: 3px;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li {
        color: #747474;
        text-decoration: underline;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li:first-child {
        border-right: 1px solid #747474;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li.active {
        text-decoration: none;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li.active a {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
        color: #000;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li.no-padding-left a {
        padding-left: 0;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li a {
        color: #747474;
        border: none;
        padding-bottom: 0;
        padding-top: 0;
        background: none;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li a:hover {
        background: none;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li a:after {
        content: "";
        display: none;
    }

    .required-products-overlay .required-products .item-price-box .tab-content {
        margin-top: 0;
        padding-top: 0;
    }

    .required-products-overlay .required-products .item-price-box .regular-price {
        color: #bb2d21;
        font-size: 24px;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    }

    .required-products-overlay .required-products .item-price-box .regular-price .price {
        font-size: 24px;
    }

    .required-products-overlay .required-products .product-image img {
        width: 100%;
        height: auto;
    }

    .required-products-overlay .required-products .product-price {
        margin-top: 20px;
    }

    .confirmation .item .order-material-number {
        margin-bottom: 90px;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .required-products-overlay .required-products .product-price {
        float: left;
    }
}

@media (max-width: 1023px) and (max-width: 767px) {
    .required-products-overlay .required-products .product-price .regular-price {
        margin-bottom: 30px;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper, .required-products-overlay .required-products .item {
        padding-top: 40px;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        top: 40px;
        bottom: auto;
    }
}

@media (max-width: 1023px) and (min-width: 1025px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        display: none;
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons, .required-products-overlay .required-products .item .icons-container .add-to-icons {
        float: none;
        margin-right: 0;
        margin-bottom: 1px;
    }
}

@media (max-width: 1023px) and (max-width: 1025px) {
    .required-products-overlay .required-products .item-price-box .nav-tabs {
        margin-bottom: 20px;
    }
}

@media (max-width: 1023px) and (min-width: 992px) {
    .required-products-overlay .required-products .item-price-box .nav-tabs {
        float: left;
    }
}

@media (max-width: 1023px) and (min-width: 992px) {
    .required-products-overlay .required-products .item-price-box .tab-content {
        float: left;
        margin-bottom: 20px;
    }
}

@media (max-width: 991px) {
    .loggedin.header-image.search-header .page-title {
        margin-top: 0;
    }

    .loggedin.header-image.search-header .page-title .search-field-wrapper .search-field {
        background-color: #fff;
        border: 1px solid #fff;
    }

    .filter-container {
        margin-top: 0;
    }

    .target-group-links.container-fluid .target-group {
        text-align: center;
        float: left;
    }

    .target-group-links.container-fluid .target-group a {
        display: inline-block;
    }

    .target-group-links.container-fluid .target-group a .icon {
        padding-top: 5px;
    }
}

@media (max-width: 767px) {
    .filter-container {
        margin-top: 0;
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .filter-container .container {
        width: 100%;
    }

    .filter-container .show-filters {
        display: block;
        margin-top: 24px;
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    }

    .filter-container .show-filters .display-filters-message {
        display: none;
    }

    .filter-container .show-filters .hide-filters-message {
        display: inline;
    }

    .filter-container .show-filters.collapsed .display-filters-message {
        display: inline;
    }

    .filter-container .show-filters.collapsed .hide-filters-message {
        display: none;
    }

    .filter-container .category-row, .filter-container .filters {
        display: none;
    }

    .filter-container .category-row.active, .filter-container .filters.active {
        display: block;
    }

    .filter-container .category-row {
        margin-top: 30px;
    }

    .filter-container .filter-row {
        margin-bottom: 0;
    }

    .filter-container .filter-row .filter-dropdown-wrapper {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }

    .filter-container .filter-row.row-3 .col-sm-3:nth-child(4) {
        z-index: 1;
    }

    .filter-container .filter-row.row-3 .col-sm-3:nth-child(3) {
        z-index: 2;
    }

    .filter-container .filter-row.row-3 .col-sm-3:nth-child(2) {
        z-index: 3;
    }

    .filter-container .filter-row.row-3 .col-sm-3:nth-child(1) {
        z-index: 4;
    }

    .filter-container .filter-row.row-2 .col-sm-3:nth-child(4) {
        z-index: 5;
    }

    .filter-container .filter-row.row-2 .col-sm-3:nth-child(3) {
        z-index: 6;
    }

    .filter-container .filter-row.row-2 .col-sm-3:nth-child(2) {
        z-index: 7;
    }

    .filter-container .filter-row.row-2 .col-sm-3:nth-child(1) {
        z-index: 8;
    }

    .filter-container .filter-row.row-1 .col-sm-3:nth-child(4) {
        z-index: 9;
    }

    .filter-container .filter-row.row-1 .col-sm-3:nth-child(3) {
        z-index: 10;
    }

    .filter-container .filter-row.row-1 .col-sm-3:nth-child(2) {
        z-index: 11;
    }

    .filter-container .filter-row.row-1 .col-sm-3:nth-child(1) {
        z-index: 12;
    }

    .filter-container #category-dropdown {
        height: 320px;
        overflow: auto;
    }

    .filter-container .category-dropdown-wrapper p {
        background: #fff none repeat scroll 0 0;
    }
}

@media (max-width: 767px) {
    .products-grid-configuration .item-price-box {
        margin-bottom: 15px;
    }

    .products-grid-configuration .item-price-box .nav-tabs {
        border: none;
        text-align: left;
        float: left;
        margin-right: 4px;
        margin-top: 3px;
    }

    .products-grid-configuration .item-price-box .nav-tabs li {
        color: #747474;
        text-decoration: underline;
    }

    .products-grid-configuration .item-price-box .nav-tabs li:first-child {
        border-right: 1px solid #747474;
    }

    .products-grid-configuration .item-price-box .nav-tabs li.active {
        text-decoration: none;
    }

    .products-grid-configuration .item-price-box .nav-tabs li.active a {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
        color: #000;
    }

    .products-grid-configuration .item-price-box .nav-tabs li.no-padding-left a {
        padding-left: 0;
    }

    .products-grid-configuration .item-price-box .nav-tabs li a {
        color: #747474;
        border: none;
        padding-bottom: 0;
        padding-top: 0;
        background: none;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    .products-grid-configuration .item-price-box .nav-tabs li a:hover {
        background: none;
    }

    .products-grid-configuration .item-price-box .nav-tabs li a:after {
        content: "";
        display: none;
    }

    .products-grid-configuration .item-price-box .tab-content {
        padding-top: 20px;
    }

    .products-grid-configuration .item-price-box .regular-price {
        color: #bb2d21;
        font-size: 24px;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .products-grid-configuration .item-price-box .regular-price .price {
        font-size: 34px;
    }

    .products-grid-configuration .item-price-box .regular-price .icon {
        font-size: 22px;
        width: 20px;
        height: 24px;
        top: 2px;
    }

    .products-grid-configuration h2 {
        margin-bottom: 5px;
        clear: both;
    }

    .products-grid-configuration .order-material-number {
        line-height: 18px;
    }

    .products-grid-configuration .icons-container {
        display: none;
        position: absolute;
        right: 15px;
        top: 0;
    }

    .products-grid-configuration .icons-container .add-to-cart-icon {
        background: #bb2d21;
    }

    .products-grid-configuration .icons-container .shopping-list-icon, .products-grid-configuration .icons-container .compare-icon {
        background: #164194;
    }

    .products-grid-configuration .icons-container .icon {
        color: #fff;
        font-size: 21px;
        padding-top: 0;
    }

    .products-grid-configuration .icons-container .add-to-icons {
        margin-right: 1px;
    }

    .products-grid-configuration .icons-container .add-to-icons a {
        display: block;
        height: 40px;
        padding-top: 10px;
        text-align: center;
        width: 40px;
    }

    .products-grid-configuration .item {
        position: relative;
    }

    .products-grid-configuration .item:hover .icons-container {
        display: block;
    }

    .required-products-overlay .required-products {
        position: relative;
    }

    .required-products-overlay .required-products h2 {
        margin-bottom: 0;
    }

    .required-products-overlay .required-products p {
        margin-bottom: 10px;
    }

    .required-products-overlay .required-products .product-price .tax-details {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    }

    .required-products-overlay .required-products .product-price .regular-price {
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        font-size: 24px;
        color: #bb2d21;
    }

    .required-products-overlay .required-products .add-to-box {
        margin-top: -4px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper, .required-products-overlay .required-products .item {
        padding-top: 30px;
        padding-bottom: 5px;
        border-top: 1px solid #747474;
        margin-left: 0;
        margin-right: 0;
        clear: both;
        position: relative;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .col-sm-9, .required-products-overlay .required-products .item .col-sm-9 {
        margin-bottom: 25px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        display: block;
        position: absolute;
        right: 0;
        bottom: 30px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons, .required-products-overlay .required-products .item .icons-container .add-to-icons {
        float: left;
        margin-right: 1px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons a, .required-products-overlay .required-products .item .icons-container .add-to-icons a {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        padding-top: 10px;
        margin-bottom: 1px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons .icon, .required-products-overlay .required-products .item .icons-container .add-to-icons .icon {
        font-size: 21px;
        color: #fff;
        padding-top: 0;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.add-to-cart-icon {
        background: #bb2d21;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.compare-icon, .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.shopping-list-icon {
        background: #164194;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, .required-products-overlay .required-products .item .icons-container .add-to-icons.compare-icon .icon {
        font-size: 27px;
        margin-top: -3px;
        padding-left: 0;
        padding-top: 0;
        width: 41px;
    }

    .required-products-overlay .required-products .tab-content-product-wrapper:hover .icons-container, .required-products-overlay .required-products .item:hover .icons-container {
        display: block;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs {
        border: none;
        text-align: left;
        margin-right: 4px;
        margin-top: 3px;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li {
        color: #747474;
        text-decoration: underline;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li:first-child {
        border-right: 1px solid #747474;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li.active {
        text-decoration: none;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li.active a {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
        color: #000;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li.no-padding-left a {
        padding-left: 0;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li a {
        color: #747474;
        border: none;
        padding-bottom: 0;
        padding-top: 0;
        background: none;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li a:hover {
        background: none;
    }

    .required-products-overlay .required-products .item-price-box .nav-tabs li a:after {
        content: "";
        display: none;
    }

    .required-products-overlay .required-products .item-price-box .tab-content {
        margin-top: 0;
        padding-top: 0;
    }

    .required-products-overlay .required-products .item-price-box .regular-price {
        color: #bb2d21;
        font-size: 24px;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    }

    .required-products-overlay .required-products .item-price-box .regular-price .price {
        font-size: 24px;
    }

    .required-products-overlay .required-products .product-image img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 767px) and (min-width: 768px) {
    .required-products-overlay .required-products .product-price {
        float: left;
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .required-products-overlay .required-products .product-price .regular-price {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) and (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper, .required-products-overlay .required-products .item {
        padding-top: 40px;
    }
}

@media (max-width: 767px) and (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        top: 40px;
        bottom: auto;
    }
}

@media (max-width: 767px) and (min-width: 1025px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container, .required-products-overlay .required-products .item .icons-container {
        display: none;
    }
}

@media (max-width: 767px) and (min-width: 768px) {
    .required-products-overlay .required-products .tab-content-product-wrapper .icons-container .add-to-icons, .required-products-overlay .required-products .item .icons-container .add-to-icons {
        float: none;
        margin-right: 0;
        margin-bottom: 1px;
    }
}

@media (max-width: 767px) and (max-width: 1025px) {
    .required-products-overlay .required-products .item-price-box .nav-tabs {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) and (min-width: 992px) {
    .required-products-overlay .required-products .item-price-box .nav-tabs {
        float: left;
    }
}

@media (max-width: 767px) and (min-width: 992px) {
    .required-products-overlay .required-products .item-price-box .tab-content {
        float: left;
        margin-bottom: 20px;
    }
}

@media (max-width: 450px) {
    .filter-container .active-filters .active-filter li {
        float: none;
        margin-bottom: 15px;
        margin-right: 0;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .search-result .view-mode, .order-section .view-mode, .search-result .amount, .order-section .amount, .search-result .sort-by, .order-section .sort-by, .search-result .sort-direction, .order-section .sort-direction {
        margin-right: 30px;
    }

    .search-result .view-mode strong, .order-section .view-mode strong, .search-result .amount strong, .order-section .amount strong, .search-result .sort-by strong, .order-section .sort-by strong, .search-result .sort-direction strong, .order-section .sort-direction strong {
        float: left;
        margin-right: 10px;
    }

    .search-result .view-mode .select-wrapper-for-arrow, .order-section .view-mode .select-wrapper-for-arrow, .search-result .amount .select-wrapper-for-arrow, .order-section .amount .select-wrapper-for-arrow, .search-result .sort-by .select-wrapper-for-arrow, .order-section .sort-by .select-wrapper-for-arrow, .search-result .sort-direction .select-wrapper-for-arrow, .order-section .sort-direction .select-wrapper-for-arrow {
        margin-top: -7px;
    }

    .search-result .view-mode .select-wrapper-for-arrow select.form-control, .order-section .view-mode .select-wrapper-for-arrow select.form-control, .search-result .amount .select-wrapper-for-arrow select.form-control, .order-section .amount .select-wrapper-for-arrow select.form-control, .search-result .sort-by .select-wrapper-for-arrow select.form-control, .order-section .sort-by .select-wrapper-for-arrow select.form-control, .search-result .sort-direction .select-wrapper-for-arrow select.form-control, .order-section .sort-direction .select-wrapper-for-arrow select.form-control {
        background: #164194;
        color: #fff;
    }

    .search-result .view-mode .select-wrapper-for-arrow:after, .order-section .view-mode .select-wrapper-for-arrow:after, .search-result .amount .select-wrapper-for-arrow:after, .order-section .amount .select-wrapper-for-arrow:after, .search-result .sort-by .select-wrapper-for-arrow:after, .order-section .sort-by .select-wrapper-for-arrow:after, .search-result .sort-direction .select-wrapper-for-arrow:after, .order-section .sort-direction .select-wrapper-for-arrow:after {
        color: #fff;
        padding-top: 0;
        right: 6px;
    }

    .search-result .sort-by .select-wrapper-for-arrow, .order-section .sort-by .select-wrapper-for-arrow {
        width: 150px;
        overflow: hidden;
    }

    .search-result .sort-by select, .order-section .sort-by select {
        width: 175px;
    }

    .search-result .sort-direction .select-wrapper-for-arrow, .order-section .sort-direction .select-wrapper-for-arrow {
        width: 140px;
        overflow: hidden;
    }

    .search-result .sort-direction select, .order-section .sort-direction select {
        width: 165px;
    }

    .search-result .amount .select-wrapper-for-arrow, .order-section .amount .select-wrapper-for-arrow {
        width: 70px;
        overflow: hidden;
    }

    .search-result .amount select, .order-section .amount select {
        width: 95px;
    }

    .page-title p {
        max-width: 715px;
    }

    header #header-menu-container .rectangle #logout-and-cart .shopping-cart {
        margin-top: -9px;
    }
}

.current-menu-tree a {
    color: #164194;
}

.my-account-section {
    margin-bottom: 60px;
}

.my-account-section dt, .my-account-section dd {
    line-height: 1.26;
}

.my-account-section dt {
    margin-bottom: 6px;
    text-align: left;
}

.my-account-section dd {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

@media (max-width: 767px) {
    .my-account-section .user-info h2, .my-account-section .company-info h2 {
        margin-bottom: 15px;
    }

    .my-account-section .user-info dt, .my-account-section .company-info dt, .my-account-section .user-info dd, .my-account-section .company-info dd {
        width: 100%;
        float: none;
    }

    .my-account-section .user-info dd, .my-account-section .company-info dd {
        margin-left: 0;
        margin-bottom: 15px;
    }

    .my-account-section .company-info {
        margin-top: 30px;
    }
}

@media (min-width: 768px) {
    .my-account-section dt {
        width: 35%;
        text-overflow: clip;
    }

    .my-account-section dd {
        width: 65%;
        margin-left: 35%;
    }
}

@media (min-width: 992px) {
    .my-account-section dt {
        width: 25%;
    }

    .my-account-section dd {
        width: 75%;
        margin-left: 25%;
    }
}

.my-account-intro {
    margin: 0 auto 30px auto;
    max-width: 925px;
}

.my-orders-section strong, .order-overlay strong {
    margin-right: 13px;
}

@media (min-width: 768px) {
    .my-orders-section .select-wrapper-for-arrow, .order-overlay .select-wrapper-for-arrow {
        display: inline-block;
    }
}

.my-orders-section .select-wrapper-for-arrow select.form-control, .order-overlay .select-wrapper-for-arrow select.form-control {
    background: #164194;
    color: #fff;
    padding-left: 6px;
    padding-right: 35px;
}

.my-orders-section .select-wrapper-for-arrow:after, .order-overlay .select-wrapper-for-arrow:after {
    color: #fff;
    padding-top: 0;
    top: 11px;
    right: 8px;
    font-size: 21px;
}

.my-orders-section .sort-by, .order-overlay .sort-by {
    margin-top: 30px;
    margin-bottom: 20px;
}

.my-orders-table .winkler-arrow-right {
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    margin-top: -4px;
    margin-left: 1px;
    font-size: 22px;
    color: #bb2d21;
    vertical-align: middle;
}

.my-orders-table .winkler-checkbox-checked {
    display: inline-block;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    font-size: 20px;
    color: #109110;
}

.my-orders-table .order-amount-column {
    margin-bottom: 15px;
}

.my-orders-table .order-number {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
}

.my-orders-table .order-item {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 15px;
}

@media (max-width: 767px) {
    .my-orders-table .order-bold {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    }

    .my-orders-table .row {
        position: relative;
        border-top: 1px solid #c0c0c0;
        margin: 0;
        padding: 10px 0;
    }

    .my-orders-table .row > div {
        padding: 0;
    }

    .my-orders-table .row > .order-item-column, .my-orders-table .row > .order-number-column {
        margin-bottom: 15px;
        padding-right: 30px;
    }

    .my-orders-table .header-row {
        border: none;
    }

    .my-orders-table .order-status-column {
        position: absolute;
        top: 10px;
        right: 0;
    }

    .my-orders-table .order-status-header {
        text-align: right;
    }

    .my-orders-table .order-date-header, .my-orders-table .order-amount-header, .my-orders-table .order-comment-header, .my-orders-table .order-delivery-number-header, .my-orders-table .order-amount-outstanding-header {
        display: none;
    }
}

@media (min-width: 768px) {
    .my-orders-table {
        display: table;
        width: 100%;
    }

    .my-orders-table .row {
        display: table-row;
    }

    .my-orders-table .row > div {
        float: none;
        display: table-cell;
        padding: 8px 0;
        border-bottom: 1px solid #c0c0c0;
    }

    .my-orders-table .row:last-child > div {
        border-bottom: none;
    }

    .my-orders-table .order-number {
        font-size: 18px;
    }

    .my-orders-table .winkler-arrow-right {
        margin-top: -7px;
    }
}

.winkler-inprogress {
    display: inline-block;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    font-size: 20px;
    padding-left: 1px;
    width: 23px;
}

.gray-background {
    background-color: #eaeaea;
}

.form-control.invert {
    background-color: #fff;
}

@media (max-width: 767px) {
    .change-password-container {
        padding: 0;
    }
}

.change-password-inner {
    padding: 30px;
}

@media (max-width: 767px) {
    .change-password-inner {
        padding: 24px 20px 43px 20px;
    }
}

.btn-submit-changes {
    display: block;
    margin: 30px auto 0 auto;
    padding: 7px 36px;
}

@media (max-width: 767px) {
    .btn-submit-changes {
        margin-top: 15px;
    }
}

@media (max-width: 767px) {
    .change-password-form .form-group {
        margin-bottom: 24px;
    }
}

.btn-new-password {
    padding: 7px 18px;
}

.form-groups-table-submit-row button {
    margin-bottom: 8px;
}

@media (min-width: 768px) {
    .form-groups-table-submit-row button {
        margin-left: 10px;
        margin-bottom: 0;
    }
}

@media (max-width: 767px) {
    .form-groups-table-submit-row {
        text-align: center;
    }
}

@media (min-width: 768px) {
    .form-groups-table-submit-row .col-sm-5, .form-groups-table-submit-row .col-sm-4 {
        float: right;
    }
}

@media (min-width: 768px) {
    .form-groups-table {
        display: table;
    }

    .form-groups-table .row {
        display: table-row;
    }

    .form-groups-table .row > div {
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 30px;
    }

    .form-groups-table div {
        float: none;
    }

    .form-groups-table label {
        line-height: 1;
        vertical-align: middle;
    }

    .change-password-actions {
        float: left;
    }

    .change-password-action {
        float: right;
    }
}

.header-row {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shopping-list-dashboard .main-content-title {
    margin-bottom: 27px;
}

.shopping-list-function-buttons {
    margin: 30px auto;
    text-align: right;
    font-size: 0;
}

@media (max-width: 767px) {
    .shopping-list-function-buttons {
        display: none;
    }
}

@media (min-width: 768px) {
    .shopping-list-function-buttons .row {
        margin: 0 -7px;
    }

    .shopping-list-function-buttons .shopping-list-function-button {
        padding: 0 7px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .shopping-list-function-buttons .row {
        margin: 0 -7px;
    }

    .shopping-list-function-buttons .shopping-list-function-button {
        width: 25%;
    }

    .shopping-list-function-buttons .btn-std-format {
        width: 100%;
    }
}

.shopping-list-topbar {
    margin: 30px 0;
    padding: 30px 0 35px 0;
}

@media (min-width: 768px) {
    .shopping-list-topbar .search-wrapper {
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .shopping-list-topbar .search-wrapper {
        position: relative;
        left: 50%;
        margin-left: -344px;
        margin-bottom: 15px;
        width: 688px;
        float: left;
    }
}

@media (min-width: 768px) {
    .shopping-list-topbar .search-field-wrapper, .shopping-list-topbar .search-button-wrapper {
        float: left;
    }
}

.shopping-list-topbar .search-field-wrapper {
    width: 100%;
}

@media (min-width: 768px) and (max-width: 991px) {
    .shopping-list-topbar .search-field-wrapper {
        width: 453px;
    }
}

@media (min-width: 992px) {
    .shopping-list-topbar .search-field-wrapper {
        width: 350px;
    }
}

@media (min-width: 1200px) {
    .shopping-list-topbar .search-field-wrapper {
        width: 480px;
    }
}

@media (max-width: 767px) {
    .shopping-list-topbar .search-button-wrapper {
        margin-top: 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .shopping-list-topbar .search-button-wrapper {
        width: 235px;
    }
}

.shopping-list-topbar .search-button {
    padding: 7px 18px;
}

.shopping-list-topbar .search-field {
    text-align: center;
    font-size: 18px;
}

.shopping-list-topbar .search-field::-webkit-input-placeholder {
    color: #000;
}

.shopping-list-topbar .search-field::-moz-placeholder {
    color: #000;
}

.shopping-list-topbar .search-field:-ms-input-placeholder {
    color: #000;
}

.shopping-list-topbar .search-field:-moz-placeholder {
    color: #000;
}

.shopping-list-topbar .new-list-wrapper {
    margin-top: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .shopping-list-topbar .new-list-wrapper {
        clear: both;
        display: block;
        margin: 0 auto;
        width: 688px;
        padding-left: 453px;
    }

    .shopping-list-topbar .new-list-wrapper button {
        width: 235px;
    }
}

@media (min-width: 992px) {
    .shopping-list-topbar .new-list-wrapper {
        float: right;
        margin-top: 0;
    }
}

.shopping-list-topbar .new-list-wrapper button {
    padding: 7px 18px;
}

.shopping-list-function-button {
    display: inline-block;
}

@media (max-width: 767px) {
    .shopping-list-sorting {
        margin-bottom: 15px;
    }
}

.shopping-list-sorting strong {
    margin-right: 8px;
}

@media (min-width: 768px) {
    .shopping-list-sorting strong {
        line-height: 40px;
    }
}

@media (min-width: 768px) {
    .shopping-list-sorting .select-wrapper-for-arrow {
        display: inline-block;
    }
}

.shopping-list-sorting .select-wrapper-for-arrow::after {
    top: 9px;
    right: 8px;
    font-size: 21px;
}

@media (max-width: 767px) {
    .shopping-list-table {
        margin-bottom: 30px;
    }
}

.shopping-list-table .styled-checkbox {
    margin: 7px 0 0;
}

.shopping-list-table .styled-checkbox.icon.winkler-checkbox-checked {
    font-size: 20px;
    padding: 10px;
}

.shopping-list-table .row {
    border-bottom: 1px solid #c0c0c0;
    position: relative;
    margin: 0;
}

@media (max-width: 767px) {
    .shopping-list-table .row {
        padding-bottom: 15px;
    }
}

.shopping-list-table .row > div {
    padding: 15px 0;
}

@media (min-width: 768px) {
    .shopping-list-table .row > div {
        padding: 8px 0;
    }
}

.shopping-list-table .row > .icons-container {
    padding: 0;
}

@media (min-width: 768px) {
    .shopping-list-table .row > .icons-container {
        position: absolute;
        top: 15px;
        right: 54px;
    }
}

@media (min-width: 992px) {
    .shopping-list-table .row > .icons-container {
        display: none;
    }
}

.shopping-list-table .row > .icons-container .add-to-cart-icon {
    background-color: #bb2d21;
}

.shopping-list-table .row:last-child {
    border-bottom: none;
}

.shopping-list-table .row:hover > .icons-container {
    display: block;
}

.shopping-list-table .comment {
    line-height: 1.26;
    max-width: 390px;
    padding-right: 15px;
}

@media (min-width: 1200px) {
    .shopping-list-table .comment {
        max-width: 450px;
    }
}

.shopping-list-table .article-count {
    display: block;
    margin-top: 3px;
}

.shopping-list-table .winkler-arrow-right {
    display: inline-block;
    font-size: 20px;
    height: 24px;
    overflow: hidden;
    padding-left: 0;
    padding-top: 2px;
    position: relative;
    top: auto;
    vertical-align: top;
    width: 21px;
    color: #bb2d21;
    padding-left: 3px;
}

.shopping-list-table .shopping-list-name {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    margin-bottom: 3px;
    padding-right: 10px;
}

@media (max-width: 767px) {
    .shopping-list-table .checkbox-container {
        display: none;
    }
}

@media (max-width: 767px) {
    .new-shopping-list-form {
        margin-bottom: 30px;
    }
}

.btn-add-new-shopping-list {
    margin-top: 28px;
    padding: 7px 36px;
}

.btn-std-format {
    min-width: 160px;
    padding: 7px 0;
}

@media (min-width: 1024px) {
    .btn-std-format {
        min-width: 220px;
    }
}

@media (max-width: 991px) {
    .shopping-list-forms {
        padding: 0;
    }
}

.shopping-list-forms .container-inner {
    padding: 30px;
}

.shopping-list-forms .form-label {
    margin-bottom: 2px;
}

.shopping-list-forms .form-row {
    margin-bottom: 7px;
}

.shopping-list-forms .form-row input[type="text"] {
    height: 42px;
}

.shopping-list-forms .csv-notice {
    margin-top: 12px;
}

.csv-file-upload-container {
    margin-top: 19px;
}

.add-product-container {
    margin: 30px 0;
}

@media (max-width: 767px) {
    .add-product-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .add-product-container .add-product-to-list {
        text-align: center;
    }
}

.add-product-container .winkler-arrow-down {
    margin-left: 5px;
    color: #164194;
}

.shopping-list-detail-buttons {
    margin-top: 30px;
}

@media (max-width: 767px) {
    .shopping-list-detail-buttons {
        display: none;
    }
}

.shopping-list-detail-buttons .row > div a, .shopping-list-detail-buttons .row > div button {
    display: inline;
    vertical-align: top;
}

.shopping-list-detail-buttons .row > div button {
    margin-left: 10px;
}

.shopping-list-detail-buttons .buttons-container {
    float: right;
}

.shopping-list-detail-buttons .select-all-items, .shopping-list-detail-buttons .deselect-all-items {
    position: relative;
    top: 6px;
}

.shopping-list-detail-edit-product-amount .styled-checkbox {
    margin: 0;
}

.shopping-list-detail-edit-product-amount .btn-add {
    float: right;
}

.shopping-list-detail-edit-product-amount .btn-add .winkler-expand {
    color: #fff;
}

.shopping-list-detail-edit-product-amount .add-product-name-field {
    max-width: 340px;
}

.shopping-list-detail-edit-product-amount .add-product-name {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .shopping-list-detail-edit-product-amount .add-product-name strong {
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        font-size: 24px;
    }
}

@media (max-width: 767px) {
    .shopping-list-detail-edit-product-amount > .row > div {
        margin-top: 30px;
    }

    .shopping-list-detail-edit-product-amount > .row > div:nth-child(2) {
        margin-top: 15px;
    }
}

.product-amount input {
    text-align: right;
    width: 60px;
    display: inline-block;
    margin-right: 10px;
}

.product-amount label {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.shopping-list-detail-table {
    margin-top: 50px;
}

@media (max-width: 767px) {
    .shopping-list-detail-table {
        margin-top: 30px;
    }
}

.shopping-list-detail-table > .row {
    border-bottom: 1px solid #c0c0c0;
}

.shopping-list-detail-table .product-name .winkler-arrow-right {
    color: #bb2d21;
    font-size: 21px;
    margin-left: 3px;
}

.shopping-list-detail-table .styled-checkbox {
    margin: 0;
}

.shopping-list-detail-table .styled-checkbox.icon.winkler-checkbox-checked {
    font-size: 20px;
    padding: 10px;
}

.shopping-list-detail-table .product-comment-field {
    background: #eaeaea;
    border-color: #eaeaea;
    color: #000;
    border-radius: 0;
    box-shadow: none;
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    font-size: 15px;
    margin-bottom: 15px;
    min-height: 80px;
}

.shopping-list-detail-table .product-comment-new {
    display: none;
}

@media (max-width: 767px) {
    .shopping-list-detail-table .product-comment-new .save-data-container {
        margin-top: 30px;
    }
}

@media (min-width: 768px) {
    .shopping-list-detail-table .product-comment-new .save-data-container {
        float: left;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .shopping-list-detail-table .product-comment-new .save-data-container .save-data-action {
        margin-top: 15px;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .shopping-list-detail-table .product-comment-new .save-data-container .save-data-action {
        float: right;
    }

    .shopping-list-detail-table .product-comment-new .save-data-container .save-data-action .cancel {
        position: relative;
        top: 6px;
        padding-top: 10px;
        margin-right: 10px;
        vertical-align: bottom;
    }

    .shopping-list-detail-table .product-comment-new .save-data-container .save-data-action .save-comment {
        width: 100%;
    }

    .shopping-list-detail-table .product-comment-new .save-data-container .save-data-action:first-child {
        width: 75%;
    }

    .shopping-list-detail-table .product-comment-new .save-data-container .save-data-action:nth-child(2) {
        width: 25%;
    }
}

@media (max-width: 767px) {
    .shopping-list-detail-table .checkbox-container {
        display: none;
    }
}

.shopping-list-detail-table .icons-container {
    float: right;
}

@media (min-width: 768px) {
    .shopping-list-detail-table .row > .icons-container-column {
        display: none;
    }
}

@media (max-width: 767px) {
    .edit-shopping-list-data {
        margin-bottom: 15px;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .edit-shopping-list-data {
        float: right;
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .edit-shopping-list-data .edit-data-container a {
        width: 100%;
        display: block;
        background-color: #164194;
        border: medium none;
        color: #fff;
        font-size: 18px;
        padding: 7px 60px;
        border-radius: 0;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    }

    .edit-shopping-list-data .edit-data-container a:active, .edit-shopping-list-data .edit-data-container a:focus, .edit-shopping-list-data .edit-data-container a:hover {
        color: #fff;
        background: #133880;
        outline: 0;
    }

    .edit-shopping-list-data .edit-data-container a.btn-red {
        background-color: #bb2d21;
    }

    .edit-shopping-list-data .edit-data-container a.btn-red:focus, .edit-shopping-list-data .edit-data-container a.btn-red:hover {
        color: #fff;
        background: #a40e11;
    }

    .edit-shopping-list-data .edit-data-container a.btn-gray {
        background-color: #eaeaea;
        color: #000;
    }

    .edit-shopping-list-data .edit-data-container a.btn-gray:focus, .edit-shopping-list-data .edit-data-container a.btn-gray:hover {
        color: #000;
        background: #c0c0c0;
    }

    .edit-shopping-list-data .edit-data-container a.btn-blue {
        background-color: #164194;
    }

    .edit-shopping-list-data .edit-data-container a.btn-blue:focus, .edit-shopping-list-data .edit-data-container a.btn-blue:hover {
        color: #fff;
        background: #133880;
    }

    .edit-shopping-list-data .edit-data-container a.btn-add {
        border: none;
        width: 40px;
        height: 40px;
        color: #fff;
        font-size: 20px;
        padding: 0;
    }

    .edit-shopping-list-data .edit-data-container a.btn-add::before {
        display: block;
        padding-top: 9px;
    }
}

@media (min-width: 768px) {
    .edit-shopping-list-data .edit-data-container a {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    }
}

.edit-shopping-list-data .winkler-bearbeiten {
    padding-top: 0;
    margin-left: 10px;
    color: #164194;
}

@media (max-width: 767px) {
    .edit-shopping-list-data .winkler-bearbeiten {
        display: none;
    }
}

.edit-shopping-list-data .save-data-container {
    display: none;
}

@media (min-width: 768px) {
    .edit-shopping-list-data .save-data-action {
        float: right;
    }
}

@media (min-width: 768px) {
    .edit-shopping-list-data .save-comment {
        margin-left: 8px;
    }
}

.product-comparison-amount, .product-part-number, .product-material-number {
    color: #747474;
}

.product-comparison-table-container {
    overflow-x: hidden;
}

.product-comparison-table {
    table-layout: fixed;
    width: 100%;
    overflow-x: hidden;
}

.product-comparison-table .property-label {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

.product-comparison-table .property-label .cell-content {
    margin-right: 10px;
    line-height: 1.26;
}

@media (min-width: 768px) {
    .product-comparison-table .property-label .cell-content {
        margin-right: 20px;
    }
}

.product-comparison-table th, .product-comparison-table td {
    vertical-align: top;
}

.product-comparison-table th {
    width: 125px;
}

@media (min-width: 768px) {
    .product-comparison-table th {
        width: 190px;
    }
}

.product-comparison-table td {
    width: 220px;
}

@media (min-width: 768px) {
    .product-comparison-table td {
        width: 266px;
    }
}

.product-comparison-table .cell-content {
    display: block;
    padding: 16px 0;
}

.product-comparison-table .property-label .cell-content, .product-comparison-table .property-value .cell-content {
    border-top: 1px solid #eaeaea;
}

.product-comparison-table .property-label.no-border .cell-content, .product-comparison-table .property-value.no-border .cell-content {
    border: 0;
}

.product-comparison-table .product-description {
    padding-right: 20px;
}

.product-comparison-table .product-content {
    position: relative;
    padding-top: 14px;
    padding-right: 10px;
    padding-bottom: 103px;
}

.product-comparison-table .product-content .checkbox-container {
    position: absolute;
    bottom: 30px;
}

@media (max-width: 767px) {
    .product-comparison-table .product-content .checkbox-container strong {
        display: inline-block;
        max-width: 100px;
        vertical-align: middle;
    }
}

.product-comparison-table .product-content .styled-checkbox {
    margin: 0 10px 0 0;
}

.product-comparison-table .product-content .styled-checkbox.icon.winkler-checkbox-checked {
    font-size: 20px;
    padding: 10px;
}

.product-comparison-table .product-content .cell-content {
    padding: 0;
}

.product-comparison-table .product-content .icons-container {
    display: none;
    position: absolute;
    top: 30px;
    right: 15px;
    border: none;
}

@media (max-width: 1025px) {
    .product-comparison-table .product-content .icons-container {
        display: block;
    }
}

.product-comparison-table .product-content .icons-container .add-to-icons {
    background-color: #164194;
}

.product-comparison-table .product-content .icons-container .add-to-icons a {
    display: block;
    height: 41px;
    border-bottom: 1px solid #fff;
    padding-top: 10px;
    text-align: center;
    width: 40px;
    color: #fff;
}

.product-comparison-table .product-content .icons-container .add-to-cart-icon {
    background-color: #bb2d21;
}

.product-comparison-table .product-content:hover > .icons-container {
    display: block;
}

@media (max-width: 767px) {
    .product-comparison-table .product-content {
        padding-bottom: 153px;
    }

    .product-comparison-table .product-content .checkbox-container {
        bottom: 80px;
    }

    .product-comparison-table .product-content .icons-container {
        top: auto;
        right: auto;
        bottom: 25px;
        left: 0;
    }

    .product-comparison-table .product-content .icons-container .add-to-icons {
        margin-right: 1px;
    }

    .product-comparison-table .product-content .icons-container .add-to-icons {
        float: left;
    }
}

.product-comparison-table .price-box {
    margin-bottom: 23px;
}

.product-comparison-table .price-box .nav-tabs {
    border: none;
}

.product-comparison-table .price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

.product-comparison-table .price-box .nav-tabs li.active {
    text-decoration: none;
}

.product-comparison-table .price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.product-comparison-table .price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
}

.product-comparison-table .price-box .nav-tabs li a:hover {
    background: none;
}

.product-comparison-table .price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

.product-comparison-table .price-box .nav-tabs li:first-child a {
    padding-left: 0;
}

.product-comparison-table .price-box .tab-content {
    margin-top: 26px;
    margin-bottom: 6px;
}

.product-comparison-table .price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

.product-comparison-table .price-box .regular-price .price {
    font-size: 30px;
}

.product-comparison-table .price-box .regular-price .winkler-arrow-right {
    padding-top: 4px;
    padding-left: 5px;
}

.product-comparison-table .product-title {
    margin-bottom: 8px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 23px;
}

.product-comparison-table .product-part-number, .product-comparison-table .product-material-number {
    line-height: 1;
}

.product-comparison-table .product-part-number {
    margin-bottom: 4px;
}

.product-comparison-table .compared {
    color: #bb2d21;
}

.comparison-mode tr td.property-value:nth-child(2), .comparison-mode tr td.property-value:nth-child(4) {
    color: #bb2d21;
}

.comparison-mode tr td.property-value:nth-child(2).null-value, .comparison-mode tr td.property-value:nth-child(4).null-value {
    color: #000;
}

@media (max-width: 767px) {
    .confirmation .row > [class^="col-"] {
        margin-top: 80px;
        float: none;
    }

    .confirmation .row > [class^="col-"]:first-child {
        margin-top: 0;
    }

    .confirmation .contact {
        margin: 80px auto 0;
        float: none;
    }
}

@media (max-width: 991px) {
    .form-horizontal .form-group {
        margin: 0 -20px;
    }
}

@media (max-width: 767px) {
    .container {
        width: 100%;
    }

    .form-group > [class^="col-"] > .row > [class^="col-"] {
        margin-bottom: 23px;
    }

    .form-group > [class^="col-"] > .row > [class^="col-"]:last-child {
        margin-bottom: 0;
    }

    .form-group label {
        margin-bottom: 10px;
    }
}

.nopadding {
    padding: 0 !important;
}

.shipping-failure-remove-item {
    line-height: 1.26;
}

@media (max-width: 767px) {
    .shipping-failure-remove-item {
        display: block;
        text-align: right;
        margin-top: 19px;
    }

    .shipping-failure-remove-item > span:first-child {
        width: 160px;
    }
}

.shipping-failure-remove-item > span {
    display: inline-block;
    vertical-align: middle;
}

.shipping-failure-remove-item .winkler-arrow-right {
    width: 16px;
}

@media (max-width: 991px) {
    .legal-explanation {
        display: none;
    }
}

.radiobuttons-container {
    max-width: 585px;
}

@media (max-width: 1023px) {
    .forgotten-password-header {
        display: none;
    }
}

.offer-agreement-message {
    margin-left: 55px;
}

.icons-container {
    border: none;
}

.icons-container.horizontal .add-to-icons {
    border-right: 1px solid #fff;
    float: left;
}

.icons-container .add-to-icons {
    width: 41px;
    height: 40px;
    background-color: #164194;
    margin-bottom: 1px;
}

.icons-container .add-to-icons a {
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}

.icons-container .add-to-icons .icon {
    font-size: 21px;
    color: #fff;
    padding-top: 0;
}

.icons-container .add-to-icons.add-to-cart-icon, .icons-container .add-to-icons.shopping-list-icon {
    background: #bb2d21;
}

.icons-container .add-to-icons.compare-icon {
    background: #164194;
    font-size: 27px !important;
    padding-left: 0 !important;
    padding-top: 0;
    width: 41px;
}

#accessories, #related {
    position: relative;
}

#accessories h2, #related h2 {
    margin-bottom: 0;
}

#accessories p, #related p {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    #accessories .product-price, #related .product-price {
        float: left;
    }
}

#accessories .product-price .tax-details, #related .product-price .tax-details {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
}

#accessories .product-price .regular-price, #related .product-price .regular-price {
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
    font-size: 24px;
    color: #bb2d21;
}

@media (max-width: 767px) {
    #accessories .product-price .regular-price, #related .product-price .regular-price {
        margin-bottom: 30px;
    }
}

#accessories .add-to-box, #related .add-to-box {
    margin-top: -4px;
}

#accessories .tab-content-product-wrapper, #related .tab-content-product-wrapper, #accessories .item, #related .item {
    padding-top: 30px;
    padding-bottom: 5px;
    border-top: 1px solid #747474;
    margin-left: 0;
    margin-right: 0;
    clear: both;
    position: relative;
}

@media (min-width: 768px) {
    #accessories .tab-content-product-wrapper, #related .tab-content-product-wrapper, #accessories .item, #related .item {
        padding-top: 40px;
    }
}

#accessories .tab-content-product-wrapper .col-sm-9, #related .tab-content-product-wrapper .col-sm-9, #accessories .item .col-sm-9, #related .item .col-sm-9 {
    margin-bottom: 25px;
}

#accessories .tab-content-product-wrapper .icons-container, #related .tab-content-product-wrapper .icons-container, #accessories .item .icons-container, #related .item .icons-container {
    display: block;
    position: absolute;
    right: 0;
    bottom: 30px;
}

@media (min-width: 768px) {
    #accessories .tab-content-product-wrapper .icons-container, #related .tab-content-product-wrapper .icons-container, #accessories .item .icons-container, #related .item .icons-container {
        top: 40px;
        bottom: auto;
    }
}

@media (min-width: 1025px) {
    #accessories .tab-content-product-wrapper .icons-container, #related .tab-content-product-wrapper .icons-container, #accessories .item .icons-container, #related .item .icons-container {
        display: none;
    }
}

#accessories .tab-content-product-wrapper .icons-container .add-to-icons, #related .tab-content-product-wrapper .icons-container .add-to-icons, #accessories .item .icons-container .add-to-icons, #related .item .icons-container .add-to-icons {
    float: left;
    margin-right: 1px;
}

@media (min-width: 768px) {
    #accessories .tab-content-product-wrapper .icons-container .add-to-icons, #related .tab-content-product-wrapper .icons-container .add-to-icons, #accessories .item .icons-container .add-to-icons, #related .item .icons-container .add-to-icons {
        float: none;
        margin-right: 0;
        margin-bottom: 1px;
    }
}

#accessories .tab-content-product-wrapper .icons-container .add-to-icons a, #related .tab-content-product-wrapper .icons-container .add-to-icons a, #accessories .item .icons-container .add-to-icons a, #related .item .icons-container .add-to-icons a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 1px;
}

#accessories .tab-content-product-wrapper .icons-container .add-to-icons .icon, #related .tab-content-product-wrapper .icons-container .add-to-icons .icon, #accessories .item .icons-container .add-to-icons .icon, #related .item .icons-container .add-to-icons .icon {
    font-size: 21px;
    color: #fff;
    padding-top: 0;
}

#accessories .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, #related .tab-content-product-wrapper .icons-container .add-to-icons.add-to-cart-icon, #accessories .item .icons-container .add-to-icons.add-to-cart-icon, #related .item .icons-container .add-to-icons.add-to-cart-icon {
    background: #bb2d21;
}

#accessories .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, #related .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon, #accessories .item .icons-container .add-to-icons.compare-icon, #related .item .icons-container .add-to-icons.compare-icon, #accessories .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, #related .tab-content-product-wrapper .icons-container .add-to-icons.shopping-list-icon, #accessories .item .icons-container .add-to-icons.shopping-list-icon, #related .item .icons-container .add-to-icons.shopping-list-icon {
    background: #164194;
}

#accessories .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, #related .tab-content-product-wrapper .icons-container .add-to-icons.compare-icon .icon, #accessories .item .icons-container .add-to-icons.compare-icon .icon, #related .item .icons-container .add-to-icons.compare-icon .icon {
    font-size: 27px;
    margin-top: -3px;
    padding-left: 0;
    padding-top: 0;
    width: 41px;
}

#accessories .tab-content-product-wrapper:hover .icons-container, #related .tab-content-product-wrapper:hover .icons-container, #accessories .item:hover .icons-container, #related .item:hover .icons-container {
    display: block;
}

#accessories .item-price-box .nav-tabs, #related .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    margin-right: 4px;
    margin-top: 3px;
}

@media (max-width: 1025px) {
    #accessories .item-price-box .nav-tabs, #related .item-price-box .nav-tabs {
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    #accessories .item-price-box .nav-tabs, #related .item-price-box .nav-tabs {
        float: left;
    }
}

#accessories .item-price-box .nav-tabs li, #related .item-price-box .nav-tabs li {
    color: #747474;
    text-decoration: underline;
}

#accessories .item-price-box .nav-tabs li:first-child, #related .item-price-box .nav-tabs li:first-child {
    border-right: 1px solid #747474;
}

#accessories .item-price-box .nav-tabs li.active, #related .item-price-box .nav-tabs li.active {
    text-decoration: none;
}

#accessories .item-price-box .nav-tabs li.active a, #related .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

#accessories .item-price-box .nav-tabs li.no-padding-left a, #related .item-price-box .nav-tabs li.no-padding-left a {
    padding-left: 0;
}

#accessories .item-price-box .nav-tabs li a, #related .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

#accessories .item-price-box .nav-tabs li a:hover, #related .item-price-box .nav-tabs li a:hover {
    background: none;
}

#accessories .item-price-box .nav-tabs li a:after, #related .item-price-box .nav-tabs li a:after {
    content: "";
    display: none;
}

#accessories .item-price-box .tab-content, #related .item-price-box .tab-content {
    margin-top: 0;
    padding-top: 0;
}

@media (min-width: 992px) {
    #accessories .item-price-box .tab-content, #related .item-price-box .tab-content {
        float: left;
        margin-bottom: 20px;
    }
}

#accessories .item-price-box .regular-price, #related .item-price-box .regular-price {
    color: #bb2d21;
    font-size: 24px;
    font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
}

#accessories .item-price-box .regular-price .price, #related .item-price-box .regular-price .price {
    font-size: 24px;
}

#accessories .product-image img, #related .product-image img {
    width: 100%;
    height: auto;
}

.text-button-vertical-center {
    line-height: 40px;
}

@media (min-width: 768px) {
    .sorting-wrapper {
        display: inline-block;
    }
}

.download-field.form-control {
    border: 1px solid #eaeaea;
}
