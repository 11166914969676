@media print {
    a[href]:after {
        content: none !important;
    }

    .product-image {
        width: 250px !important;
    }

    .product-image img {
        width: 250px !important;
    }

    .add-to-box {
        display: none !important;
    }

    section.header-image {
        display: none !important;
    }

    footer.footer {
        display: none !important;
    }

    .header-nav,
    .slice.iwc-toolbar,
    #admin_toolbar_footer {
        display: none !important;
    }

    #header-menu-container {
        display: none !important;
    }
}
