@charset "UTF-8";

@font-face {
    font-family: "shipping-font";
    src: url("/fonts/shippingfont.eot");
    src: url("/fonts/shippingfont.eot?#iefix") format("embedded-opentype"), url("/fonts/shippingfont.woff") format("woff"), url("/fonts/shippingfont.ttf") format("truetype"), url("/fonts/shippingfont.svg#untitled-font-1") format("svg");
    font-weight: normal;
    font-style: normal;
}

[data-icon]:before {
    font-family: "shipping-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "shipping-font" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.winkler-nachtexpress:before {
    content: "\48";
}

.winkler-paketdienst:before {
    content: "\49";
}

.winkler-selbstabholung:before {
    content: "\4a";
}

.winkler-spedition:before {
    content: "\4b";
}

.winkler-tagestour:before {
    content: "\4c";
}
