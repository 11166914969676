.bulletpoints li {
    list-style: disc;
}

.search-result-products .item .found-term {
    font-family: 'Helvetica-Neue-LT-Bold', Verdana, Arial, Helvetica;
    color: #bb2d21;
}

.search-result-products.products-grid .item .search-reusult-term {
    margin-bottom: 10px;
}

.cookie-note .winkler-info {
    top: 10Px;
    margin-left: 5px;
    color: #747474;
}

.service-section .subtitle {
    margin-bottom: 40px;
}

.service-section .service-row {
    margin-bottom: 45px;
}

.service-section .service-row h2 {
    margin-bottom: 10px;
}

.service-section .table img {
    width: 100%;
}

.service-bottom .service-bottom-2-col a {
    margin-top: 30px;
    display: inline-block;
    padding: 7px 0;
    width: 220px;
}

.shipping-service-section .service-row {
    margin-top: 45px;
}

@media (max-width: 767px) {
    .shipping-service-section .service-row .col-md-4, .shipping-service-section .service-row .col-md-8 {
        float: none;
        width: 100%;
    }

    .service-section .service-row a {
        margin-top: 15px;
    }

    .service-test {
        margin-top: -80px;
    }

    .service-information {
        margin-top: 45px;
    }

    .service-bottom-2-col {
        text-align: center;
    }

    .service-bottom .service-bottom-2-col a {
        width: 270px;
    }
}

.service-icon-container .icon {
    color: #164194;
    font-size: 65px;
    width: 65px;
    height: 65px;
    top: -20px;
    margin-right: 20px;
}

.service-icon-container {
    margin-top: 45px;
    margin-bottom: 20px;
}

.shipping-link {
    margin-top: 45px;
}

.shipping-link a .icon, .shipping-link a {
    color: #164194;
}

.service-icon-container p {
    font-family: 'Helvetica-Neue-LT-Cond-Bld', Verdana, Arial, Helvetica;
    font-size: 20px;
    color: #164194;
}

.row.service-row img {
    width: 100%;
}

.quick-order .row .icon {
    color: #164194;
    top: 7px;
}

.quick-order .row .invalid .icon, .quick-order .row .invalid {
    color: #bb2d21;
}

.invalid-text {
    top: 7px;
    margin-left: 5px;
    position: relative;
}

.quick-order .row .gray-text {
    top: 7px;
    color: #747474;
    position: relative;
}

.quick-order .row.quick-order-table-head {
    margin-bottom: 15px;
}

.quick-order > p {
    margin-bottom: 30px;
}

.download-row {
    margin-bottom: 55px;
}

.download-row img {
    width: 100%;
}

@media (max-width: 991px) {
    .download-row .download-image-container {
        width: 100%;
        margin: 0 auto;
    }
}

.cross-references {
    color: #164194;
}

.accordion-item {
    background: #eaeaea;
    padding: 0 15px;
}

.accordion-item h2 {
    padding: 5px 40px 5px 0;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 1px;
    position: relative;
}

.accordion {
    margin-top: 45px;
}

.accordion-item .collapse {
    padding-bottom: 15px;
    margin-bottom: 1px;
}

.accordion-item .collapse p {
    padding-right: 40px;
}

.accordion-item h2.active {
    color: #164194;
}

.accordion-item h2 .icon {
    color: #164194;
    position: absolute;
    top: 6px;
    right: 0;
}

.faq-overlay-inner img {
    width: 100%;
}

.faq-overlay .icon {
    position: absolute;
    right: 7%;
    top: 60px;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 767px) {
    .accordion-item img {
        padding-top: 65px;
    }
}

.download-search-wrapper {
    width: 100%;
    max-width: 715px;
    margin: 50px auto 50px;
}

.download-not-found {
    color: #bb2d21;
    font-family: 'Helvetica-Neue-LT-Bold', Verdana, Arial, Helvetica;
    margin-top: 20px;
}

.download-not-found .icon {
    margin-right: 10px;
}

.download-found p {
    font-family: 'Helvetica-Neue-LT-Bold', Verdana, Arial, Helvetica;
}

.download-found {
    margin-top: 30px;
}

.download-found a {
    color: #164194;
    font-family: 'Helvetica-Neue-LT-Bold', Verdana, Arial, Helvetica;
}

.download-found a .icon {
    font-size: 25px;
    margin-left: 10px;
    height: 30px;
    width: 25px;
}

.download-background {
    background-color: #eaeaea;
    height: 50px;
    width: 100%;
    max-width: 695px;
    margin-top: 10px;
}

.download-background div {
    margin-top: 12px;
}

.download-search-wrapper input.form-control::-webkit-input-placeholder {
    color: #000;
}

.download-search-wrapper input.form-control:-moz-placeholder {
    color: #000;
}

.download-search-wrapper input.form-control::-moz-placeholder {
    color: #000;
}

.download-search-wrapper input.form-control:-ms-input-placeholder {
    color: #000;
}

.download-search-wrapper .download-field-wrapper {
    width: 75%;
    max-width: 470px;
}

.download-search-wrapper .download-button-wrapper {
    width: 25%;
}

.download-button-wrapper .btn {
    padding: 7px 0;
    width: 225px;
}

@media (max-width: 991px) {
    .download-background .col-lg-8.col-md-8 {
        float: left;
        width: 60%;
    }

    .download-background .col-lg-4.col-md-4 {
        float: left;
        width: 40%;
    }
}

@media (max-width: 767px) {
    .download-row .download-image-container {
        width: 100%;
        margin: 0 auto;
    }

    .download-search-wrapper .download-field-wrapper,
    .download-search-wrapper .download-button-wrapper {
        max-width: 470px;
        width: 100%;
        float: none !important;
    }

    .download-search-wrapper .download-field-wrapper {
        margin-bottom: 30px;
    }

    .download-button-wrapper .btn {
        padding: 7px 0;
        width: 100%;
    }

    .download-background .col-lg-8.col-md-8 {
        float: none;
        width: 100%;
    }

    .download-background .col-lg-4.col-md-4 {
        float: none;
        width: 100%;
    }

    .download-background {
        height: auto;
        width: 100%;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 10px;
    }
}

.offer-section .offer-products.products-grid .item-price-box .nav-tabs li a {
    color: #747474;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
    font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
    font-size: 15px;
}

.offer-section .offer-products.products-grid .item-price-box .nav-tabs {
    border: none;
    text-align: left;
    float: left;
    margin-right: 4px;
    margin-top: 3px;
    background-color: #fff;
}

.offer-section .offer-products.products-grid .item-price-box .nav-tabs li.active a {
    font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    color: #000;
}

.offer-section .offer-products.products-grid .item-price-box .nav-tabs li.active {
    text-decoration: none;
    background-color: #fff;
}

.offer-section.gray-tabs .offer-products.products-grid .nav-tabs > li {
    width: 50%;
}

.tab-pane .toolbar {
    margin-top: 0;
}

@media (min-width: 768px) {
    .tab-pane .toolbar {
        margin-top: 60px;
    }
}

.faq-links {
    color: #164194;
    font-family: 'Helvetica-Neue-LT-Bold', Verdana, Arial, Helvetica;
}

.img-faq {
    width: 100%;
}

.checkbox.keep-logged-in .styled-checkbox {
    margin-top: 6px;
}

@media (max-width: 1199px) {
    .cookie-note {
        display: block;
    }

    .checkbox.keep-logged-in .styled-checkbox {
        float: left;
        margin-bottom: 20px;
    }

    .cookie-note .winkler-info {
        top: 0px;
    }

    .quick-order-bottom-space .btn {
        padding: 7px 0;
        width: 220px;
    }
}

@media (max-width: 991px) {
    .container.service-section .icon.winkler-expand {
        color: #164194;
        margin-left: 10px;
    }

    .container.service-section .collap-text {
        margin-bottom: 15px;
    }

    .service-section h2, .service-bottom h2 {
        text-align: center;
        margin-bottom: 10px;
    }

    .service-section, .service-bottom div {
        text-align: justify;
    }

    .quick-order .enumeration {
        float: left;
        width: 30px;
    }

    .quick-order .article-number {
        float: left;
    }

    .quick-order .item-quantity {
        float: left;
        width: 80px;
    }

    .quick-order .checkbox {
        float: left;
        margin-top: 1px;
    }

    .quick-order .row.quick-order-bottom-space {
        width: 100%;
        margin: 0 auto;
    }

    .quick-order-bottom-space .btn {
        padding: 7px 0;
        width: 100%;
        max-width: 320px;
    }

    .quick-order .row.quick-order-bottom-space {
        text-align: center;
    }
}

.quick-order .add-article {
    margin-top: 30px;
    margin-bottom: 30px;
}

.add-article .icon {
    color: #164194;
    text-decoration: none;
    margin-left: 10px;
    top: 0;
}

.quick-order .row.quick-order-bottom-space .icon {
    top: 0;
}

.offer-section .offer-products.products-grid .product-image {
    position: relative;
}

.offer-section .offer-products.products-grid .stoerer {
    padding: 5px 10px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.offer-section .offer-products.products-grid .stoerer img {
    width: 70px;
}

.offer-products.products-list .item .stoerer {
    padding: 5px 10px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.offer-products.products-list .item .stoerer img {
    width: 70px;
}

@media (max-width: 1023px) {
    .service-bottom .service-bottom-2-col {
        padding-left: 20px;
    }
}

@media (max-width: 991px) {
    .row.service-row img {
        /*width: 35%;
        float:left;
        padding-right:20px;*/
    }

    .service-section .service-row .col-md-3 {
        float: left;
        width: 25%;
    }

    .service-section .service-row .col-md-9 {
        float: left;
        width: 75%;
    }

    .service-bottom .service-bottom-2-col a {
        float: left;
        margin-bottom: 80px;
        width: 100%;
    }
}

@media (max-width: 767px) {
    .service-section .service-row .col-md-3 {
        float: none;
        width: 100%;
        max-width: 260px;
        margin: 0 auto;
    }

    .service-section .service-row .col-md-9 {
        float: none;
        width: 100%;
    }

    .quick-order-table {
        max-width: 320px;
        margin: 0 auto;
    }

    .quick-order .article-number {
        width: 140px;
    }
}
