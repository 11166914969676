@media all and (max-width: 1200px) {
    body {
        min-width: 100%;
        width: 100%;
    }
}

@media all and (max-width: 1100px) {
    .ui-widget-header-inner {
        width: 100%;
    }
}

@media all and (max-width: 520px) {
    .loginwindow .ui-widget-header {
        font-size: 2em;
        margin-bottom: 0;
    }
}

@media all and (max-width: 1000px) {
    #teaser_box div {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        float: none;
        height: 120px;
        padding: 0;
    }
}

@media all and (max-width: 800px) {
    .container_12 .grid_9, .container_12 .grid_3 {
        width: 100%;
    }
}

@media screen and (max-width: 520px) {
    .nav-slide a.prev, .nav-reveal a.prev, .nav-doubleflip a.prev, .nav-fillslide a.prev, .nav-growpop a.prev {
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
    }
}

@media (max-width: 991px) {
    .productinfo .iwc-ui-matlist-classification {
        min-height: 100px;
        max-height: 100px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .iwc-ui-mat-details-view .iwc-ui-mat-details-matnr {
        height: 40px;
    }
}
