/*20150116@innovate LT: Link-Klasse, Icon's eingef�gt*/

/*Tabelle in FORM-TABLE-GRID*/
.TABLE-GROUP {
width: 100%;
}

/*Link-Definition*/
a.link-default:hover {
	background-color: #F9F9F9;
}
.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {	
    border-color: transparent;  
}	
	
.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {  
    background-color: transparent;  
	box-shadow: none;	
}	
.btn-link {	
	font-weight: 400;	
	color: #337AB7;	
	border-radius: 0px;	
}	

/*ICON-Bearbeiten-Definition*/
 .input_icon_edit {
background: url("../../../images/icons/bootstrap/edit.png") no-repeat scroll right center transparent;
background-position: 98%;
background-size: 15px;
}

/*ICON-Kalender-Definition*/
 .input_icon_calendar {
background: url("../../../images/icons/bootstrap/calendar.png") no-repeat scroll right center transparent;
background-position: 98%;
background-size: 15px;
}

/*20150119@innovate LT: Suchbutton-Icon einf�gen*/
.btnsearch {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

/*20150119@innovate LT: Materialliste-Element*/
.producttitle {
    font-weight: bold;
    padding: 5px 0px;
    color: #555;
}

.productprice {
    border-top: 1px solid #DADADA;
    padding-top: 5px;
}

.pricetext {
    font-weight: bold;
    font-size: 1.4em;
    padding-top: 14px;
    color: #555;
}

/*20150119@innovate LT: Button-Klasse �berschreiben*/
.button.btn {
  /*  width: auto;*/
    height: auto;
}


/*20150120@innovate LT: Produktbox-Klasse*/

.productbox:before, .productbox:after {
    background: none repeat scroll 0 0 #777;
    bottom: 15px;
    box-shadow: 0 15px 10px #777;
    content: "";
    left: 10px;
    max-width: 300px;
    position: absolute;
    top: 80%;
    transform: rotate(-3deg);
    width: 50%;
    z-index: -1;
}
*:before, *:after {
    box-sizing: border-box;
}
.productbox:after {
    left: auto;
    right: 10px;
    transform: rotate(3deg);
}

.productbox {
    background-color: #FFF;
    padding: 10px;
    margin-bottom: 10px;
}

/*20150121@innovate LT: Inputfeld bei Dialog fixen*/

.ui-dialog .input-group .form-control{
    z-index: auto;
}

.input-group-addon{
    vertical-align: top;
}