@charset "UTF-8";

@font-face {
    font-family: "winkler-font";
    src: url("/fonts/winklerfont.eot");
    src: url("/fonts/winklerfont-1.eot") format("embedded-opentype"), url("/fonts/winklerfont.svg") format("svg"), url("/fonts/winklerfont.woff") format("woff"), url("/fonts/winklerfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-rendering: optimizeLegibility;
}

[data-icon]:before {
    font-family: "winkler-font" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 2;

    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-rendering: optimizeLegibility;
}

[class^="winkler-"]:before,
[class*=" winkler-"]:before {
    font-family: "winkler-font" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-rendering: optimizeLegibility;
}

.winkler-arrow-down:before {
    content: "\61";
}

.winkler-nachtexpress:before {
    content: "\48";
}

.winkler-paketdienst:before {
    content: "\49";
}

.winkler-selbstabholung:before {
    content: "\4a";
}

.winkler-spedition:before {
    content: "\4b";
}

.winkler-tagestour:before {
    content: "\4c";
}

.winkler-arrow-down-1:before {
    content: "\62";
}

.winkler-arrow-left:before {
    content: "\63";
}

.winkler-arrow-right:before {
    content: "\64";
}

.winkler-arrow-up:before {
    content: "\65";
}

.winkler-artikelmerkmale:before {
    content: "\66";
}

.winkler-bullet:before {
    content: "\67";
}

.winkler-ch:before {
    content: "\68";
}

.winkler-checkbox-checked:before {
    content: "\69";
}

.winkler-close:before {
    content: "\6a";
}

.winkler-collapse:before {
    content: "\6b";
}

.winkler-contact:before {
    content: "\6c";
}

.winkler-download:before {
    content: "\6d";
}

.winkler-einkaufslisten:before {
    content: "\6e";
}

.winkler-expand:before {
    content: "\6f";
}

.winkler-language:before {
    content: "\70";
}

.winkler-link-extern:before {
    content: "\71";
}

.winkler-locations:before {
    content: "\72";
}

.winkler-menu:before {
    content: "\73";
}

.winkler-newsletter:before {
    content: "\74";
}

.winkler-onlinekat:before {
    content: "\75";
}

.winkler-pin:before {
    content: "\76";
}

.winkler-print:before {
    content: "\77";
}

.winkler-radio-checked:before {
    content: "\78";
}

.winkler-reset:before {
    content: "\79";
}

.winkler-search:before {
    content: "\7a";
}

.winkler-share:before {
    content: "\41";
}

.winkler-share-mail:before {
    content: "\42";
}

.winkler-share-sms:before {
    content: "\43";
}

.winkler-share-whatsapp:before {
    content: "\44";
}

.winkler-shopping-cart:before {
    content: "\45";
}

.winkler-video:before {
    content: "\46";
}

.winkler-youtube:before {
    content: "\47";
}

.winkler-reifen:before {
    content: "\4d";
}

.winkler-kachelansicht:before {
    content: "\4e";
}

.winkler-listenansicht:before {
    content: "\4f";
}

.winkler-aktualisieren:before {
    content: "\50";
}

.winkler-bearbeiten:before {
    content: "\51";
}

.winkler-info:before {
    content: "\52";
}

.winkler-inprogress:before {
    content: "\53";
}

.winkler-trash:before {
    content: "\54";
}

.winkler-login:before {
    content: "\55";
}
