.breadcrumbs, .breadcrumbs-mobile {
    margin-bottom: 20px;
}

.close-zoom {
    display: none;
}

.catalog-section.contact-section .catalogs .col-md-4, .login-section .catalogs .col-md-4 {
    padding-bottom: 30px;
}

#related > p {
    visibility: hidden;
}

.required-products-overlay .required-products .order-material-number {
    margin-bottom: 20px;
}

.required-products-overlay .required-products .product-attribute-list {
    line-height: 16px;
}

.products-grid .item .add-to-box {
    margin-top: 20px;
}

.search-result-products.products-grid .item .search-reusult-term {
    margin-bottom: 0;
}

.search-result.no-result {
    margin-top: 30px;
}

.products-list .product-attribute-list {
    line-height: 16px;
}

.search-suggest li, .search-suggest p {
    text-align: left;
}

.shadow-sortiment .product-image img {
    max-width: 180px;
}

.offer-section .offer-products.noscroll {
    height: auto;
    overflow: visible;
    white-space: normal;
}

@media (max-width: 1024px) {
    .products-grid-configuration .item-price-box {
        margin-bottom: 15px;
    }

    .products-grid-configuration .item-price-box .nav-tabs {
        border: none;
        text-align: left;
        float: left;
        margin-right: 4px;
        margin-top: 3px;
    }

    .products-grid-configuration .item-price-box .nav-tabs li {
        color: #747474;
        text-decoration: underline;
    }

    .products-grid-configuration .item-price-box .nav-tabs li:first-child {
        border-right: 1px solid #747474;
    }

    .products-grid-configuration .item-price-box .nav-tabs li.active {
        text-decoration: none;
    }

    .products-grid-configuration .item-price-box .nav-tabs li.active a {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
        color: #000;
    }

    .products-grid-configuration .item-price-box .nav-tabs li.no-padding-left a {
        padding-left: 0;
    }

    .products-grid-configuration .item-price-box .nav-tabs li a {
        color: #747474;
        border: none;
        padding-bottom: 0;
        padding-top: 0;
        background: none;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    .products-grid-configuration .item-price-box .nav-tabs li a:hover {
        background: none;
    }

    .products-grid-configuration .item-price-box .nav-tabs li a:after {
        content: "";
        display: none;
    }

    .products-grid-configuration .item-price-box .tab-content {
        padding-top: 20px;
    }

    .products-grid-configuration .item-price-box .regular-price {
        color: #bb2d21;
        font-size: 24px;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .products-grid-configuration .item-price-box .regular-price .price {
        font-size: 34px;
    }

    .products-grid-configuration .item-price-box .regular-price .icon {
        font-size: 22px;
        width: 20px;
        height: 24px;
        top: 2px;
    }

    .products-grid-configuration h2 {
        margin-bottom: 5px;
        clear: both;
    }

    .products-grid-configuration .order-material-number {
        line-height: 18px;
    }

    .products-grid-configuration .icons-container {
        display: none;
        position: absolute;
        right: 15px;
        top: 0;
    }

    .products-grid-configuration .icons-container .add-to-cart-icon {
        background: #bb2d21;
    }

    .products-grid-configuration .icons-container .shopping-list-icon, .products-grid-configuration .icons-container .compare-icon {
        background: #164194;
    }

    .products-grid-configuration .icons-container .icon {
        color: #fff;
        font-size: 21px;
        padding-top: 0;
    }

    .products-grid-configuration .icons-container .add-to-icons a {
        display: block;
        height: 40px;
        margin-bottom: 1px;
        padding-top: 10px;
        text-align: center;
        width: 40px;
    }

    .products-grid-configuration .item {
        position: relative;
    }

    .products-grid-configuration .item:hover .icons-container {
        display: block;
    }

    .required-products-overlay .required-products .add-to-box {
        float: none;
        width: 100%;
    }
}

@media (max-width: 991px) {
    header .winkler-logo-container {
        width: 80px;
    }

    header .winkler-logo-container .logo-rectangle-background {
        width: 80px;
        height: 80px;
    }

    header .winkler-logo-container .logo-rectangle-background .logo {
        left: 15px;
        top: 20px;
        z-index: 1;
    }

    header .winkler-logo-container .logo-rectangle-background .logo .header-logo-image {
        width: 67px;
    }

    header .winkler-logo-container .logo-triangle-background {
        height: 80px;
        left: 80px;
    }

    header .winkler-logo-container .logo-triangle-background:after {
        left: -163px;
        width: 160px;
    }

    header #header-menu-container {
        height: 80px;
        top: 0;
        left: 137px;
    }

    header #header-menu-container .rectangle {
        height: 80px;
    }

    header #header-menu-container .rectangle #navigation-mobile {
        padding-right: 20px;
        margin-top: 30px;
    }

    header #header-menu-container .rectangle #navigation-mobile li {
        float: right;
        margin-left: 20px;
    }

    header #header-menu-container .rectangle #navigation-mobile li .icon {
        font-size: 22px;
        width: 23px;
        cursor: pointer;
    }

    header #header-menu-container .rectangle #navigation-mobile li .icon.winkler-shopping-cart {
        color: #bb2d21;
    }

    header #header-menu-container .rectangle #navigation-mobile li.cart {
        position: relative;
    }

    header #header-menu-container .rectangle #navigation-mobile li.cart .items-in-cart-wrapper {
        background: #bb2d21 none repeat scroll 0 0;
        border-radius: 50%;
        color: #fff;
        display: block;
        height: 18px;
        padding: 0;
        position: absolute;
        right: -5px;
        text-align: center;
        top: -4px;
        width: 18px;
        z-index: 1;
    }

    header #header-menu-container .rectangle #navigation-mobile li.cart .items-in-cart-wrapper #items-in-cart-mobile {
        font-size: 10px;
        position: relative;
        top: -7px;
    }

    header #header-menu-container .triangle {
        width: 100px;
        height: 90px;
        top: 0;
        left: -50px;
    }

    header #header-menu-container .triangle:after {
        height: 80px;
        top: 0;
        left: 31px;
        width: 78px;
    }

    header .cart-details {
        display: none;
        background: #bb2d21;
        color: #fff;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        padding: 30px 20px;
        z-index: 2500;
        -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    }

    header .cart-details .winkler-close {
        position: absolute;
        top: 15px;
        right: 20px;
    }

    header .cart-details .quantity-row, header .cart-details .total {
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
        font-size: 18px;
    }

    header .cart-details .quantity-row {
        border-bottom: 1px solid #fff;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    header .cart-details .quantity-label, header .cart-details .total-label {
        float: left;
        width: 180px;
    }

    header .cart-details .quantity, header .cart-details .total-price {
        float: right;
    }

    header .cart-details .shipping-info-link {
        margin-bottom: 30px;
    }

    header .cart-details .shipping-info-link a {
        color: #fff;
        text-decoration: underline;
    }

    header .cart-details .action-button a {
        padding: 7px 0;
        width: 220px;
        color: #bb2d21;
        background: #fff;
    }

    header .cart-details:after {
        border-color: transparent transparent #bb2d21;
        border-style: solid;
        border-width: 0 25px 20px;
        content: "";
        display: block;
        height: 0;
        right: 47px;
        position: absolute;
        top: -20px;
        width: 0;
    }

    #mobile-subheader-dropdown {
        background: rgba(14, 65, 148, 0.85) none repeat scroll 0 0;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        font-size: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        z-index: 1004;
    }

    #mobile-subheader-dropdown a, #mobile-subheader-dropdown a:hover, #mobile-subheader-dropdown a:focus {
        text-decoration: none;
    }

    #mobile-subheader-dropdown.loggedin .service-nav {
        padding-top: 20px;
    }

    #mobile-subheader-dropdown.loggedin .language-nav #languages.swiss-languages {
        padding-top: 0;
    }

    #mobile-subheader-dropdown .main-mobile-nav {
        background: #164194;
        padding-top: 100px;
        padding-bottom: 5px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li {
        padding: 0 20px 20px 20px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li .account-nav-mobile {
        padding-top: 20px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li .account-nav-mobile li {
        margin-bottom: 20px;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li .account-nav-mobile li:last-child {
        margin-bottom: 5px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li .account-nav-mobile li a {
        padding: 5px;
    }

    #mobile-subheader-dropdown .main-mobile-nav > li .account-nav-mobile li.active a {
        background: #fff;
        color: #164194;
        font-family: 'Helvetica-Neue-LT-Bold',Verdana,Arial,Helvetica;
    }

    #mobile-subheader-dropdown .main-mobile-nav a, #mobile-subheader-dropdown .main-mobile-nav .nav-item {
        color: #fff;
        cursor: pointer;
    }

    #mobile-subheader-dropdown .main-mobile-nav a .icon, #mobile-subheader-dropdown .main-mobile-nav .nav-item .icon {
        float: right;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop {
        position: relative;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper {
        display: none;
        background: #fff;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        padding: 30px 20px;
        top: 50px;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper .icon {
        color: #164194;
        position: absolute;
        right: 20px;
        top: 15px;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper p {
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper .tyre-partnershop-link {
        margin-top: 30px;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper .tyre-partnershop-link a {
        padding: 7px 0;
        width: 100%;
    }

    #mobile-subheader-dropdown .main-mobile-nav .tyre-partnershop .tyre-partnershop-link-wrapper:after {
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 0 25px 20px;
        content: "";
        display: block;
        height: 0;
        left: 70px;
        position: absolute;
        top: -20px;
        width: 0;
    }

    #mobile-subheader-dropdown .service-nav {
        background: #fff;
        padding-top: 100px;
        padding-bottom: 5px;
    }

    #mobile-subheader-dropdown .service-nav li {
        padding: 0 20px 20px 20px;
    }

    #mobile-subheader-dropdown .service-nav a {
        color: #164194;
    }

    #mobile-subheader-dropdown .service-nav a .icon {
        float: right;
    }

    #mobile-subheader-dropdown .service-nav .shopping-list, #mobile-subheader-dropdown .service-nav .compare {
        position: relative;
    }

    #mobile-subheader-dropdown .service-nav .shopping-list .service-item-quantity-wrapper, #mobile-subheader-dropdown .service-nav .compare .service-item-quantity-wrapper {
        position: absolute;
        right: 20px;
    }

    #mobile-subheader-dropdown .service-nav .shopping-list .service-item-quantity-wrapper .item-quantity-wrapper, #mobile-subheader-dropdown .service-nav .compare .service-item-quantity-wrapper .item-quantity-wrapper {
        background: #164194;
        border-radius: 50%;
        display: block;
        height: 18px;
        padding: 0;
        position: absolute;
        right: 9px;
        text-align: center;
        top: 12px;
        width: 18px;
        z-index: 1;
    }

    #mobile-subheader-dropdown .service-nav .shopping-list .service-item-quantity-wrapper .item-quantity-wrapper .item-quantity, #mobile-subheader-dropdown .service-nav .compare .service-item-quantity-wrapper .item-quantity-wrapper .item-quantity {
        color: #fff;
        font-size: 10px;
        position: relative;
        top: -7px;
    }

    #mobile-subheader-dropdown .service-nav .compare .winkler-artikelmerkmale {
        font-size: 23px;
        width: 23px;
    }

    #mobile-subheader-dropdown .service-nav .contact {
        position: relative;
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper {
        display: none;
        background: #164194;
        padding: 50px 20px 30px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        color: #fff;
        top: 48px;
        -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper .winkler-close {
        color: #fff;
        position: absolute;
        right: 20px;
        top: 15px;
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper h4 {
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        margin-bottom: 15px;
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper a, #mobile-subheader-dropdown .service-nav .contact .contact-wrapper a:focus, #mobile-subheader-dropdown .service-nav .contact .contact-wrapper a:hover {
        color: #fff;
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper a .icon, #mobile-subheader-dropdown .service-nav .contact .contact-wrapper a:focus .icon, #mobile-subheader-dropdown .service-nav .contact .contact-wrapper a:hover .icon {
        color: #fff;
    }

    #mobile-subheader-dropdown .service-nav .contact .contact-wrapper:after {
        border-color: transparent transparent #164194;
        border-style: solid;
        border-width: 0 25px 20px;
        content: "";
        display: block;
        height: 0;
        left: 30px;
        position: absolute;
        top: -20px;
        width: 0;
    }

    #mobile-subheader-dropdown .language-nav {
        background: #164194;
        padding: 30px 20px 10px;
        -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
    }

    #mobile-subheader-dropdown .language-nav a, #mobile-subheader-dropdown .language-nav a:hover, #mobile-subheader-dropdown .language-nav a:focus, #mobile-subheader-dropdown .language-nav .selected-language, #mobile-subheader-dropdown .language-nav .swiss {
        color: #fff;
        cursor: pointer;
    }

    #mobile-subheader-dropdown .language-nav .active-language {
        margin-bottom: 20px;
    }

    #mobile-subheader-dropdown .language-nav .active-language img {
        margin-right: 2px;
    }

    #mobile-subheader-dropdown .language-nav .active-language .icon {
        float: right;
    }

    #mobile-subheader-dropdown .language-nav #languages li {
        margin-bottom: 20px;
    }

    #mobile-subheader-dropdown .language-nav #languages li img {
        margin-right: 2px;
    }

    #mobile-subheader-dropdown .language-nav #languages li .swiss > .winkler-expand {
        position: absolute;
        right: 20px;
    }

    #mobile-subheader-dropdown .language-nav #languages .swiss-languages {
        padding-left: 28px;
        padding-top: 20px;
    }

    #mobile-subheader-dropdown .language-nav #languages .swiss-languages li {
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }

    #mobile-subheader-dropdown .language-nav #languages.swiss-languages {
        padding-left: 28px;
        padding-top: 20px;
    }

    #mobile-subheader-dropdown .language-nav #languages.swiss-languages li {
        font-family: 'Helvetica-Neue-LT-Roman',Verdana,Arial,Helvetica;
        font-size: 15px;
    }
}

@media (max-width: 991px) {
    .product-detail-overview.container {
        width: 100%;
    }

    .container.productdetails-container, .container.search-result {
        width: 100%;
    }

    .page-title h2 {
        line-height: 34px;
        font-size: 34px;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footer-group.col-md-3 {
        float: left;
        width: 25%;
    }

    .mobile-header-slider {
        position: relative;
    }

    .mobile-header-slider li {
        position: relative;
    }

    .mobile-header-slider .current-target-group-triangle {
        bottom: 22px;
        display: block;
        position: absolute;
        width: 100%;
    }

    .mobile-header-slider .current-target-group-triangle .triangle-left-side {
        margin-left: -15px;
        background: #fff none repeat scroll 0 0;
        display: inline-block;
        float: left;
        height: 15px;
        position: absolute;
    }

    .mobile-header-slider .current-target-group-triangle .triangle-left-side:after {
        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 15px 0 0 15px;
        content: "";
        display: inline-block;
        height: 0;
        position: absolute;
        right: -15px;
        width: 0;
    }

    .mobile-header-slider .current-target-group-triangle .triangle-right-side {
        margin-left: 16px;
        background: #fff none repeat scroll 0 0;
        display: inline-block;
        float: right;
        height: 15px;
        left: 12%;
        position: absolute;
        width: 78%;
    }

    .mobile-header-slider .current-target-group-triangle .triangle-right-side:before {
        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 0 0 15px 15px;
        content: "";
        display: inline-block;
        height: 0;
        left: -15px;
        position: absolute;
        width: 0;
    }

    .mobile-header-slider .current-target-group-triangle .triangle-right-edge {
        background: #fff none repeat scroll 0 0;
        height: 15px;
        position: absolute;
        right: 0;
        width: 20%;
    }

    .mobile-header-slider .current-target-group-triangle.omnibus-triangle .triangle-left-side {
        width: 12.5%;
    }

    .mobile-header-slider .current-target-group-triangle.omnibus-triangle .triangle-right-side {
        left: 12.5%;
    }

    .mobile-header-slider .current-target-group-triangle.nfz-triangle .triangle-left-side {
        width: 34.5%;
    }

    .mobile-header-slider .current-target-group-triangle.nfz-triangle .triangle-right-side {
        left: 34.5%;
    }

    .mobile-header-slider .current-target-group-triangle.agrar-triangle .triangle-left-side {
        width: 57.5%;
    }

    .mobile-header-slider .current-target-group-triangle.agrar-triangle .triangle-right-side {
        left: 57.5%;
        width: 37.5%;
    }

    .mobile-header-slider .current-target-group-triangle.werkstatt-triangle .triangle-left-side {
        width: 82.5%;
    }

    .mobile-header-slider .current-target-group-triangle.werkstatt-triangle .triangle-right-side {
        left: 82.5%;
        width: 12.5%;
    }

    .mobile-header-slider .current-target-group-triangle.werkstatt-triangle .triangle-right-edge {
        width: 3%;
    }

    .mobile-header-slider .page-target-groups {
        margin: 0 auto;
        padding: 0;
    }

    .mobile-header-slider .page-target-groups .target-group {
        width: 22%;
        vertical-align: middle;
        display: inline-block;
        font-family: 'Helvetica-Neue-LT-Cond-Bld',Verdana,Arial,Helvetica;
        padding: 0 0 20px;
        position: relative;
        text-align: center;
        font-size: 18px;
    }

    .mobile-header-slider .action-buttons {
        position: absolute;
        bottom: 95px;
        padding: 0 20px;
        width: 100%;
    }

    .mobile-header-slider .action-buttons .login-button {
        margin-bottom: 20px;
    }

    .mobile-header-slider .action-buttons a.btn {
        padding: 7px 0;
        width: 100%;
    }

    .tab-content .col-md-3 {
        float: left;
    }

    .catalog-section.contact-section .catalogs .col-md-4, .login-section .catalogs .col-md-4, .no-result .catalogs .col-md-4 {
        width: 33%;
        float: left;
    }

    .catalog-section.contact-section .contact, .login-section .contact, .no-result .contact {
        clear: left;
    }

    .catalog-section.contact-section .contact img, .login-section .contact img, .no-result .contact img {
        float: left;
        margin-right: 20px;
        width: 50%;
    }

    .login-section .login-form, .login-section .register-link {
        width: 50%;
        float: left;
    }

    .login-section .onlineshop-benefits {
        clear: left;
        padding-top: 50px;
    }

    .login-section .register-link .btn {
        margin-top: 25px;
    }

    .news-section article img {
        margin: 0 10px 20px 0;
    }

    #main-nav {
        display: none;
    }

    .loggedin.header-image .page-title h1 {
        text-align: center;
        left: 0;
        right: 0;
    }

    .loggedin.header-image .page-title .search-field-wrapper {
        margin-bottom: 15px;
    }

    .productdetails-container .product-image img {
        width: 100%;
    }

    .shadow-sortiment .col-md-6 {
        width: 55%;
        float: left;
    }

    .shadow-sortiment .col-md-6:last-child {
        width: 45%;
    }

    .required-products-overlay .required-products .add-to-box {
        float: left;
        width: 50%;
        margin-top: -2px;
    }

    .required-products-overlay .required-products {
        margin: 180px 50px 0;
        width: auto;
    }

    .required-products-overlay .required-products .action-buttons .buttons {
        padding-right: 20px;
    }

    .required-products-overlay .required-products .action-buttons a {
        padding-left: 20px;
    }

    .required-products-overlay .required-products .add-to-box {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .catalog-section.contact-section .catalogs, .login-section .catalogs, .no-result .catalogs {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
}

.catalog-section.contact-section .contact, .login-section .contact, .no-result .contact {
    padding-top: 0;
}

@media (min-width: 768px) and (max-width: 991px) {
    .catalog-section.contact-section .contact, .login-section .contact, .no-result .contact {
        padding-top: 30px;
    }
}

@media (max-width: 767px) {
    section.header-image {
        margin-bottom: 45px;
    }

    .header-image .page-title {
        padding-left: 0;
        padding-right: 0;
    }

    footer .footer-bottomlinks li {
        margin-bottom: 5px;
    }

    footer .footer-legal-links li {
        display: block;
        margin-left: 0;
        margin-bottom: 5px;
    }

    footer .footer-toplinks .footer-group {
        padding: 0 20px;
    }

    footer .footer-toplinks .footer-group .icon {
        float: right;
    }

    footer .footer-toplinks .footer-group ul {
        display: none;
    }

    footer .footer-toplinks .footer-group:first-child {
        padding-top: 40px;
    }

    footer .footer-toplinks .footer-group h4 {
        cursor: pointer;
    }

    footer .footer-toplinks .footer-group.col-md-3 {
        float: none;
        width: 100%;
    }

    a.btn, .btn {
        padding: 7px 0;
        width: 100%;
    }

    .login-section .keep-logged-in .cookie-note-tooltip {
        width: 100%;
    }

    .login-section .keep-logged-in .cookie-note-tooltip:after {
        left: 80px;
    }

    .login-section .register-link {
        padding: 0 20px;
    }

    .login-section .register-link .btn {
        margin-top: 25px;
    }

    .login-section .col-md-4 {
        margin-bottom: 45px;
    }

    .login-section .login-form, .login-section .register-link {
        width: 100%;
        float: none;
    }

    .login-section .onlineshop-benefits {
        padding-top: 0;
    }

    .catalog-section.contact-section .catalogs, .login-section .catalogs, .no-result .catalogs {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .catalog-section.contact-section .catalogs .col-md-4, .login-section .catalogs .col-md-4, .no-result .catalogs .col-md-4 {
        display: inline-block;
        float: none;
        white-space: normal;
        max-width: 320px;
        height: auto;
        vertical-align: top;
        width: 100%;
    }

    .catalog-section.contact-section .col-md-9, .login-section .col-md-9, .no-result .col-md-9 {
        margin-bottom: 60px;
    }

    .catalog-section.contact-section .contact img, .login-section .contact img, .no-result .contact img {
        float: none;
        margin-right: 0;
        width: 100%;
    }

    .offer-section .offers {
        white-space: nowrap;
    }

    .offer-section .offers .col-md-3 {
        display: inline-block;
        float: none;
        white-space: normal;
        max-width: 320px;
        height: auto;
        vertical-align: top;
        width: 100%;
    }

    .container.news-section {
        padding: 0;
    }

    .container.news-section .news-inner {
        padding: 30px 20px;
    }

    .container.news-section article {
        width: 320px;
    }

    .container.news-section article h5 {
        float: left;
        width: 180px;
    }

    .offer-section .offer-products {
        margin-bottom: 60px;
    }

    .offer-section .offer-products.products-grid {
        height: auto;
        overflow: auto;
        white-space: normal;
    }

    .offer-section .offer-products.products-grid .col-md-3 {
        float: left;
    }

    .offer-section .offer-products .offer-single-product {
        display: inline-block;
        float: none;
        white-space: normal;
        max-width: 320px;
        height: auto;
        vertical-align: top;
        width: 100%;
    }

    .loggedin.header-image .page-title h1 {
        text-align: right;
        right: 20px;
        left: auto;
    }

    .loggedin.header-image .page-title .search-field-wrapper, .loggedin.header-image .page-title .search-button-wrapper {
        float: none !important;
        width: 100%;
        max-width: none;
    }

    .loggedin.header-image .page-title .search-field-wrapper {
        margin-bottom: 15px;
    }

    .advantage-list-register.row {
        padding: 0 10px;
    }

    .register-form-section .form-group > .col-md-3 {
        width: 100%;
        float: none;
    }

    .register-form-section .form-group > .col-md-9 {
        width: 100%;
        float: none;
    }

    .register-form-section .form-group > .col-md-9 .col-md-6 {
        width: 100%;
        float: none;
    }

    .register-form-section .form-group > .col-md-9 .col-md-9 {
        width: 100%;
        float: none;
    }

    .register-form-section .form-group > .col-md-9 .col-md-3 {
        width: 100%;
        float: none;
    }

    .register-form-section .form-group .radiobuttons .col-md-6 {
        width: 50%;
        float: left;
    }

    .register-form-section .input-margin-bottom-mobile {
        margin-bottom: 30px;
    }

    .register-form-section label {
        margin-bottom: 7px;
    }

    .register-form-section .btn-right {
        margin-top: 60px;
    }

    .register-form-section .offer-agreement-checkbox .styled-checkbox {
        margin-bottom: 30px;
    }

    .register-form-section .offer-agreement-checkbox p {
        line-height: 18px;
    }

    .safety-informations {
        margin-top: 20px;
    }

    #main-nav {
        display: none;
    }

    .breadcrumbs-mobile .breadcrumb-accordion {
        cursor: pointer;
    }

    .breadcrumbs-mobile .breadcrumb-accordion .winkler-arrow-down {
        margin-left: 5px;
        font-size: 18px;
        top: 1px;
    }

    .breadcrumbs-mobile .breadcrumb-accordion .winkler-close {
        display: none;
    }

    .breadcrumbs-mobile .breadcrumb-separator {
        color: #c0c0c0;
        font-size: 14px;
    }

    .product-detail-overview.container {
        width: 100%;
    }

    .product-detail-overview.container .product-image-container {
        height: auto;
        position: relative;
        width: 100%;
    }

    .product-detail-overview.container .product-image-container .product-image {
        height: 315px;
        padding: 0;
        position: relative;
    }

    .product-detail-overview.container .product-image-container .product-image img {
        margin: auto;
        max-width: 370px;
        position: absolute;
        transform: translate(0, 0);
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .product-detail-overview.container .product-image-container .product-thumbnails {
        margin-bottom: 10px;
    }

    .product-detail-overview.container .product-image-container .product-thumbnails li {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        display: block;
        float: left;
        height: 60px;
        margin-bottom: 20px;
        position: relative;
        width: 60px;
        margin-right: 13px;
    }

    .product-detail-overview.container .product-image-container .product-thumbnails li:last-child {
        margin-right: 0;
    }

    .product-detail-overview.container .product-image-container .close-zoom {
        background: #164194;
        height: 40px;
        padding: 8px 10px 8px 8px;
        position: absolute;
        right: 0;
        width: 40px;
    }

    .product-detail-overview.container .product-image-container .close-zoom .icon {
        color: #fff;
    }

    .product-detail-overview.container .dl-horizontal.offer-list dt {
        float: left;
        width: 30%;
    }

    .product-detail-overview.container .dl-horizontal.offer-list dd {
        float: right;
        width: 60%;
        margin-left: 0;
    }

    .product-detail-overview.container .product-description .units .unit-price {
        text-align: left;
    }

    .product-detail-overview.container .availability-list .availability-location .location {
        display: inline-block;
        margin-bottom: 10px;
    }

    .product-detail-overview.container .availability-list .availability-location .availability {
        width: 50%;
        float: left;
    }

    .product-detail-overview.container .availability-list .availability-location .availability-icons {
        float: right;
        width: 50%;
    }

    .product-detail-overview.container .add-to-box .add-to-cart-qty {
        margin-bottom: 45px;
        width: 90px;
    }

    .add-to-box .btn {
        max-width: none;
    }

    .container.productdetails-container, .container.search-result {
        width: 100%;
    }

    .filter-container .active-filters .text-right {
        text-align: center;
    }

    .productdetails-container .specifics-table-container .specifics-table {
        padding: 0;
    }

    .productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper {
        width: 100%;
        clear: both;
        padding: 12px 20px;
        margin: 0;
    }

    .productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper .col-sm-6 {
        float: left;
        width: 50%;
        padding-bottom: 10px;
    }

    .productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper .specifics-data {
        text-align: right;
    }

    .productdetails-container .specifics-table-container .specifics-table .specifics-data-wrapper:nth-child(2) {
        border-top: 1px solid #fff;
    }

    .productdetails-container #downloads .download-item {
        margin: 0;
        padding: 15px 20px;
        width: 100%;
    }

    .productdetails-container #downloads .download-item .col-sm-4 {
        text-align: left;
        padding-left: 0;
    }

    .productdetails-container #downloads .download-item:nth-child(2) {
        border-top: 1px solid #fff;
    }

    .required-products-overlay {
        background: #fff;
    }

    .required-products-overlay .winkler-close {
        color: #000;
        top: 30px;
    }

    .required-products-overlay .required-products {
        margin: 65px 0 0;
        width: auto;
    }

    .required-products-overlay .required-products .action-buttons .buttons {
        padding-right: 20px;
    }

    .required-products-overlay .required-products .action-buttons a {
        padding-left: 20px;
    }

    .required-products-overlay .required-products .add-to-box {
        margin-top: 0;
    }

    .required-products-overlay .add-to .add-to-cart-icon {
        width: 40px;
        height: 40px;
        display: inline-block;
        background: #bb2d21;
        cursor: pointer;
    }

    .required-products-overlay .add-to .add-to-cart-icon .icon {
        color: #fff;
        font-size: 21px;
        height: 40px;
        left: -11px;
        padding-top: 10px;
        width: 40px;
    }

    .required-products-overlay .add-to .winkler-checkbox-checked {
        margin-right: 10px;
        top: 9px;
        width: 25px;
        color: #008a00;
    }

    .required-products-overlay .item .add-to-icon-wrapper {
        float: right;
        width: 40%;
    }

    .required-products-overlay .mobile-close-overlay .winkler-close {
        color: #164194;
        position: static;
        margin-top: 2px;
        margin-left: 2px;
        width: 23px;
    }

    .shadow-sortiment .col-sm-9 {
        padding-left: 0;
        padding-right: 0;
    }

    .shadow-sortiment .col-md-6 {
        width: 100%;
        float: none;
    }

    .shadow-sortiment .col-md-6:last-child {
        width: 100%;
    }

    .shadow-sortiment .col-md-6 .text-right {
        text-align: left;
    }

    .shadow-sortiment .product-image {
        padding: 0;
    }

    .shadow-sortiment .product-image img {
        max-width: 230px;
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .product-detail-overview.container .add-to-box .add-to-cart-qty {
        margin-bottom: 30px;
    }
}
