/** Innovate - Touchspin CSS **/

.touch_spin.form-control, .bootstrap-touchspin, .bootstrap-touchspin .input-group-btn-vertical {
    width: auto !important;
}

.bootstrap-touchspin {
	float:left;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
	margin-top:0px;
	margin-bottom:0px;
	max-width: initial;
	width: auto;
	
}


.bootstrap-touchspin-down {
    padding-top: 7px !important;
}


.bootstrap-touchspin-up {
    border-bottom: medium none !important;
}